import { BackendService } from './BackendService';


export interface SubscribeV1
{
    email:  string;
}


export class SubscriptionService
{
    private static _instance:           SubscriptionService;
    private readonly _backendService:   BackendService;
    
    
    public static getInstance ( ): SubscriptionService
    {
        if ( ! this._instance )
        {
            this._instance = new SubscriptionService();
        }
        
        return this._instance;
    }


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async subscribe ( params: SubscribeV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/newsletter/subscribe`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
}
