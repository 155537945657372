import { StateSubject } from 'ts-subject';
import { AddReservationModalParams } from '../Modals/AddReservationModal/AddReservationModal';
import { SelectFileModalParams } from '../Modals/SelectFileModal/SelectFileModal';
import { SelectEventLocationModalParams } from '../Modals/SelectEventLocationModal/SelectEventLocationModal';
import { ConfirmEventReservationModalParams } from '../Modals/ConfirmEventReservationModal/ConfirmEventReservationModal';
import { RejectEventReservationModalParams } from '../Modals/RejectEventReservationModal/RejectEventReservationModal';
import { SubscribeModalParams } from '../Modals/SubscribeModal/SubscribeModal';
import { CancelEventDateModalParams } from '../Modals/CancelEventDateModal/CancelEventDateModal';
import { CancelEventModalParams } from '../Modals/CancelEventModal/CancelEventModal';
import { DeleteEventReservationModalParams } from '../Modals/DeleteEventReservationModal/DeleteEventReservationModal';
import { InputLinkModalParams } from '../Modals/InputLinkModal/InputLinkModal';
import { DeleteEventDateModalParams } from '../Modals/DeleteEventDateModal/DeleteEventDateModal';


export class ModalService
{
    private static _instance:                           ModalService;
    private readonly _subjectAddReservation:            StateSubject<AddReservationModalParams | null>;
    private readonly _subjectInputLink:                 StateSubject<InputLinkModalParams | null>;
    private readonly _subjectSubscribe:                 StateSubject<SubscribeModalParams | null>;
    private readonly _subjectSelectFile:                StateSubject<SelectFileModalParams | null>;
    private readonly _subjectSelectEventLocation:       StateSubject<SelectEventLocationModalParams | null>;
    private readonly _subjectConfirmEventReservation:   StateSubject<ConfirmEventReservationModalParams | null>;
    private readonly _subjectRejectEventReservation:    StateSubject<RejectEventReservationModalParams | null>;
    private readonly _subjectDeleteEventReservation:    StateSubject<DeleteEventReservationModalParams | null>;
    private readonly _subjectCancelEventDate:           StateSubject<CancelEventDateModalParams | null>;
    private readonly _subjectCancelEvent:               StateSubject<CancelEventModalParams | null>;
    private readonly _subjectDeleteEventDate:           StateSubject<DeleteEventDateModalParams | null>;
    

    public static getInstance ( ): ModalService
    {
        if ( ! this._instance )
        {
            this._instance = new ModalService();
        }

        return this._instance;
    }


    constructor ( )
    {
        this._subjectAddReservation = new StateSubject<AddReservationModalParams | null>(null);
        this._subjectInputLink = new StateSubject<InputLinkModalParams | null>(null);
        this._subjectSubscribe = new StateSubject<SubscribeModalParams | null>(null);
        this._subjectSelectFile = new StateSubject<SelectFileModalParams | null>(null);
        this._subjectSelectEventLocation = new StateSubject<SelectEventLocationModalParams | null>(null);
        this._subjectConfirmEventReservation = new StateSubject<ConfirmEventReservationModalParams | null>(null);
        this._subjectRejectEventReservation = new StateSubject<RejectEventReservationModalParams | null>(null);
        this._subjectDeleteEventReservation = new StateSubject<DeleteEventReservationModalParams | null>(null);
        this._subjectCancelEventDate = new StateSubject<CancelEventDateModalParams | null>(null);
        this._subjectCancelEvent = new StateSubject<CancelEventModalParams | null>(null);
        this._subjectDeleteEventDate = new StateSubject<DeleteEventDateModalParams | null>(null);
    }


    public showAddReservation ( params: AddReservationModalParams ): void
    {
        this._subjectAddReservation.next(params);
    }
    
    
    public hideAddReservation ( ): void
    {
        this._subjectAddReservation.next(null);
    }


    public getAddReservation ( ): StateSubject<AddReservationModalParams | null>
    {
        return this._subjectAddReservation;
    }
   
   
    public showInputLink ( params: InputLinkModalParams ): void
    {
        this._subjectInputLink.next(params);
    }
    
    
    public hideInputLink ( ): void
    {
        this._subjectInputLink.next(null);
    }


    public getInputLink ( ): StateSubject<InputLinkModalParams | null>
    {
        return this._subjectInputLink;
    }
   
   
    public showSubscribe ( params: SubscribeModalParams ): void
    {
        this._subjectSubscribe.next(params);
    }
    
    
    public hideSubscribe ( ): void
    {
        this._subjectSubscribe.next(null);
    }


    public getSubscribe ( ): StateSubject<SubscribeModalParams | null>
    {
        return this._subjectSubscribe;
    }


    public showSelectFile ( params: SelectFileModalParams ): void
    {
        this._subjectSelectFile.next(params);
    }
    
    
    public hideSelectFile ( ): void
    {
        this._subjectSelectFile.next(null);
    }


    public getSelectFile ( ): StateSubject<SelectFileModalParams | null>
    {
        return this._subjectSelectFile;
    }


    public showSelectEventLocation ( params: SelectEventLocationModalParams ): void
    {
        this._subjectSelectEventLocation.next(params);
    }
    
    
    public hideSelectEventLocation ( ): void
    {
        this._subjectSelectEventLocation.next(null);
    }


    public getSelectEventLocation ( ): StateSubject<SelectEventLocationModalParams | null>
    {
        return this._subjectSelectEventLocation;
    }


    public showConfirmEventReservation ( params: ConfirmEventReservationModalParams ): void
    {
        this._subjectConfirmEventReservation.next(params);
    }
    
    
    public hideConfirmEventReservation ( ): void
    {
        this._subjectConfirmEventReservation.next(null);
    }


    public getConfirmEventReservation ( ): StateSubject<ConfirmEventReservationModalParams | null>
    {
        return this._subjectConfirmEventReservation;
    }


    public showRejectEventReservation ( params: RejectEventReservationModalParams ): void
    {
        this._subjectRejectEventReservation.next(params);
    }
    
    
    public hideRejectEventReservation ( ): void
    {
        this._subjectRejectEventReservation.next(null);
    }


    public getRejectEventReservation ( ): StateSubject<RejectEventReservationModalParams | null>
    {
        return this._subjectRejectEventReservation;
    }
   
   
    public showDeleteEventReservation ( params: DeleteEventReservationModalParams ): void
    {
        this._subjectDeleteEventReservation.next(params);
    }
    
    
    public hideDeleteEventReservation ( ): void
    {
        this._subjectDeleteEventReservation.next(null);
    }


    public getDeleteEventReservation ( ): StateSubject<DeleteEventReservationModalParams | null>
    {
        return this._subjectDeleteEventReservation;
    }


    public showCancelEventDate ( params: CancelEventDateModalParams ): void
    {
        this._subjectCancelEventDate.next(params);
    }
    
    
    public hideCancelEventDate ( ): void
    {
        this._subjectCancelEventDate.next(null);
    }


    public getCancelEventDate ( ): StateSubject<CancelEventDateModalParams | null>
    {
        return this._subjectCancelEventDate;
    }


    public showCancelEvent ( params: CancelEventModalParams ): void
    {
        this._subjectCancelEvent.next(params);
    }
    
    
    public hideCancelEvent ( ): void
    {
        this._subjectCancelEvent.next(null);
    }


    public getCancelEvent ( ): StateSubject<CancelEventModalParams | null>
    {
        return this._subjectCancelEvent;
    }


    public showDeleteEventDate ( params: DeleteEventDateModalParams ): void
    {
        this._subjectDeleteEventDate.next(params);
    }
    
    
    public hideDeleteEventDate ( ): void
    {
        this._subjectDeleteEventDate.next(null);
    }


    public getDeleteEventDate ( ): StateSubject<DeleteEventDateModalParams | null>
    {
        return this._subjectDeleteEventDate;
    }
}
