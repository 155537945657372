import React from 'react';
import DayJS from 'dayjs';
import { BlogService, BlogItemV1 } from '../../Services/BlogService';
import { FileService } from '../../Services/FileService';
import { TextBlock } from '../../Components/TextBlock/TextBlock';
import { AuthContextProps, withAuth } from 'oidc-react';
import { PageContent } from '../../Components/PageContent/PageContent';
import { Hero } from '../../Components/Hero/Hero';
import { SlideShow } from '../../Components/SlideShow/SlideShow';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './BlogItemPage.css';


export interface BlogItemPageRouteParams
{
    blogitemID: string;
}


export interface BlogItemPageProps extends RouteComponentProps<BlogItemPageRouteParams>, AuthContextProps
{
}


interface BlogItemPageState
{
    blogitem:   BlogItemV1 | null;
    loading:    boolean;
    error:      Error | null;
}


class $BlogItemPage extends React.Component<BlogItemPageProps, BlogItemPageState>
{
    private readonly _blogService:  BlogService;
    private readonly _fileService:  FileService;


    constructor ( props: BlogItemPageProps )
    {
        super(props);

        this.state = {
            blogitem:    null,
            loading:    true,
            error:      null
        };

        this._blogService = BlogService.getInstance();
        this._fileService = FileService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const blogitemID = parseInt(this.props.router.params.blogitemID, 10);
            const blogitem = await this._blogService.getBlogItem(blogitemID);

            this.setState({
                blogitem,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading blogitem: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <div className='BlogItemPage'>
                <Hero title='Aktuelles'>
                    {this.state.blogitem && this.state.blogitem.preview_image_uid ?
                        <div
                            className='BlogItemPage-hero-img'
                            style={{backgroundImage: `url(${this._fileService.getFileDataURL(this.state.blogitem.preview_image_uid)})`}}
                        />
                    : null}
                </Hero>

                <PageContent>
                    <IcErrorBox error={this.state.error} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />

                    {this.state.blogitem && this.props.userData ?
                        <IcButton to={LinkUtils.make('admin', 'blog', this.state.blogitem.id, 'bearbeiten')}>
                            Bearbeiten
                        </IcButton>
                    : null}

                    {this.state.blogitem ?
                        <>
                            <h1>{this.state.blogitem.title}</h1>
                            
                            <div className='BlogItemPage-datetime'>
                                Erstellt am {DayJS(this.state.blogitem.datetime_created).format('DD.MM.YYYY')}
                            </div>

                            <TextBlock text={this.state.blogitem.description} />

                            <SlideShow imageUIDs={[
                                ...(this.state.blogitem.preview_image_uid ? [this.state.blogitem.preview_image_uid] : []),
                                ...this.state.blogitem.image_uids
                            ]} />
                        </>
                    : null}
                </PageContent>
            </div>
        );
    }
}


export const BlogItemPage = withAuth(withRouter($BlogItemPage));
