import React from 'react';
import { PageContent } from '../../Components/PageContent/PageContent';
import { IcButton, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './AdminHomePage.css';


export interface AdminHomePageProps
{
}


interface AdminHomePageState
{
}


export class AdminHomePage extends React.Component<AdminHomePageProps, AdminHomePageState>
{
    constructor ( props: AdminHomePageProps )
    {
        super(props);

        this.state = {
        };
    }


    public render ( )
    {
        return (
            <div className='AdminHomePage'>
                <PageContent noHeader={true}>
                    <h1>Administration</h1>

                    <div className='AdminHomePage-buttons'>
                        <IcButton to={LinkUtils.make('admin', 'veranstaltungen')}>
                            Veranstaltungen
                        </IcButton>
                        
                        <IcButton to={LinkUtils.make('admin', 'veranstaltungsorte')}>
                            Veranstaltungsorte
                        </IcButton>
                        
                        <IcButton to={LinkUtils.make('admin', 'reservierungen')}>
                            Reservierungen
                        </IcButton>
                        
                        <IcButton to={LinkUtils.make('admin', 'blog')}>
                            Blog / Aktuelles
                        </IcButton>

                        <IcButton to={LinkUtils.make('admin', 'locations')}>
                            Räumlichkeiten
                        </IcButton>

                        <IcButton to={LinkUtils.make('admin', 'freund_innen')}>
                            Freund_Innen
                        </IcButton>
                        
                        <IcButton to={LinkUtils.make('admin', 'dateien')}>
                            Dateien
                        </IcButton>
                        
                        <IcButton to={LinkUtils.make('admin', 'status')}>
                            Status
                        </IcButton>
                    </div>
                </PageContent>
            </div>
        );
    }
}
