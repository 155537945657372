import React from 'react';
import MarkdownIt from 'markdown-it';
import { TextService, TextV1 } from '../../Services/TextService';
import { AuthContextProps, withAuth } from 'oidc-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik } from 'formik';
import { InputMarkdown } from '../Input/InputMarkdown';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize } from '@indece-common/ic-ui-lib-react';

import './TextBlock.css';


export interface TextBlockProps extends AuthContextProps
{
    text:   TextV1 | string | null | undefined;
}


interface TextBlockFormData
{
    content:    string;
}


interface TextBlockState
{
    edit:               boolean;
    overrideContent:    string | null;
    loading:            boolean;
    error:              Error | null;
}


class $TextBlock extends React.Component<TextBlockProps, TextBlockState>
{
    private readonly _textService:  TextService;
    private readonly _markdown:     MarkdownIt;


    constructor ( props: TextBlockProps )
    {
        super(props);

        this.state = {
            edit:               false,
            overrideContent:    null,
            loading:            false,
            error:              null
        };

        this._textService = TextService.getInstance();
        this._markdown = new MarkdownIt({
            html: true
        });

        this._enableEdit = this._enableEdit.bind(this);
        this._disableEdit = this._disableEdit.bind(this);
        this._submit = this._submit.bind(this);
    }


    private _enableEdit ( ): void
    {
        this.setState({
            edit:   true
        });
    }
    
    
    private _disableEdit ( ): void
    {
        this.setState({
            edit:   false
        });
    }


    private async _submit ( values: TextBlockFormData ): Promise<void>
    {
        if ( this.state.loading ||
             !this.props.text ||
             typeof(this.props.text) !== 'object' )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            await this._textService.updateText(
                this.props.text.tag,
                {
                    content: values.content
                }
            );

            this.setState({
                loading:            false,
                overrideContent:    values.content,
                edit:               false
            });
        }
        catch ( err )
        {
            console.error(`Error updating text: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public render ( )
    {
        let text = null;
        
        if ( this.state.overrideContent !== null )
        {
            text = this.state.overrideContent;
        }
        else if ( this.props.text && typeof(this.props.text) === 'string' )
        {
            text = this.props.text;
        }
        else if ( this.props.text && typeof(this.props.text) === 'object' )
        {
            text = this.props.text.content;
        }

        return (
            <div className='TextBlock'>
                <IcErrorBox error={this.state.error} />

                {this.props.text &&
                 typeof(this.props.text) === 'object' &&
                 this.props.text.tag &&
                 this.props.userData ?
                    <div className='TextBlock-actions'>
                        {!this.state.edit ?
                            <div className='TextBlock-action' onClick={this._enableEdit}>
                                <FontAwesomeIcon icon={faPencil} />
                            </div>
                        : null}
                    </div>
                : null}

                {this.state.edit ?
                    <div className='TextBlock-form'>
                        <Formik
                            initialValues={{
                                content: text || ''
                            }}
                            onSubmit={this._submit}>
                            <Form>
                                <InputMarkdown
                                    name='content'
                                />

                                <IcButton
                                    type='button'
                                    onClick={this._disableEdit}
                                    disabled={this.state.loading}>
                                    Abbrechen
                                </IcButton>

                                <IcButton
                                    type='submit'
                                    disabled={this.state.loading}>
                                    Speichern
                                </IcButton>
                            </Form>
                        </Formik>

                        <IcSpinner
                            size={IcSpinnerSize.Medium}
                            active={this.state.loading}
                        />
                    </div>
                : null}

                {!this.state.edit ?
                    <div className='TextBlock-text'>
                        {text ?
                            <div dangerouslySetInnerHTML={{__html: this._markdown.render(text)}} />
                        : null}
                    </div>
                : null}
            </div>
        );
    }
}


export const TextBlock = withAuth($TextBlock);
