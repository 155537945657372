import DayJS from 'dayjs';
import { EventV1, EventV1Date } from '../Services/EventService';


export class EventUtils
{
    public static getClosestDate ( event: EventV1 ): EventV1Date | null
    {
        let closestEventdate: EventV1Date | null = null;
        const now = DayJS();

        for ( const eventdate of event.dates )
        {
            const start = DayJS(eventdate.datetime_start);
            if ( start.isBefore(now) )
            {
                continue;
            }

            if ( !closestEventdate || start.isBefore(closestEventdate.datetime_start) )
            {
                closestEventdate = eventdate;
            }
        }

        return closestEventdate;
    }
   
   
    public static getMinDate ( event: EventV1 ): EventV1Date | null
    {
        let minEventdate: EventV1Date | null = null;

        for ( const eventdate of event.dates )
        {
            const start = DayJS(eventdate.datetime_start);
            if ( !minEventdate || start.isBefore(minEventdate.datetime_start) )
            {
                minEventdate = eventdate;
            }
        }

        return minEventdate;
    }
    
    
    public static getFutureDates ( event: EventV1 ): Array<EventV1Date>
    {
        const now = DayJS();

        return event.dates.filter( ( eventdate ) => DayJS(eventdate.datetime_start).isAfter(now) );
    }
}
