import DayJS from 'dayjs';
import React from 'react';
import { AuthContextProps, withAuth } from 'oidc-react';
import { PageContent } from '../../Components/PageContent/PageContent';
import { TextBlock } from '../../Components/TextBlock/TextBlock';
import { Country, EventReservationCountV1, EventService, EventV1, EventV1Date } from '../../Services/EventService';
import { FileService } from '../../Services/FileService';
import { ModalService } from '../../Services/ModalService';
import { Hero } from '../../Components/Hero/Hero';
import { SlideShow } from '../../Components/SlideShow/SlideShow';
import { Formatter } from '../../utils/Formatter';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Icon } from 'leaflet';
import { IcButton, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './EventPage.css';

import IconMarker from './assets/marker.png';


export interface EventPageRouteParams
{
    eventID:    string;
}


export interface EventPageProps extends RouteComponentProps<EventPageRouteParams>, AuthContextProps
{
}


interface EventPageState
{
    event:                  EventV1 | null;
    eventReservationCount:  EventReservationCountV1 | null;
    loading:                boolean;
    error:                  Error | null;
}


class $EventPage extends React.Component<EventPageProps, EventPageState>
{
    private readonly _eventService:     EventService;
    private readonly _fileService:      FileService;
    private readonly _modalService:     ModalService;


    constructor ( props: EventPageProps )
    {
        super(props);

        this.state = {
            event:                  null,
            eventReservationCount:  null,
            loading:                true,
            error:                  null
        };

        this._eventService = EventService.getInstance();
        this._fileService = FileService.getInstance();
        this._modalService = ModalService.getInstance();
    }


    private _reserve ( eventDateID: number ): void
    {
        if ( ! this.state.event )
        {
            return;
        }

        this._modalService.showAddReservation({
            eventID: this.state.event.id,
            eventDateID
        });
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const eventID = parseInt(this.props.router.params.eventID, 10);
            const event = await this._eventService.getEvent(eventID);

            let eventReservationCount: EventReservationCountV1 | null = null;

            /* TODO if ( this.props.userData )
            {
                eventReservationCount = await this._eventService.countEventReservations(eventID);
            }*/

            this.setState({
                event,
                eventReservationCount,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading event: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }
    
    
    public async componentDidUpdate ( prevProps: EventPageProps ): Promise<void>
    {
        if ( !prevProps.userData && this.props.userData )
        {
            await this._load();
        }
    }


    public render ( )
    {
        let dates: Array<EventV1Date> = [];
        // TODO

        if ( this.state.event )
        {
            dates = this.state.event.dates.sort( ( a, b ) => DayJS(a.datetime_start).diff(DayJS(b.datetime_start), 'second') );
        }

        return (
            <div className='EventPage'>
                <Hero title='Veranstaltung'>
                    {this.state.event && this.state.event.preview_image_uid ?
                        <div
                            className='LocationPage-hero-img'
                            style={{backgroundImage: `url(${this._fileService.getFileDataURL(this.state.event.preview_image_uid)})`}}
                        />
                    : null}
                </Hero>

                <PageContent>
                    <IcErrorBox error={this.state.error} />

                    {this.props.userData && this.state.event ?
                        <IcFloatRow align={IcFloatRowAlign.Left}>
                            <IcButton href={LinkUtils.make('admin', 'veranstaltung', this.state.event.id, 'bearbeiten')}>
                                Bearbeiten
                            </IcButton>

                            <IcButton href={LinkUtils.make('admin', 'veranstaltung', this.state.event.id, 'absagen')}>
                                Absagen
                            </IcButton>
                        </IcFloatRow>
                    : null}

                    {this.state.event ?
                        <>
                            <h1>{this.state.event.title}</h1>

                            {this.state.eventReservationCount ?
                                <div className='EventPage-reservationstats'>
                                    <div className='EventPage-reservationstats-item'>
                                        Reservierungen bestätigt: {this.state.eventReservationCount.count_confirmed} ({this.state.eventReservationCount.sum_confirmed} Plätze)
                                    </div>

                                    <div className='EventPage-reservationstats-item'>
                                        Reservierungen abgesagt: {this.state.eventReservationCount.count_canceled} ({this.state.eventReservationCount.sum_canceled} Plätze)
                                    </div>
                                </div>
                            : null}

                            <div className='EventPage-description'>
                                <TextBlock
                                    text={this.state.event.description}
                                />
                            </div>

                            {this.state.event.price ?
                                <div className='EventPage-price'>
                                    Preis: {this.state.event.price}
                                </div>
                            : null}

                            <div className='EventPage-slideshow'>
                                <SlideShow imageUIDs={[
                                    ...(this.state.event.preview_image_uid ? [this.state.event.preview_image_uid] : []),
                                    ...this.state.event.image_uids
                                ]} />
                            </div>
                        
                            <h2>Termine</h2>

                            <div className='EventPage-dates'>
                                {dates.map( ( date ) => (
                                    <div className='EventPage-date' key={date.id}>
                                        <div className='EventPage-date-info'>
                                            <div className='EventPage-date-info-row'>
                                                {DayJS(date.datetime_start).format('DD.MM.YYYY')} | {DayJS(date.datetime_start).format('HH:mm')} Uhr
                                            </div>

                                            {date.datetime_doors ?
                                                <div className='EventPage-date-info-row'>
                                                    Einlass: {DayJS(date.datetime_doors).format('HH:mm')} Uhr
                                                </div>
                                            : null}
                                            
                                            {date.datetime_end ?
                                                <div className='EventPage-date-info-row'>
                                                    Ende: {DayJS(date.datetime_end).format('HH:mm')} Uhr
                                                </div>
                                            : null}
                                        </div>

                                        <div className='EventPage-date-actions'>
                                            {date.booking_enabled && date.booking_url && !date.full && !date.canceled && DayJS(date.datetime_start).isAfter(DayJS()) ?
                                                <IcButton href={date.booking_url}>Ticket kaufen</IcButton>
                                            : null}
                                            
                                            {date.reservation_enabled && !date.full && !date.canceled && DayJS(date.datetime_start).isAfter(DayJS()) ?
                                                <IcButton onClick={ ( ) => this._reserve(date.id) }>Platz reservieren</IcButton>
                                            : null}
                                            
                                            {date.full && !date.canceled ?
                                                <>Ausgebucht</>
                                            : null}
                                            
                                            {date.canceled ?
                                                <>Abgesagt</>
                                            : null}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <h2>Location</h2>

                            <div className='EventPage-location'>
                                <div className='EventPage-location-title'>
                                    {this.state.event.location.title}
                                </div>

                                <div className='EventPage-location-address'>
                                    {this.state.event.location.street}<br />
                                    {this.state.event.location.zip} {this.state.event.location.city}<br />
                                    {this.state.event.location.country && this.state.event.location.country !== Country.DE ?
                                        <>{Formatter.country(this.state.event.location.country)}<br /></>
                                    : null}
                                    {this.state.event.location.description ?
                                        <><br />{this.state.event.location.description}</>
                                    : null}
                                </div>

                                {this.state.event.location.lat && this.state.event.location.lon ?
                                    <div>
                                        <MapContainer
                                            center={{lat: this.state.event.location.lat, lng: this.state.event.location.lon }}
                                            zoom={15}
                                            scrollWheelZoom={false}
                                            style={{width: '100%', height: '300px', marginTop: '30px', border: '10px solid #fff'}}>
                                            <TileLayer
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
            
                                            <Marker
                                                position={{lat: this.state.event.location.lat, lng: this.state.event.location.lon }}
                                                icon={new Icon({iconUrl: IconMarker, iconSize: [40, 40], iconAnchor: [20, 40]})}>
                                                <Popup>
                                                    {this.state.event.location.title}
                                                </Popup>
                                            </Marker>
                                        </MapContainer>
                                    </div>
                                : null}
                            </div>
                        </>
                    : null}

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}


export const EventPage = withAuth(withRouter($EventPage));
