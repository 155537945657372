import React from 'react';
import { RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface ScrollToTopControllerProps extends RouteComponentProps
{
}


class $ScrollToTopController extends React.Component<ScrollToTopControllerProps>
{
    public componentDidUpdate ( prevProps: ScrollToTopControllerProps ): void
    {
        if ( this.props.router.location.pathname !== prevProps.router.location.pathname )
        {
            window.scrollTo(0, 0);
        }
    }


    public render ( )
    {
        return null;
    }
}


export const ScrollToTopController = withRouter($ScrollToTopController);
