import React from 'react';
import { sleep } from 'ts-delay';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { BlogService, BlogItemV1 } from '../../Services/BlogService';
import { MetadataService } from '../../Services/MetadataService';
import { PageContent } from '../../Components/PageContent/PageContent';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './AdminDeleteBlogItemPage.css';


export interface AdminDeleteBlogItemPageRouteParams
{
    blogitemID: string;
}


export interface AdminDeleteBlogItemPageProps extends RouteComponentProps<AdminDeleteBlogItemPageRouteParams>
{
}


interface AdminDeleteBlogItemPageState
{
    blogitem:    BlogItemV1 | null;
    loading:    boolean;
    success:    string | null;
    error:      Error | null;
}


class $AdminDeleteBlogItemPage extends React.Component<AdminDeleteBlogItemPageProps, AdminDeleteBlogItemPageState>
{
    private readonly _blogService:      BlogService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: AdminDeleteBlogItemPageProps )
    {
        super(props);

        this.state = {
            blogitem:    null,
            loading:    true,
            success:    null,
            error:      null
        };

        this._blogService = BlogService.getInstance();
        this._metadataService = MetadataService.getInstance();

        this._delete    = this._delete.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const blogitemID = parseInt(this.props.router.params.blogitemID, 10);
            const blogitem = await this._blogService.getBlogItem(blogitemID);

            this.setState({
                blogitem,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading blogitem: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }
    
    
    private async _delete ( ): Promise<void>
    {
        if ( this.state.loading || !this.state.blogitem )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            await this._blogService.deleteBlogItem(this.state.blogitem.id);

            this.setState({
                success:    'Der Blogbeitrag wurde erfolgreich gelöscht',
                loading:    false
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'blog'));
        }
        catch ( err )
        {
            console.error(`Error updating blogitem: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._metadataService.setTitle('Blog-Beitrag löschen');

        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminDeleteBlogItemPage'>
                <PageContent noHeader={true}>
                    <h1>Blog-Beitrag löschen</h1>

                    <IcErrorBox error={this.state.error} />

                    {!this.state.success ?
                        <div>
                            <IcButton onClick={this._delete}>
                                Löschen
                            </IcButton>
                        </div>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}


export const AdminDeleteBlogItemPage = withRouter($AdminDeleteBlogItemPage);
