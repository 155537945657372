import React from 'react';
import { AuthContextProps, withAuth } from 'oidc-react';
import { PageContent } from '../../Components/PageContent/PageContent';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './AdminLoginPage.css';


export interface AdminLoginPageProps extends AuthContextProps, RouteComponentProps
{
}


interface AdminLoginPageState
{
    loading:    boolean;
    error:      Error | null;
}


class $AdminLoginPage extends React.Component<AdminLoginPageProps, AdminLoginPageState>
{
    constructor ( props: AdminLoginPageProps )
    {
        super(props);

        this.state = {
            loading:    false,
            error:      null
        };

        this._login = this._login.bind(this);
    }


    private async _login ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            await this.props.signIn();

            this.setState({
                loading:    false,
                error:      null
            });

            this.props.router.navigate(LinkUtils.make('admin', 'home'));
        }
        catch ( err )
        {
            console.error(`Error login in: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public render ( )
    {
        return (
            <div className='HomePage'>
                <PageContent noHeader={true}>
                    <h1>Admin-Panel</h1>

                    <IcErrorBox error={this.state.error} />

                    <IcButton
                        disabled={this.state.loading}
                        onClick={this._login}>
                        Login
                    </IcButton>

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}


export const AdminLoginPage = withAuth(withRouter($AdminLoginPage));
