import DayJS from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { ModalService } from '../../Services/ModalService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { LinkUtils } from '@indece-common/ic-ui-lib-react';

import './Footer.css';

import ImgKeke from './assets/keke.png';


export interface FooterProps
{
}


export class Footer extends React.Component<FooterProps>
{
    private readonly _modalService: ModalService;


    constructor ( props: FooterProps )
    {
        super(props);

        this._modalService = ModalService.getInstance();

        this._subscribeNewsletter = this._subscribeNewsletter.bind(this);
    }


    private _subscribeNewsletter ( ): void
    {
        this._modalService.showSubscribe({});
    }


    public render ( )
    {
        return (
            <div className='Footer'>
                <div className='Footer-main'>
                    <div className='Footer-left'>
                        <img src={ImgKeke} title='Keke' className='Footer-left-keke' alt='' />
                    </div>

                    <div className='Footer-center'>
                        <Link to={LinkUtils.make('kontakt')} className='Footer-link'>
                            Kontakt
                        </Link>

                        <Link to={LinkUtils.make('impressum')} className='Footer-link'>
                            Impressum
                        </Link>
                        
                        <Link to={LinkUtils.make('datenschutz')} className='Footer-link'>
                            Datenschutz
                        </Link>
                    </div>
                    
                    <div className='Footer-right'>
                        <div className='Footer-link' onClick={this._subscribeNewsletter}>
                            Newsletter
                        </div>

                        <Link to={LinkUtils.make('archiv')} className='Footer-link'>
                            Archiv
                        </Link>

                        <div className='Footer-item'>
                            Soziale Netzwerke
                        </div>

                        <div className='Footer-socialmedia'>
                            <a
                                className='Footer-socialmedia-item'
                                href='https://www.facebook.com/KulturBuntNeuperlach/'
                                target='_blank'
                                rel='noreferrer'
                                title='Kulturbunt Neuperlach auf Facebook'>
                                <FontAwesomeIcon icon={faFacebookSquare} />
                            </a>
                            
                            <a
                                className='Footer-socialmedia-item'
                                href='https://www.youtube.com/@kulturbuntneuperlach3724'
                                target='_blank'
                                rel='noreferrer'
                                title='Kulturbunt Neuperlach auf Youtube'>
                                <FontAwesomeIcon icon={faYoutubeSquare} />
                            </a>
                        </div>
                    </div>
                </div>
            
                <div className='Footer-copyright'>
                    Copyright &copy; {DayJS().format('YYYY')} Kulturbunt Neuperlach e.V.
                </div>
            </div>
        );
    }
}
