export class MetadataService
{
    private static _instance: MetadataService;


    public static getInstance ( ): MetadataService
    {
        if ( ! this._instance )
        {
            this._instance = new MetadataService();
        }

        return this._instance;
    }


    public setDescription ( description: string ): void
    {
        if ( (window as any).reactTestMode )
        {
            return;
        }

        let elem = document.querySelector('meta[name="description"]');
        if ( ! elem )
        {
            elem = document.createElement('<meta name="description" content="" />');

            document.querySelector('head')!.append(elem);
        }
        
        elem.setAttribute('content', description);
    }
    
    
    public setTitle ( title: string ): void
    {
        document.title = title ? `${title} | Kulturbunt Neuperlach` : 'Kulturbunt Neuperlach';
    }
}
