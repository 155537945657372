import DayJS from 'dayjs';
import React from 'react';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, Form, Formik } from 'formik';
import { sleep } from 'ts-delay';
import { InputDateTime } from '../../Components/Input/InputDateTime';
import { InputMarkdown } from '../../Components/Input/InputMarkdown';
import { InputSelect, InputSelectOption } from '../../Components/Input/InputSelect';
import { InputSelectEventLocation } from '../../Components/Input/InputSelectEventLocation';
import { InputSelectFile } from '../../Components/Input/InputSelectFile';
import { InputText } from '../../Components/Input/InputText';
import { InputTextarea } from '../../Components/Input/InputTextarea';
import { PageContent } from '../../Components/PageContent/PageContent';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { EventLocationV1, EventService, EventV1BookingType } from '../../Services/EventService';
import { MetadataService } from '../../Services/MetadataService';
import { IcButton, IcButtonColor, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './AdminAddEventPage.css';


export interface AdminAddEventPageProps extends RouteComponentProps
{
}


interface AdminAddEventPageFormDataDate
{
    datetime_start:     string;
    datetime_doors:     string;
    datetime_end:       string;
    reservation_type:   string;
    booking_url:        string;
}


interface AdminAddEventPageFormData
{
    title:                      string;
    abstract:                   string;
    description:                string;
    price:                      string;
    email_confirmation_note:    string;
    email_rejection_note:       string;
    preview_image_uid:          string | null;
    image_uids:                 Array<string>;
    eventlocation_id:           string;
    dates:                      Array<AdminAddEventPageFormDataDate>;
}


interface AdminAddEventPageState
{
    eventlocations: Array<EventLocationV1>;
    loading:        boolean;
    success:        string | null;
    error:          Error | null;
}


class $AdminAddEventPage extends React.Component<AdminAddEventPageProps, AdminAddEventPageState>
{
    private readonly INITIAL_FORMVALUES: AdminAddEventPageFormData = {
        title:                      '',
        abstract:                   '',
        description:                '',
        price:                      '',
        email_confirmation_note:    '',
        email_rejection_note:       '',
        preview_image_uid:          null,
        image_uids:                 [],
        eventlocation_id:           '',
        dates:                      []
    };
    private readonly OPTIONS_RESERVATIONTYPE: Array<InputSelectOption> = [
        {
            label:  'Nein',
            value:  'none'
        },
        {
            label:  'Ja (Reservierung)',
            value:  'reservation'
        },
        {
            label:  'Ja (München-Ticket)',
            value:  EventV1BookingType.MuenchenTicket
        }
    ]
    private readonly _eventService:  EventService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: AdminAddEventPageProps )
    {
        super(props);

        this.state = {
            eventlocations: [],
            loading:        false,
            success:        null,
            error:          null
        };

        this._eventService = EventService.getInstance();
        this._metadataService = MetadataService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._submit    = this._submit.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _submit ( values: AdminAddEventPageFormData ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            const title = values.title.trim();
            const abstract = values.abstract.trim();
            const preview_image_uid = values.preview_image_uid || null;
            const description = values.description.trim();

            const eventID = await this._eventService.addEvent({
                title,
                abstract,
                description,
                price:                      values.price.trim() || null,
                email_confirmation_note:    values.email_confirmation_note.trim() || null,
                email_rejection_note:       values.email_rejection_note.trim() || null,
                preview_image_uid,
                image_uids:                 values.image_uids,
                eventlocation_id:           parseInt(values.eventlocation_id, 10)
            });

            for ( const eventDate of values.dates )
            {
                let reservation_enabled: boolean = false;
                let booking_enabled: boolean = false;
                let booking_type: EventV1BookingType | null = null;

                switch ( eventDate.reservation_type )
                {
                    case 'reservation':
                        reservation_enabled = true;
                        break;
                    case EventV1BookingType.MuenchenTicket:
                        booking_enabled = true;
                        booking_type = EventV1BookingType.MuenchenTicket;
                        break;
                }

                await this._eventService.addEventDate(
                    eventID,
                    {
                        datetime_start: DayJS(eventDate.datetime_start).format(),
                        datetime_doors: eventDate.datetime_doors ? DayJS(eventDate.datetime_doors).format() : null,
                        datetime_end: eventDate.datetime_end ? DayJS(eventDate.datetime_end).format() : null,
                        reservation_enabled,
                        booking_enabled,
                        booking_type,
                        booking_url: eventDate.booking_url || null
                    }
                );
            }

            this.setState({
                success:    'Die Veranstaltung wurde erfolgreich angelegt',
                loading:    false
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('veranstaltung', eventID));
        }
        catch ( err )
        {
            console.error(`Error adding event: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public componentDidMount ( ): void
    {
        this._metadataService.setTitle('Neue Veranstaltung');
    }


    public render ( )
    {
        return (
            <div className='AdminAddEventPage'>
                <PageContent noHeader={true}>
                    <h1>Neue Veranstaltung anlegen</h1>

                    <IcErrorBox error={this.state.error} />

                    {!this.state.success ?
                        <Formik
                            initialValues={this.INITIAL_FORMVALUES}
                            onSubmit={this._submit}>
                            {({ values }) => (
                                <Form>
                                    <InputText
                                        label='Name'
                                        name='title'
                                        required={true}
                                    />
                                
                                    <InputText
                                        label='Kurzbeschreibung'
                                        name='abstract'
                                    />
                                    
                                    <InputSelectEventLocation
                                        label='Location'
                                        name='eventlocation_id'
                                        required={true}
                                    />

                                    <InputSelectFile
                                        label='Vorschaubild'
                                        name='preview_image_uid'
                                    />
                    
                                    <InputMarkdown
                                        label='Beschreibung'
                                        name='description'
                                        required={true}
                                    />

                                    <InputText
                                        label='Preis'
                                        name='price'
                                    />
                                
                                    <InputTextarea
                                        label='Anmerkung für Bestätigungs-Email'
                                        name='email_confirmation_note'
                                    />
                                    
                                    <FieldArray
                                        name='dates'>
                                        {( arrayHelpers ) => (
                                            <div className='AdminAddEventPage-dates'>
                                                <IcButton
                                                    type='button'
                                                    onClick={() => arrayHelpers.push({})}>
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    Neuer Termin
                                                </IcButton>

                                                <div className='AdminAddEventPage-dates-list'>
                                                    {values.dates.length === 0 ?
                                                        <div className='AdminAddEventPage-dates-empty'>
                                                            Es wurden noch keine Termine angelegt
                                                        </div>
                                                    : null}
                                                </div>

                                                {values.dates.map( ( dateValue, index ) => (
                                                    <div key={index} className='AdminAddEventPage-date'>
                                                        <div className='AdminAddEventPage-date-form'>
                                                            <div className='AdminAddEventPage-date-form-main'>
                                                                <InputDateTime
                                                                    name={`dates.${index}.datetime_start`}
                                                                    label='Start'
                                                                    required={true}
                                                                />

                                                                <InputDateTime
                                                                    name={`dates.${index}.datetime_end`}
                                                                    label='Ende'
                                                                />
                                                            </div>

                                                            <div className='AdminAddEventPage-date-form-main'>
                                                                <InputDateTime
                                                                    name={`dates.${index}.datetime_doors`}
                                                                    label='Einlass'
                                                                />
                                                            
                                                                <InputSelect
                                                                    name={`dates.${index}.reservation_type`}
                                                                    label='Reservierung möglich'
                                                                    options={this.OPTIONS_RESERVATIONTYPE}
                                                                    required={true}
                                                                />
                                                            </div>

                                                            {dateValue.reservation_type === EventV1BookingType.MuenchenTicket ?
                                                                <div className='AdminAddEventPage-date-form-booking'>
                                                                    <InputText
                                                                        name={`dates.${index}.booking_url`}
                                                                        label='Link zur Buchungs-Seite'
                                                                        required={true}
                                                                    />
                                                                </div>
                                                            : null}
                                                        </div>

                                                        <IcButton
                                                            title='Termin entfernen'
                                                            type='button'
                                                            onClick={() => arrayHelpers.remove(index)}>
                                                            <FontAwesomeIcon icon={faTimes} />
                                                        </IcButton>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </FieldArray>

                                    <IcFloatRow align={IcFloatRowAlign.Right}>
                                        <IcButton
                                            color={IcButtonColor.Link}
                                            onClick={this._cancel}>
                                            Abbrechen
                                        </IcButton>

                                        <IcButton
                                            type='submit'
                                            disabled={this.state.loading}>
                                            Anlegen
                                        </IcButton>
                                    </IcFloatRow>
                                </Form>
                            )}
                        </Formik>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}


export const AdminAddEventPage = withRouter($AdminAddEventPage);
