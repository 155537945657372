import React from 'react';
import { Link } from 'react-router-dom';
import { TextBlock } from '../../Components/TextBlock/TextBlock';
import { EventService, EventV1, GetEventsV1Period } from '../../Services/EventService';
import { MetadataService } from '../../Services/MetadataService';
import { TextService, TextV1, TextV1Tag } from '../../Services/TextService';
import { EventPreview } from '../../Components/EventPreview/EventPreview';
import { ModalService } from '../../Services/ModalService';
import { AssetService, AssetV1, AssetV1Tag } from '../../Services/AssetService';
import { Hero } from '../../Components/Hero/Hero';
import { IcButton, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcSpinner, IcSpinnerSize, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './HomePage.css';

import ImgLinkVeranstaltungen from './assets/link_veranstaltungen.png';
import ImgLinkLocations from './assets/link_locations.png';
import ImgLinkAddress from './assets/link_address.png';


export interface HomePageProps
{
}


interface HomePageState
{
    texts:      Partial<Record<TextV1Tag, TextV1>>;
    assets:     Partial<Record<AssetV1Tag, AssetV1>>;
    events:     Array<EventV1>;
    loading:    boolean;
    error:      Error | null;
}


export class HomePage extends React.Component<HomePageProps, HomePageState>
{
    private readonly _textService:      TextService;
    private readonly _assetService:     AssetService;
    private readonly _eventService:     EventService;
    private readonly _modalService:     ModalService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: HomePageProps )
    {
        super(props);

        this.state = {
            texts:      {},
            assets:     {},
            events:     [],
            loading:    true,
            error:      null
        };

        this._textService = TextService.getInstance();
        this._assetService = AssetService.getInstance();
        this._eventService = EventService.getInstance();
        this._modalService = ModalService.getInstance();
        this._metadataService = MetadataService.getInstance();

        this._subscribe = this._subscribe.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const texts = await this._textService.getTexts([
                TextV1Tag.HomePageHero,
                TextV1Tag.HomePageWelcome,
                TextV1Tag.HomePageNewsletter
            ]);
            
            const assets = await this._assetService.getAssets([
                AssetV1Tag.HomePageHeroImage1,
                AssetV1Tag.HomePageHeroImage2,
                AssetV1Tag.HomePageHeroImage3,
                AssetV1Tag.HomePageHeroVideo
            ]);

            const events = await this._eventService.getEvents(0, 10, GetEventsV1Period.Future);

            this.setState({
                texts,
                assets,
                events,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading events: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _subscribe ( ): void
    {
        this._modalService.showSubscribe({});
    }


    private _eventToURLPart ( event: EventV1 ): string
    {
        return `${event.id}_${event.title.replaceAll(/\W/gi, '_')}`
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._metadataService.setTitle('');

        await this._load();
    }


    public render ( )
    {
        return (
            <div className='HomePage'>
                <Hero title='Kulturbunt'>
                    <div className='HomePage-hero-inner-centerleft' />
                    <div className='HomePage-hero-inner-centerright' />
                    <div className='HomePage-hero-inner-bottom' />
                </Hero>
                
                <div className='HomePage-welcome'>
                    <div className='HomePage-welcome-inner'>
                        <TextBlock text={this.state.texts[TextV1Tag.HomePageWelcome]} />

                        <TextBlock text={this.state.texts[TextV1Tag.HomePageNewsletter]} />

                        <IcFloatRow align={IcFloatRowAlign.Center}>
                            <IcButton onClick={this._subscribe}>
                                Jetzt anmelden
                            </IcButton>
                        </IcFloatRow>
                    </div>
                </div>
                
                <div className='HomePage-links'>
                    <div className='HomePage-links-inner'>
                        <Link to={LinkUtils.make('veranstaltungen')} className='HomePage-links-link'>
                            <div className='HomePage-links-link-label'>
                                Spielplan
                            </div>

                            <div className='HomePage-links-link-inner'>
                                <img src={ImgLinkVeranstaltungen} alt='' />
                            </div>
                        </Link>
                            
                        <Link to={LinkUtils.make('locations')} className='HomePage-links-link'>
                            <div className='HomePage-links-link-label'>
                                Räumlichkeiten
                            </div>

                            <div className='HomePage-links-link-inner'>
                                <img src={ImgLinkLocations} alt='' />
                            </div>
                        </Link>

                        <Link to={LinkUtils.make('locations')} className='HomePage-links-link'>
                            <div className='HomePage-links-link-label'>
                                Anfahrt
                            </div>

                            <div className='HomePage-links-link-inner'>
                                <img src={ImgLinkAddress} alt='' />
                            </div>
                        </Link>
                    </div>
                </div>

                <div className='HomePage-video'>
                    <h2>
                        Über uns
                    </h2>

                    <video
                        muted={false}
                        autoPlay={false}
                        loop={false}
                        poster='/hero.jpg'
                        controls={true}>
                        <source type='video/mp4' src='/hero.mp4' />
                    </video>
                </div>

                <div className='HomePage-events'>
                    <div className='HomePage-events-inner'>
                        <h2>
                            <Link to={LinkUtils.make('veranstaltungen')}>
                                Spielplan
                            </Link>
                        </h2>

                        <IcErrorBox error={this.state.error} />

                        <div className='HomePage-events-inner-events'>
                            {this.state.events.map( ( event ) => (
                                <EventPreview
                                    key={event.id}
                                    href={LinkUtils.make('veranstaltung', this._eventToURLPart(event))}
                                    event={event}
                                />
                            ))}
                        </div>

                        {this.state.events.length === 0 && !this.state.loading && !this.state.error ?
                            <div className='HomePage-events-empty'>
                                Es wurden keine anstehenden Veranstaltungen gefunden.
                            </div>
                        : null}

                        {this.state.events.length > 0 && !this.state.loading && !this.state.error ?
                            <div className='HomePage-events-more'>
                                <IcButton to={LinkUtils.make('veranstaltungen')}>
                                    Mehr anzeigen
                                </IcButton>
                            </div>
                        : null}

                        <IcSpinner
                            size={IcSpinnerSize.Medium}
                            active={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
