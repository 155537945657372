import DayJS from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { EventV1 } from '../../Services/EventService';
import { FileService, ImageV1Size } from '../../Services/FileService';
import { EventUtils } from '../../utils/EventUtils';

import './EventPreview.css';


export interface EventPreviewProps
{
    event:  EventV1;
    href:   string;
}


export class EventPreview extends React.Component<EventPreviewProps>
{
    private readonly _fileService: FileService;


    constructor ( props: EventPreviewProps )
    {
        super(props);

        this._fileService = FileService.getInstance();
    }


    public render ( )
    {
        const imageURL = this.props.event.preview_image_uid ? this._fileService.getFilePreviewURL(this.props.event.preview_image_uid, ImageV1Size._500x500) : null;
        const closestDate = EventUtils.getClosestDate(this.props.event);
        const minDate = EventUtils.getMinDate(this.props.event);
        const futureDates = EventUtils.getFutureDates(this.props.event);

        return (
            <Link to={this.props.href} className='EventPreview'>
                <div
                    className='EventPreview-image'
                    style={{
                        backgroundImage: imageURL ? `url(${imageURL})` : undefined
                    }}
                />

                <div className='EventPreview-info'>
                    <div className='EventPreview-info-date'>
                        {closestDate ?
                            <>{DayJS(closestDate.datetime_start).format('dd, DD.MM.YYYY')} | {DayJS(closestDate.datetime_start).format('HH:mm')} Uhr</>
                        : (minDate ?
                            <>{DayJS(minDate.datetime_start).format('dd, DD.MM.YYYY')} | {DayJS(minDate.datetime_start).format('HH:mm')} Uhr</>
                        :
                            'Keine Termine verfügbar'
                        )}

                        {futureDates.length > 1 ? ' *' : ''}
                    </div>

                    <div className='EventPreview-info-title'>
                        {this.props.event.title}
                    </div>
                    
                    <div className='EventPreview-info-abstract'>
                        {this.props.event.abstract}
                    </div>
                </div>
            </Link>
        );
    }
}
