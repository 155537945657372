import React from 'react';
import { sleep } from 'ts-delay';
import { InputText } from '../../Components/Input/InputText';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { BlogService, BlogItemV1 } from '../../Services/BlogService';
import { MetadataService } from '../../Services/MetadataService';
import { PageContent } from '../../Components/PageContent/PageContent';
import { Form, Formik } from 'formik';
import { InputSelectFile } from '../../Components/Input/InputSelectFile';
import { InputMarkdown } from '../../Components/Input/InputMarkdown';
import { IcButton, IcButtonColor, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcSpinner, IcSpinnerSize, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './AdminEditBlogItemPage.css';


export interface AdminEditBlogItemPageRouteParams
{
    blogitemID: string;
}


export interface AdminEditBlogItemPageProps extends RouteComponentProps<AdminEditBlogItemPageRouteParams>
{
}


interface AdminEditBlogItemPageFormData
{
    title:              string;
    abstract:           string;
    preview_image_uid:  string | null;
    description:        string;
}


interface AdminEditBlogItemPageState
{
    initialFormValues:  AdminEditBlogItemPageFormData;
    blogitem:            BlogItemV1 | null;
    loading:            boolean;
    success:            string | null;
    error:              Error | null;
}


class $AdminEditBlogItemPage extends React.Component<AdminEditBlogItemPageProps, AdminEditBlogItemPageState>
{
    private readonly _blogService:      BlogService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: AdminEditBlogItemPageProps )
    {
        super(props);

        this.state = {
            initialFormValues: {
                title:              '',
                abstract:           '',
                preview_image_uid:  null,
                description:        ''
            },
            blogitem:    null,
            loading:    true,
            success:    null,
            error:      null
        };

        this._blogService = BlogService.getInstance();
        this._metadataService = MetadataService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._submit    = this._submit.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const blogitemID = parseInt(this.props.router.params.blogitemID, 10);
            const blogitem = await this._blogService.getBlogItem(blogitemID);

            this.setState({
                initialFormValues: {
                    title:              blogitem.title,
                    abstract:           blogitem.abstract,
                    preview_image_uid:  blogitem.preview_image_uid,
                    description:        blogitem.description
                },
                blogitem,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading blogitem: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }
    
    
    private async _submit ( values: AdminEditBlogItemPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.blogitem )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            const title = values.title.trim();
            const abstract = values.abstract.trim();
            const preview_image_uid = values.preview_image_uid || null;
            const description = values.description.trim();

            await this._blogService.updateBlogItem(
                this.state.blogitem.id,
                {
                    title,
                    abstract,
                    preview_image_uid,
                    image_uids: [], // TODO
                    description
                }
            );

            this.setState({
                success:    'Der Blog-Beitrag wurde erfolgreich aktualisiert',
                loading:    false
            });

            await sleep(1000);
        }
        catch ( err )
        {
            console.error(`Error updating blogitem: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._metadataService.setTitle('Blog-Beitrag bearbeiten');

        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminEditBlogItemPage'>
                <PageContent noHeader={true}>
                    <h1>Blog-Beitrag bearbeiten</h1>

                    <IcErrorBox error={this.state.error} />

                    {!this.state.success ?
                        <Formik
                            initialValues={this.state.initialFormValues}
                            enableReinitialize={true}
                            onSubmit={this._submit}>       
                            <Form>
                                <InputText
                                    label='Name'
                                    name='title'
                                    required={true}
                                />
                            
                                <InputText
                                    label='Kurzbeschreibung'
                                    name='abstract'
                                />

                                <InputSelectFile
                                    label='Vorschaubild'
                                    name='preview_image_uid'
                                />
                            
                                <InputMarkdown
                                    label='Beschreibung'
                                    name='description'
                                />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        Abbrechen
                                    </IcButton>

                                    <IcButton type='submit'>
                                        Speichern
                                    </IcButton>
                                </IcFloatRow>
                            </Form>
                        </Formik>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}


export const AdminEditBlogItemPage = withRouter($AdminEditBlogItemPage);
