import { Form, Formik, FormikErrors } from 'formik';
import React from 'react';
import { sleep } from 'ts-delay';
import { InputText } from '../../Components/Input/InputText';
import { InputCheckbox } from '../../Components/Input/InputCheckbox';
import { Modal } from '../../Components/Modal/Modal';
import { ModalContent } from '../../Components/Modal/ModalContent';
import { ModalTitle } from '../../Components/Modal/ModalTitle';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { ModalService } from '../../Services/ModalService';
import { SubscriptionService } from '../../Services/SubscriptionService';
import { IcButton, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcSpinner, IcSpinnerSize } from '@indece-common/ic-ui-lib-react';

import './SubscribeModal.css';


export interface SubscribeModalParams
{
}


export interface SubscribeModalProps
{
}


interface SubscribeModalFormData
{
    email:          string;
    privacypolicy:  boolean;
}


interface SubscribeModalState
{
    params:     SubscribeModalParams | null;
    loading:    boolean;
    success:    string | null;
    error:      Error | null;
}


export class SubscribeModal extends React.Component<SubscribeModalProps, SubscribeModalState>
{
    private readonly INITIAL_FORMVALUES: SubscribeModalFormData = {
        email:  '',
        privacypolicy: false
    };
    private readonly _subscriptionService:  SubscriptionService;
    private readonly _modalService:         ModalService;


    constructor ( props: SubscribeModalProps )
    {
        super(props);

        this.state = {
            params:     null,
            loading:    false,
            success:    null,
            error:      null
        };

        this._subscriptionService   = SubscriptionService.getInstance();
        this._modalService          = ModalService.getInstance();

        this._close     = this._close.bind(this);
        this._submit    = this._submit.bind(this);
        this._validate  = this._validate.bind(this);
    }


    private _close ( ): void
    {
        this._modalService.hideSubscribe();
    }


    private async _submit ( values: SubscribeModalFormData ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            const email = values.email.trim();

            await this._subscriptionService.subscribe({
                email
            });

            this.setState({
                success:    'Sie haben sich erfolgreich für unseren kostenlosen Newsletter angemeldet.',
                loading:    false
            });

            await sleep(1000);

            this._modalService.hideSubscribe();
        }
        catch ( err )
        {
            console.error(`Error subscribing: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }

    
    private _validate ( formData: SubscribeModalFormData ): FormikErrors<SubscribeModalFormData>
    {
        const formErrors: FormikErrors<SubscribeModalFormData> = {};

        if ( ! formData.email )
        {
            formErrors.email = 'Bitte geben Sie Ihre E-Mail-Adresse ein';
        }
        
        if ( ! formData.privacypolicy )
        {
            formErrors.privacypolicy = 'Bitte stimmen Sie der Datenschutzerklärung zu';
        }

        return formErrors;
    }



    public async componentDidMount ( ): Promise<void>
    {
        this._modalService.getSubscribe().subscribe(this, async ( params ) =>
        {
            this.setState({
                params
            });
        });
        
        const params = this._modalService.getSubscribe().get();
        this.setState({
            params
        });
    }


    public componentWillUnmount ( ): void
    {
        this._modalService.getSubscribe().unsubscribe(this);
    }


    public render ( )
    {
        if ( ! this.state.params )
        {
            return null;
        }

        return (
            <Modal
                className='SubscribeModal'
                closable={true}
                onClose={this._close}>
                <ModalTitle>Für Newsletter anmelden</ModalTitle>

                <ModalContent>
                    <IcErrorBox error={this.state.error} />

                    {!this.state.success ?
                        <Formik
                            initialValues={this.INITIAL_FORMVALUES}
                            onSubmit={this._submit}>       
                            <Form>
                                <InputText
                                    label='Ihre E-Mail-Adresse'
                                    name='email'
                                    required={true}
                                />

                                <InputCheckbox
                                    label='Ich habe die Datenschutzerklärung gelesen und stimme dieser zu.'
                                    name='privacypolicy'
                                />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton type='submit'>
                                        Kostenlos anmelden
                                    </IcButton>
                                </IcFloatRow>
                            </Form>
                        </Formik>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </ModalContent>
            </Modal>
        );
    }
}
