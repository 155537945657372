import React from 'react';
import { Field, FieldProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../Services/ModalService';
import { EventLocationV1, EventService } from '../../Services/EventService';
import { Formatter } from '../../utils/Formatter';
import { IcSpinner, IcSpinnerSize } from '@indece-common/ic-ui-lib-react';

import './Input.css';


export interface EventLocationLabelProps
{
    eventLocationID:    number;
}


interface EventLocationLabelState
{
    eventLocation:  EventLocationV1 | null;
    loading:        boolean;
    error:          Error | null;
}


class EventLocationLabel extends React.Component<EventLocationLabelProps, EventLocationLabelState>
{
    private readonly _eventService: EventService;


    constructor ( props: EventLocationLabelProps )
    {
        super(props);

        this.state = {
            eventLocation:  null,
            loading:        false,
            error:          null
        };

        this._eventService = EventService.getInstance();
    }


    private async _load ( eventLocationID: number ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const eventLocation = await this._eventService.getEventLocation(eventLocationID);

            this.setState({
                loading:    false,
                eventLocation
            });
        }
        catch ( err )
        {
            console.error(`Error loading event location: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load(this.props.eventLocationID);
    }


    public async componentDidUpdate ( prevProps: EventLocationLabelProps ): Promise<void>
    {
        if ( prevProps.eventLocationID !== this.props.eventLocationID )
        {
            await this._load(this.props.eventLocationID);
        }
    }


    public render ( )
    {
        return (
            <div className='EventLocationLabel'>
                {this.state.eventLocation ?
                    `${this.state.eventLocation.title} (${Formatter.eventLocationAddress(this.state.eventLocation)})`
                : null}

                <IcSpinner
                    size={IcSpinnerSize.Medium}
                    active={this.state.loading}
                />
            </div>
        );
    }
}


export interface InputSelectEventLocationProps
{
    label:      string;
    name:       string;
    disabled?:  boolean;
    required?:  boolean;
}


export class InputSelectEventLocation extends React.Component<InputSelectEventLocationProps>
{
    private readonly _modalService: ModalService;


    constructor ( props: InputSelectEventLocationProps )
    {
        super(props);

        this._modalService = ModalService.getInstance();
    }


    private _select ( fieldProps: FieldProps<number> ): void
    {
        this._modalService.showSelectEventLocation({
            onSelect:  ( eventLocationID ) =>
            {
                fieldProps.form.setFieldValue(fieldProps.field.name, eventLocationID)
            }
        });
    }


    public render ( )
    {
        return (
            <Field name={this.props.name}>
                {( fieldProps: FieldProps<number> ) => (
                    <label className={`InputSelectEventLocation ${fieldProps.field.value ? '' : 'empty'} ${this.props.disabled ? 'disabled' : ''} ${fieldProps.meta.error ? 'error' : ''}`} onClick={ ( ) => this._select(fieldProps) }>
                        <div className='InputSelectEventLocation-label'>
                            {this.props.label} {this.props.required ? '*' : ''}
                        </div>

                        <div className='InputSelectEventLocation-value'>
                            {fieldProps.field.value ?
                                <EventLocationLabel eventLocationID={fieldProps.field.value} />
                            : null}
                        </div>

                        <div className='InputSelectEventLocation-suffix'>
                            <FontAwesomeIcon icon={faCaretDown} />
                        </div>
                    </label>
                )}
            </Field>
        );
    }
}
