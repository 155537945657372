import React from 'react';
import { MetadataService } from '../../Services/MetadataService';

import './Hero.css';

import ImgLogo from './assets/logo.png';


export interface HeroProps
{
    title:      string;
    children?:  React.ReactNode | undefined;
}


interface HeroState
{
    animate:    boolean;
}


export class Hero extends React.Component<HeroProps, HeroState>
{
    private readonly _metadataService:  MetadataService;
    private _timeoutStore: any | null;


    constructor ( props: HeroProps )
    {
        super(props);

        this.state = {
            animate:    !(window as any).KULTURBUNT_ANIMATION_DISABLED
        };

        this._metadataService = MetadataService.getInstance();

        this._timeoutStore = null;
    }


    public componentDidMount ( ): void
    {
        this._metadataService.setTitle(this.props.title);

        if ( this._timeoutStore )
        {
            clearTimeout(this._timeoutStore);
        }

        this._timeoutStore = setTimeout( ( ) =>
        {
            this._timeoutStore = null;

            (window as any).KULTURBUNT_ANIMATION_DISABLED = true;
        }, 3000);
    }

    
    public componentDidUpdate ( prevProps: HeroProps ): void
    {
        if ( prevProps.title !== this.props.title )
        {
            this._metadataService.setTitle(this.props.title);
        }
    }


    public componentWillUnmount ( ): void
    {
        if ( this._timeoutStore )
        {
            clearTimeout(this._timeoutStore);
            this._timeoutStore = null;
        }
    }


    public render ( )
    {
        return (
            <div className={`Hero ${this.state.animate ? 'animate' : ''}`}>
                <div className='Hero-inner'>
                    <div className='Hero-inner-top' />

                    {this.props.children}
                    {/*<div className='Hero-inner-centerleft' />
                    <div className='Hero-inner-centerright' />
                    <div className='Hero-inner-bottom' />*/}
                
                    <h1 className='Hero-inner-title'>
                        <img src={ImgLogo} alt='' className='Hero-inner-title-logo' />

                        <div className='Hero-inner-title-text'>
                            {this.props.title}
                        </div>
                    </h1>
                </div>
            </div>
        );
    }
}
