import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { Hero } from '../../Components/Hero/Hero';
import { PageContent } from '../../Components/PageContent/PageContent';
import { FileService } from '../../Services/FileService';
import { LocationService, LocationV1 } from '../../Services/LocationService';
import { IcErrorBox, IcSpinner, IcSpinnerSize, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './LocationsPage.css';


export interface LocationsPageProps
{
}


interface LocationsPageState
{
    locations:  Array<LocationV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


export class LocationsPage extends React.Component<LocationsPageProps, LocationsPageState>
{
    private readonly SIZE               = 30;
    private readonly _locationService:  LocationService;
    private readonly _fileService:      FileService;


    constructor ( props: LocationsPageProps )
    {
        super(props);

        this.state = {
            locations:  [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._locationService = LocationService.getInstance();
        this._fileService = FileService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const locations = await this._locationService.getLocations(0, this.SIZE);

            this.setState({
                locations,
                hasMore:    locations.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading locations: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const locations = await this._locationService.getLocations(this.state.locations.length, this.SIZE);

            this.setState({
                locations:     [
                    ...this.state.locations,
                    ...locations
                ],
                hasMore:    locations.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more locations: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _locationToURLPart ( location: LocationV1 ): string
    {
        return `${location.id}_${location.title.replaceAll(/\W/gi, '_')}`
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <div className='LocationsPage'>
                <Hero title='Räumlichkeiten'>
                    <div className='LocationsPage-hero-keke' />
                </Hero>

                <PageContent>
                    <IcErrorBox error={this.state.error} />

                    <InfiniteScroll
                        className='LocationsPage-locations'
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        {this.state.locations.map( ( loc ) => (
                            <Link to={LinkUtils.make('location', this._locationToURLPart(loc))} className='LocationsPage-location' key={loc.id}>
                                <h2 className='LocationsPage-location-title'>
                                    {loc.title}
                                </h2>

                                <div className='LocationsPage-location-content'>
                                    <div
                                        className='LocationsPage-location-image'
                                        style={{
                                            backgroundImage: `url(${this._fileService.getFileDataURL(loc.preview_image_uid || '')})`
                                        }}
                                    />

                                    <div className='LocationsPage-location-abstract'>
                                        {loc.abstract}
                                    </div>
                                </div>
                            </Link>
                        ))}

                        {this.state.locations.length === 0 && !this.state.loading && !this.state.error ?
                            <div className='LocationsPage-empty'>
                                Es wurden keine Locations gefunden.
                            </div>
                        : null}
 
                        <IcSpinner
                            size={IcSpinnerSize.Medium}
                            active={this.state.loading}
                        />
                    </InfiniteScroll>
                </PageContent>
            </div>
        );
    }
}
