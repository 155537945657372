import { BackendService } from './BackendService';


export enum ImageV1Size
{
    _500x500    = '500x500',
    _200x200    = '200x200',
    _100x100    = '100x100',
    _50x50      = '50x50'
}


export interface FileV1
{
    uid:        string;
    title:      string;
    filename:   string;
    mimetype:   string;
}


export interface AddFileV1
{
    title:      string;
    filename:   string;
    mimetype:   string;
    data:       string;
}


export class FileService
{
    private static _instance:           FileService;
    private readonly _backendService:   BackendService;
    
    
    public static getInstance ( ): FileService
    {
        if ( ! this._instance )
        {
            this._instance = new FileService();
        }
        
        return this._instance;
    }


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public getFileDataURL ( imageUID: string ): string
    {
        return `/api/v1/file/${encodeURIComponent(imageUID)}/data`;
    }
    
    
    public getFilePreviewURL ( imageUID: string, size: ImageV1Size ): string
    {
        return `/api/v1/file/${encodeURIComponent(imageUID)}/preview/${encodeURIComponent(size)}`;
    }


    public async getFiles ( from: number, size: number ): Promise<Array<FileV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/file?from=${encodeURIComponent(from)}&size=${encodeURIComponent(size)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.files;
    }
    
    
    public async addFile ( params: AddFileV1 ): Promise<string>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/file`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.file_uid;
    }
}
