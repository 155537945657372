import { BackendService } from './BackendService';


export enum TextV1Tag
{
    HomePageHero                = 'homepage_hero',
    HomePageWelcome             = 'homepage_welcome',
    HomePageNewsletter          = 'homepage_newsletter',
    EventsPageWelcome           = 'eventspage_welcome',
    BlogPageWelcome             = 'blogpage_welcome',
    FriendsPageWelcome          = 'friendspage_welcome',
    ImprintPageContent          = 'imprintpage_content',
    PrivacyPolicyPageContent    = 'privacypolicypage_content',
    ContactPageWelcome          = 'contactpage_welcome',
    ContactPageAdditional       = 'contactpage_additional',
    ArchivePageWelcome          = 'archivepage_welcome'
}


export interface TextV1
{
    tag:        TextV1Tag;
    content:    string;
}


export interface UpdateTextV1
{
    content:    string;
}


export class TextService
{
    private static _instance:           TextService;
    private readonly _backendService:   BackendService;
    
    
    public static getInstance ( ): TextService
    {
        if ( ! this._instance )
        {
            this._instance = new TextService();
        }
        
        return this._instance;
    }


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getTexts ( tags: Array<TextV1Tag> ): Promise<Partial<Record<TextV1Tag, TextV1>>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/text?tags=${tags.map(encodeURIComponent).join(',')}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        const texts: Partial<Record<TextV1Tag, TextV1>> = {};

        for ( const text of resp.texts )
        {
            texts[text.tag as TextV1Tag] = text;
        }

        return texts;
    }


    public async updateText ( tag: TextV1Tag, params: UpdateTextV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/text/${encodeURIComponent(tag)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
}
