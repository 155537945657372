import React from 'react';
import { PageContent } from '../../Components/PageContent/PageContent';
import { StatusService } from '../../Services/StatusService';
import { IcErrorBox, IcSpinner, IcSpinnerSize, IcTable, IcTableBody, IcTableCell, IcTableColWidthAuto, IcTableHead, IcTableRow } from '@indece-common/ic-ui-lib-react';
import { StatusV1 } from '../../Services/StatusService';


export interface AdminStatusPageProps
{
}


interface AdminStatusPageState
{
    status:     Array<StatusV1>;
    loading:    boolean;
    error:      Error | null;
}


export class AdminStatusPage extends React.Component<AdminStatusPageProps, AdminStatusPageState>
{
    private readonly _statusService:     StatusService;


    constructor ( props: AdminStatusPageProps )
    {
        super(props);

        this.state = {
            status:     [],
            loading:    true,
            error:      null
        };

        this._statusService = StatusService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const status = await this._statusService.getStatus();

            this.setState({
                status,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading status: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }
   
    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminStatusPage'>
                <PageContent noHeader={true}>
                    <h1>Administration: Status</h1>

                    <IcErrorBox error={this.state.error} />

                    <IcTable
                        cols={[
                            IcTableColWidthAuto,
                            IcTableColWidthAuto,
                            IcTableColWidthAuto
                        ]}>
                        <IcTableHead>
                            <IcTableRow>
                                <IcTableCell>
                                    Datum
                                </IcTableCell>

                                <IcTableCell>
                                    Titel
                                </IcTableCell>

                                <IcTableCell />
                            </IcTableRow>
                        </IcTableHead>
                            
                        <IcTableBody>
                            {this.state.status.map( ( status, i ) => (
                                <IcTableRow key={i}>
                                    <IcTableCell>
                                        {status.status}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {status.name}
                                    </IcTableCell>

                                    <IcTableCell>
                                        {status.message}
                                    </IcTableCell>
                                </IcTableRow>
                            ))}
                        </IcTableBody>
                    </IcTable>

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}
