import React from 'react';
import { InputText } from '../../Components/Input/InputText';
import { Modal } from '../../Components/Modal/Modal';
import { ModalContent } from '../../Components/Modal/ModalContent';
import { ModalTitle } from '../../Components/Modal/ModalTitle';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { ModalService } from '../../Services/ModalService';
import { Countries, Country, EventLocationV1, EventService } from '../../Services/EventService';
import { Form, Formik } from 'formik';
import { InputTextarea } from '../../Components/Input/InputTextarea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { InputSelect } from '../../Components/Input/InputSelect';
import { Formatter } from '../../utils/Formatter';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize } from '@indece-common/ic-ui-lib-react';

import './SelectEventLocationModal.css';


export interface SelectEventLocationModalParams
{
    onSelect: ( eventLocationID: number ) => any;
}


export interface SelectEventLocationModalProps
{
}


interface SelectEventLocationModalFormData
{
    title:          string;
    street:         string;
    zip:            string;
    city:           string;
    country:        string;
    description:    string;
}


interface SelectEventLocationModalState
{
    params:         SelectEventLocationModalParams | null;
    eventlocations: Array<EventLocationV1>;
    loading:        boolean;
    success:        string | null;
    error:          Error | null;
}


export class SelectEventLocationModal extends React.Component<SelectEventLocationModalProps, SelectEventLocationModalState>
{
    private readonly INITIAL_FORMVALUES: SelectEventLocationModalFormData = {
        title:          '',
        street:         '',
        zip:            '',
        city:           '',
        country:        Country.DE,
        description:    ''
    };
    private readonly _eventService: EventService;
    private readonly _modalService: ModalService;


    constructor ( props: SelectEventLocationModalProps )
    {
        super(props);

        this.state = {
            params:         null,
            eventlocations: [],
            loading:        false,
            success:        null,
            error:          null
        };

        this._eventService  = EventService.getInstance();
        this._modalService  = ModalService.getInstance();

        this._close         = this._close.bind(this);
        this._submit        = this._submit.bind(this);
    }


    private _close ( ): void
    {
        this._modalService.hideSelectEventLocation();
    }



    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const eventlocations = await this._eventService.getEventLocations();

            this.setState({
                loading:    false,
                eventlocations
            });
        }
        catch ( err )
        {
            console.error(`Error loading event locations: ${(err as Error).message}`, err);

            this.setState({
                loading:    true,
                error:      err as Error
            });
        }
    }


    private async _submit ( values: SelectEventLocationModalFormData ): Promise<void>
    {
        if ( this.state.loading || !values.title || !this.state.params )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            const title = values.title.trim();
            const street = values.street.trim() || null;
            const zip = values.zip.trim() || null;
            const city = values.city.trim() || null;
            const country = values.country.trim() as Country || null;
            const description = values.description.trim();

            const eventLocationID = await this._eventService.addEventLocation({
                title,
                street,
                zip,
                city,
                country,
                description
            });

            this.setState({
                loading:    false
            });

            this.state.params.onSelect(eventLocationID);

            this._modalService.hideSelectEventLocation();
        }
        catch ( err )
        {
            console.error(`Error adding event location: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private _select ( eventLocationID: number ): void
    {
        if ( ! this.state.params )
        {
            return;
        }

        this.state.params.onSelect(eventLocationID);

        this._modalService.hideSelectEventLocation();
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._modalService.getSelectEventLocation().subscribe(this, async ( params ) =>
        {
            this.setState({
                params
            });

            if ( params )
            {
                await this._load();
            }
        });
        
        const params = this._modalService.getSelectEventLocation().get();
        this.setState({
            params
        });

        if ( params )
        {
            await this._load();
        }
    }


    public componentWillUnmount ( ): void
    {
        this._modalService.getSelectEventLocation().unsubscribe(this);
    }


    public render ( )
    {
        if ( ! this.state.params )
        {
            return null;
        }

        return (
            <Modal
                className='SelectEventLocationModal'
                closable={true}
                onClose={this._close}>
                <ModalTitle>Veranstaltungsort auswählen</ModalTitle>

                <ModalContent>
                    <IcErrorBox error={this.state.error} />

                    <div className='SelectEventLocationModal-add'>
                        <div className='SelectEventLocationModal-add-title'>
                            Neue Location anlegen:
                        </div>

                        {!this.state.success ?
                            <Formik
                                initialValues={this.INITIAL_FORMVALUES}
                                onSubmit={this._submit}>       
                                <Form>
                                    <InputText
                                        label='Titel'
                                        name='title'
                                        required={true}
                                    />
            
                                    <InputText
                                        label='Straße / Hausnummer'
                                        name='street'
                                    />
                                    
                                    <InputText
                                        label='Postleitzahl'
                                        name='zip'
                                    />
                                    
                                    <InputText
                                        label='Ort'
                                        name='city'
                                    />
                                    
                                    <InputSelect
                                        label='Land'
                                        name='country'
                                        options={Countries.map( country => ({
                                            label: Formatter.country(country),
                                            value: country
                                        }))}
                                    />

                                    <InputTextarea
                                        label='Zusatzinformation'
                                        name='description'
                                    />

                                    <IcButton type='submit'>
                                        <FontAwesomeIcon icon={faSave} />
                                        Anlegen
                                    </IcButton>
                                </Form>
                            </Formik>
                        : null}

                        <SuccessBox message={this.state.success} />
                    </div>

                    <div className='SelectEventLocationModal-eventlocations'>
                        {this.state.eventlocations.map( ( eventlocation ) => (
                            <div
                                className='SelectEventLocationModal-eventlocation'
                                key={eventlocation.id}>
                                <div className='SelectEventLocationModal-eventlocation-details'>
                                    {eventlocation.title}<br />
                                    {Formatter.eventLocationAddress(eventlocation)}
                                </div>
                                
                                <div className='SelectEventLocationModal-eventlocation-actions'>
                                    <div className='SelectEventLocationModal-eventlocation-action'>
                                        <IcButton onClick={ ( ) => this._select(eventlocation.id) }>
                                            Auswählen
                                        </IcButton>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {this.state.eventlocations.length === 0 ?
                            <div className='SelectEventLocationModal-eventlocations-empty'>
                                Noch keine Locations vorhanden
                            </div>
                        : null}
                    </div>

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </ModalContent>
            </Modal>
        );
    }
}
