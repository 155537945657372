import React from 'react';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileService } from '../../Services/FileService';

import './SlideShow.css';


export interface SlideShowProps
{
    imageUIDs:  Array<string>;
}


interface SlideShowState
{
    index:  number;
}


export class SlideShow extends React.Component<SlideShowProps, SlideShowState>
{
    private readonly _fileService:  FileService;


    constructor ( props: SlideShowProps )
    {
        super(props);

        this.state = {
            index: 0
        };

        this._fileService = FileService.getInstance();

        this._prev = this._prev.bind(this);
        this._next = this._next.bind(this);
    }


    private _prev ( )
    {
        this.setState({
            index: (this.state.index + this.props.imageUIDs.length - 1) % this.props.imageUIDs.length
        });
    }


    private _next ( )
    {
        this.setState({
            index: (this.state.index + 1) % this.props.imageUIDs.length
        });
    }


    public render ( )
    {
        if ( this.props.imageUIDs.length === 0 )
        {
            return null;
        }

        const index = Math.min(this.state.index, this.props.imageUIDs.length - 1);

        return (
            <div className='SlideShow'>
                <div className='SlideShow-images' style={{left: `-${index * 100}%`}}>
                    {this.props.imageUIDs.map( ( imageUID, i ) => 
                        <div
                            key={i}
                            className='SlideShow-image'
                            onClick={this._next}
                            style={{
                                backgroundImage: `url(${this._fileService.getFileDataURL(imageUID)})`
                            }}
                        />
                    )}
                </div>

                <div className='SlideShow-nav'>
                    {index > 0 ?
                        <div className='SlideShow-nav-left' onClick={this._prev}>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </div>
                    : null}
                    
                    {index < (this.props.imageUIDs.length - 1) ?
                        <div className='SlideShow-nav-right' onClick={this._next}>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </div>
                    : null}
                </div>
            </div>
        );
    }
}
