import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { EventPreview } from '../../Components/EventPreview/EventPreview';
import { Hero } from '../../Components/Hero/Hero';
import { PageContent } from '../../Components/PageContent/PageContent';
import { TextBlock } from '../../Components/TextBlock/TextBlock';
import { EventService, EventV1, GetEventsV1Period } from '../../Services/EventService';
import { MetadataService } from '../../Services/MetadataService';
import { TextService, TextV1, TextV1Tag } from '../../Services/TextService';
import { IcErrorBox, IcSpinner, IcSpinnerSize, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './EventsPage.css';


export interface EventsPageProps
{
}


interface EventsPageState
{
    texts:      Partial<Record<TextV1Tag, TextV1>>;
    events:     Array<EventV1>;
    loading:    boolean;
    hasMore:    boolean;
    error:      Error | null;
}


export class EventsPage extends React.Component<EventsPageProps, EventsPageState>
{
    private readonly SIZE               = 30;
    private readonly _textService:      TextService;
    private readonly _eventService:     EventService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: EventsPageProps )
    {
        super(props);

        this.state = {
            texts:      {},
            events:     [],
            loading:    true,
            hasMore:    true,
            error:      null
        };

        this._textService = TextService.getInstance();
        this._eventService = EventService.getInstance();
        this._metadataService = MetadataService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const texts = await this._textService.getTexts([
                TextV1Tag.EventsPageWelcome
            ]);
            
            const events = await this._eventService.getEvents(0, this.SIZE, GetEventsV1Period.Future);

            this.setState({
                texts,
                events,
                hasMore:    events.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading events: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }
    
    
    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const events = await this._eventService.getEvents(this.state.events.length, this.SIZE, GetEventsV1Period.Future);

            this.setState({
                events:     [
                    ...this.state.events,
                    ...events
                ],
                hasMore:    events.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more events: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _eventToURLPart ( event: EventV1 ): string
    {
        return `${event.id}_${event.title.replaceAll(/\W/gi, '_')}`
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._metadataService.setTitle('Veranstaltungen');

        await this._load();
    }


    public render ( )
    {
        return (
            <div className='EventsPage'>
                <Hero title='Spielplan'>
                    <div className='EventsPage-hero-keke' />
                </Hero>

                <PageContent>
                    <IcErrorBox error={this.state.error} />

                    <div className='EventsPage-welcome'>
                        <TextBlock text={this.state.texts[TextV1Tag.EventsPageWelcome]} />
                    </div>

                    <InfiniteScroll
                        className='EventsPage-events'
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        {this.state.events.map( ( event ) => (
                            <div className='EventsPage-event' key={event.id}>
                                <EventPreview
                                    href={LinkUtils.make('veranstaltung', this._eventToURLPart(event))}
                                    event={event}
                                />
                            </div>
                        ))}

                        {this.state.events.length === 0 && !this.state.loading && !this.state.error ?
                            <div className='EventsPage-empty'>
                                Es wurden keine anstehenden Veranstaltungen gefunden.
                            </div>
                        : null}
            
                        <IcSpinner
                            size={IcSpinnerSize.Medium}
                            active={this.state.loading}
                        />
                    </InfiniteScroll>
                </PageContent>
            </div>
        );
    }
}
