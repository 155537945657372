import React from 'react';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventService, EventLocationV1 } from '../../Services/EventService';
import { PageContent } from '../../Components/PageContent/PageContent';
import { IcButton, IcButtonColor, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcFloatRowPadding, IcSpinner, IcSpinnerSize, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './AdminEventLocationsPage.css';


export interface AdminEventLocationsPageProps
{
}


interface AdminEventLocationsPageState
{
    eventLocations: Array<EventLocationV1>;
    loading:        boolean;
    error:          Error | null;
}


export class AdminEventLocationsPage extends React.Component<AdminEventLocationsPageProps, AdminEventLocationsPageState>
{
    private readonly _eventService:  EventService;


    constructor ( props: AdminEventLocationsPageProps )
    {
        super(props);

        this.state = {
            eventLocations: [],
            loading:        true,
            error:          null
        };

        this._eventService = EventService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const eventLocations = await this._eventService.getEventLocations();

            this.setState({
                eventLocations,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading event locations: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminEventLocationsPage'>
                <PageContent noHeader={true}>
                    <h1>Administration: Veranstaltungsorte</h1>

                    <IcErrorBox error={this.state.error} />

                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton to={LinkUtils.make('admin', 'veranstaltungsort', 'add')}>
                            <FontAwesomeIcon icon={faPlus} />
                            
                            Neuer Veranstaltungsort
                        </IcButton>
                    </IcFloatRow>

                    <IcTable
                        cols={[
                            IcTableColWidthAuto,
                            IcTableColWidthAuto
                        ]}>
                        <IcTableHead>
                            <IcTableRow>
                                <IcTableCell>
                                    Name
                                </IcTableCell>

                                <IcTableCell />
                            </IcTableRow>
                        </IcTableHead>
                            
                        <IcTableBody>
                            {this.state.eventLocations.map( ( loc ) => (
                                <IcTableRow key={loc.id}>
                                    <IcTableCell>
                                        {loc.title}
                                    </IcTableCell>

                                    <IcTableCell align={IcTableCellAlign.Right}>
                                        <IcFloatRow align={IcFloatRowAlign.Right} padding={IcFloatRowPadding.None}>
                                            <IcButton
                                                color={IcButtonColor.Link}
                                                title='Bearbeiten'
                                                to={LinkUtils.make('admin', 'veranstaltungsort', loc.id, 'edit')}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </IcButton>
                                        
                                            <IcButton
                                                color={IcButtonColor.Link}
                                                title='Löschen'
                                                to={LinkUtils.make('admin', 'veranstaltungsort', loc.id, 'delete')}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </IcButton>
                                        </IcFloatRow>
                                    </IcTableCell>
                                </IcTableRow>
                            ))}

                            {this.state.eventLocations.length === 0 && !this.state.loading && !this.state.error ?
                                <IcTableRow>
                                    <IcTableCell
                                        colSpan={2}
                                        disabled={true}
                                        align={IcTableCellAlign.Center}>
                                        Es wurden keine Veranstaltungsorte gefunden.
                                    </IcTableCell>
                                </IcTableRow>
                            : null}
                        </IcTableBody>
                    </IcTable>

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}
