import React from 'react';
import DayJS from 'dayjs';
import { InputText } from '../../Components/Input/InputText';
import { Modal } from '../../Components/Modal/Modal';
import { ModalContent } from '../../Components/Modal/ModalContent';
import { ModalTitle } from '../../Components/Modal/ModalTitle';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { ModalService } from '../../Services/ModalService';
import { EventReservationV1, EventService, EventV1, EventV1Date } from '../../Services/EventService';
import { Form, Formik } from 'formik';
import { sleep } from 'ts-delay';
import { InfoBox } from '../../Components/InfoBox/InfoBox';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize } from '@indece-common/ic-ui-lib-react';

import './ConfirmEventReservationModal.css';


export interface ConfirmEventReservationModalParams
{
    eventReservationID: number;
    onConfirm:          ( eventReservationID: number ) => any;
}


export interface ConfirmEventReservationModalProps
{
}


interface ConfirmEventReservationModalFormData
{
    message:    string;
}


interface ConfirmEventReservationModalState
{
    params:             ConfirmEventReservationModalParams | null;
    event:              EventV1 | null;
    eventDate:          EventV1Date | null;
    eventReservation:   EventReservationV1 | null;
    loading:            boolean;
    success:            string | null;
    error:              Error | null;
}


export class ConfirmEventReservationModal extends React.Component<ConfirmEventReservationModalProps, ConfirmEventReservationModalState>
{
    private readonly INITIAL_FORMVALUES: ConfirmEventReservationModalFormData = {
        message:    ''
    };
    private readonly _eventService: EventService;
    private readonly _modalService: ModalService;


    constructor ( props: ConfirmEventReservationModalProps )
    {
        super(props);

        this.state = {
            params:             null,
            event:              null,
            eventDate:          null,
            eventReservation:   null,
            loading:            false,
            success:            null,
            error:              null
        };

        this._eventService  = EventService.getInstance();
        this._modalService  = ModalService.getInstance();

        this._close         = this._close.bind(this);
        this._submit        = this._submit.bind(this);
    }


    private _close ( ): void
    {
        this._modalService.hideConfirmEventReservation();
    }


    private async _applyParams ( params: ConfirmEventReservationModalParams | null ): Promise<void>
    {
        if ( ! params )
        {
            this.setState({
                params:             null,
                event:              null,
                eventDate:          null,
                eventReservation:   null,
                error:              null,
                success:            null
            });

            return;
        }

        try
        {
            this.setState({
                params,
                loading:    true,
                error:      null
            });

            const eventReservation = await this._eventService.getEventReservation(params.eventReservationID, null);
            const event = await this._eventService.getEventByEventDate(eventReservation.eventdate_id);
            const eventDate = event.dates.find( o => o.id === eventReservation.eventdate_id ) || null;
    
            this.setState({
                event,
                eventDate,
                eventReservation,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading event reservation: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _submit ( values: ConfirmEventReservationModalFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.params )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            await this._eventService.confirmEventReservation(
                this.state.params.eventReservationID,
                {
                    message: values.message.trim() || null
                }
            );

            this.setState({
                loading:    false,
                success:    'Die Reservierung wurde erfolgreich bestätigt.'
            });

            await sleep(1000);

            this.state.params.onConfirm(this.state.params.eventReservationID);

            this._modalService.hideConfirmEventReservation();
        }
        catch ( err )
        {
            console.error(`Error confirm event reservation: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._modalService.getConfirmEventReservation().subscribe(this, async ( params ) =>
        {
            await this._applyParams(params);
        });
        
        const params = this._modalService.getConfirmEventReservation().get();
        await this._applyParams(params);
    }


    public componentWillUnmount ( ): void
    {
        this._modalService.getConfirmEventReservation().unsubscribe(this);
    }


    public render ( )
    {
        if ( ! this.state.params )
        {
            return null;
        }

        return (
            <Modal
                className='ConfirmEventReservationModal'
                closable={true}
                onClose={this._close}>
                <ModalTitle>Reservierung bestätigen</ModalTitle>

                <ModalContent>
                    <IcErrorBox error={this.state.error} />

                    {this.state.event && this.state.eventDate && this.state.eventReservation ?
                        <div className='ConfirmEventReservationModal-event'>
                            <div className='ConfirmEventReservationModal-event-row'>
                                Veranstaltung: {this.state.event.title}
                            </div>
                            
                            <div className='ConfirmEventReservationModal-event-row'>
                                Datum: {DayJS(this.state.eventDate.datetime_start).format('DD.MM.YYYY')} | {DayJS(this.state.eventDate.datetime_start).format('HH:mm')} Uhr
                            </div>
                            
                            <div className='ConfirmEventReservationModal-event-row'>
                                Name: {this.state.eventReservation.name}
                            </div>
                            
                            <div className='ConfirmEventReservationModal-event-row'>
                                E-Mail: {this.state.eventReservation.email}
                            </div>
                            
                            <div className='ConfirmEventReservationModal-event-row'>
                                Telefon: {this.state.eventReservation.phone || '-'}
                            </div>
                            
                            <div className='ConfirmEventReservationModal-event-row'>
                                Plätze: {this.state.eventReservation.count}
                            </div>
                        </div>
                    : null}

                    {!this.state.success ?
                        <Formik
                            initialValues={this.INITIAL_FORMVALUES}
                            onSubmit={this._submit}>
                            <Form>
                                <div>
                                    Soll die Reservierung bestätigt werden?
                                </div>

                                <InfoBox>
                                    Beim Bestätigen der Reservierung wird automatisch eine E-Mail an den Benutzer gesendet.
                                </InfoBox>

                                <InputText
                                    label='Nachricht (optional)'
                                    name='message'
                                />

                                <IcButton type='submit'>
                                    Reservierung bestätigen
                                </IcButton>
                            </Form>
                        </Formik>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </ModalContent>
            </Modal>
        );
    }
}
