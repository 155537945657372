import { BackendService } from './BackendService';


export enum StatusV1Status
{
    Ok      = 'OK',
    Unknown = 'UNKNOWN',
    Error   = 'ERROR'
}


export interface StatusV1
{
    name:       string;
    message:    string | null;
    status:     StatusV1Status;
    error:      string | null;
}


export class StatusService
{
    private static _instance: StatusService;
    
    
    public static getInstance ( ): StatusService
    {
        if ( ! this._instance )
        {
            this._instance = new StatusService();
        }
        
        return this._instance;
    }


    private readonly _backendService: BackendService;


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getStatus ( ): Promise<Array<StatusV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/status`
        );

        return resp.status;
    }
}
