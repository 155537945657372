import { BackendService } from './BackendService';


export interface FriendV1
{
    id:                 number;
    title:              string;
    link:               string | null;
    description:        string;
    preview_image_uid:  string | null;
}


export interface AddFriendV1
{
    title:              string;
    link:               string | null;
    description:        string;
    preview_image_uid:  string | null;
}


export interface UpdateFriendV1
{
    title:              string;
    link:               string | null;
    description:        string;
    preview_image_uid:  string | null;
}


export class FriendService
{
    private static _instance:           FriendService;
    private readonly _backendService:   BackendService;
    
    
    public static getInstance ( ): FriendService
    {
        if ( ! this._instance )
        {
            this._instance = new FriendService();
        }
        
        return this._instance;
    }


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getFriends ( from: number, size: number ): Promise<Array<FriendV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/friend?from=${encodeURIComponent(from)}&size=${encodeURIComponent(size)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.friends;
    }
    
    
    public async getFriend ( friendID: number ): Promise<FriendV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/friend/${encodeURIComponent(friendID)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.friend;
    }


    public async addFriend ( params: AddFriendV1 ): Promise<number>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/friend`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.friend_id;
    }
   
   
    public async updateFriend ( friendID: number, params: UpdateFriendV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/friend/${encodeURIComponent(friendID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
   
   
    public async deleteFriend ( friendID: number ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/friend/${encodeURIComponent(friendID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
}
