import React from 'react';
import { AuthContextProps, withAuth } from 'oidc-react';
import { BackendService } from '../../Services/BackendService';
import Cookies from 'universal-cookie';
import { Environment } from '../../utils/Environment';


export interface AuthControllerProps extends AuthContextProps
{
}


class $AuthController extends React.Component<AuthControllerProps>
{
    private readonly _backendService:   BackendService;


    constructor ( props: AuthControllerProps )
    {
        super(props);

        this._backendService = BackendService.getInstance();
    }


    public async componentDidMount ( ): Promise<void>
    {
        let userData = this.props.userData || null;

        try
        {
            const cookies = new Cookies();
    
            const signedIn = cookies.get('kulturbunt-signedin') === '1';
            if ( signedIn && !this.props.isLoading && !this.props.userData )
            {
                window.sessionStorage.setItem('kulturbunt-afterloginpath', window.location.pathname);

                await this.props.signIn();
                
                userData = await this.props.userManager.getUser();
            }
        }
        catch ( err )
        {
            console.error(`Error during auto login: ${(err as Error).message}`, err);
        }

        if ( userData )
        {
            if ( Environment.oauth.idTokenForApi )
            {
                this._backendService.setAccessToken(userData.id_token || '');
            }
            else
            {
                this._backendService.setAccessToken(userData.access_token);
            }
        }
        else
        {
            this._backendService.deleteAccessToken();
        }
    }
    
    
    public componentDidUpdate ( ): void
    {
        if ( this.props.userData )
        {
            if ( Environment.oauth.idTokenForApi )
            {
                this._backendService.setAccessToken(this.props.userData.id_token || '');
            }
            else
            {
                this._backendService.setAccessToken(this.props.userData.access_token);
            }
        }
        else
        {
            this._backendService.deleteAccessToken();
        }
    }


    public render ( )
    {
        return null;
    }
}


export const AuthController = withAuth($AuthController);
