import React from 'react';
import { PageContent } from '../../Components/PageContent/PageContent';
import { Hero } from '../../Components/Hero/Hero';
import { TextService, TextV1, TextV1Tag } from '../../Services/TextService';
import { TextBlock } from '../../Components/TextBlock/TextBlock';
import { IcErrorBox, IcSpinner, IcSpinnerSize } from '@indece-common/ic-ui-lib-react';

import './ContactPage.css';


export interface ContactPageProps
{
}


interface ContactPageState
{
    texts:      Partial<Record<TextV1Tag, TextV1>>;
    error:      Error | null;
    success:    string | null;
    loading:    boolean;
}


export class ContactPage extends React.Component<ContactPageProps, ContactPageState>
{
    private readonly _textService:  TextService;


    constructor ( props: ContactPageProps )
    {
        super(props);

        this.state = {
            texts:      {},
            error:      null,
            success:    null,
            loading:    false
        };

        this._textService = TextService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const texts = await this._textService.getTexts([
                TextV1Tag.ContactPageWelcome,
                TextV1Tag.ContactPageAdditional
            ]);

            this.setState({
                texts,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading text: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <div className='ContactPage'>
                <Hero title='Kontakt'>
                    <div className='ContactPage-hero-keke' />
                    <div className='ContactPage-hero-damen' />
                </Hero>
    
                <PageContent>
                    <IcErrorBox error={this.state.error} />

                    <div className='ContactPage-more'>
                        <TextBlock text={this.state.texts[TextV1Tag.ContactPageAdditional]} />
                    </div>

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        )
    }
}
