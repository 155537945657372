import React from 'react';
import DayJS from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { BlogService, BlogItemV1 } from '../../Services/BlogService';
import { TextService, TextV1, TextV1Tag } from '../../Services/TextService';
import { TextBlock } from '../../Components/TextBlock/TextBlock';
import { FileService } from '../../Services/FileService';
import { PageContent } from '../../Components/PageContent/PageContent';
import { Hero } from '../../Components/Hero/Hero';
import { IcErrorBox, IcSpinner, IcSpinnerSize, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './BlogPage.css';


export interface BlogPageProps
{
}


interface BlogPageState
{
    texts:      Partial<Record<TextV1Tag, TextV1>>;
    blogitems:   Array<BlogItemV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


export class BlogPage extends React.Component<BlogPageProps, BlogPageState>
{
    private readonly SIZE           = 30;
    private readonly _textService:  TextService;
    private readonly _fileService:  FileService;
    private readonly _blogService:  BlogService;


    constructor ( props: BlogPageProps )
    {
        super(props);

        this.state = {
            texts:      {},
            blogitems:   [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._textService = TextService.getInstance();
        this._fileService = FileService.getInstance();
        this._blogService = BlogService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const texts = await this._textService.getTexts([
                TextV1Tag.BlogPageWelcome
            ]);

            const blogitems = await this._blogService.getBlogItems(0, this.SIZE);

            this.setState({
                texts,
                blogitems,
                hasMore:    blogitems.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading blogitems: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const blogitems = await this._blogService.getBlogItems(this.state.blogitems.length, this.SIZE);

            this.setState({
                blogitems:     [
                    ...this.state.blogitems,
                    ...blogitems
                ],
                hasMore:    blogitems.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more blogitems: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _blogitemToURLPart ( blogitem: BlogItemV1 ): string
    {
        return `${blogitem.id}_${blogitem.title.replaceAll(/\W/gi, '_')}`
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <div className='BlogPage'>
                <Hero title='Aktuelles'>
                    <div className='BlogPage-hero-keke' />
                </Hero>

                <PageContent>
                    <IcErrorBox error={this.state.error} />

                    <div className='BlogPage-welcome'>
                        <TextBlock text={this.state.texts[TextV1Tag.BlogPageWelcome]} />
                    </div>

                    <InfiniteScroll
                        className='BlogPage-blogitems'
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        {this.state.blogitems.map( ( blogitem ) => (
                            <Link to={LinkUtils.make('blog', this._blogitemToURLPart(blogitem))} className='BlogPage-blogitem' key={blogitem.id}>
                                <h2>
                                    {blogitem.title}
                                </h2>
                                
                                <div className='BlogPage-blogitem-details'>
                                    <div className='BlogPage-blogitem-abstract'>
                                        <div className='BlogPage-blogitem-datetime'>
                                            Erstellt am {DayJS(blogitem.datetime_created).format('DD.MM.YYYY')}
                                        </div>

                                        {blogitem.abstract}

                                        <div className='BlogPage-blogitem-more'>
                                            Weiter lesen ...
                                        </div>
                                    </div>

                                    {blogitem.preview_image_uid ?
                                        <div className='BlogPage-blogitem-image'
                                            style={{
                                                backgroundImage: `url(${this._fileService.getFileDataURL(blogitem.preview_image_uid || '')})`
                                            }}
                                        />
                                    : null}
                                </div>
                            </Link>
                        ))}

                        {this.state.blogitems.length === 0 && !this.state.loading && !this.state.error ?
                            <div className='BlogPage-empty'>
                                Es wurden keine Beiträge gefunden.
                            </div>
                        : null}

                        <IcSpinner
                            size={IcSpinnerSize.Medium}
                            active={this.state.loading}
                        />
                    </InfiniteScroll>
                </PageContent>
            </div>
        );
    }
}
