import React from 'react';
import { InputText } from '../../Components/Input/InputText';
import { Modal } from '../../Components/Modal/Modal';
import { ModalContent } from '../../Components/Modal/ModalContent';
import { ModalTitle } from '../../Components/Modal/ModalTitle';
import { ModalService } from '../../Services/ModalService';
import { Form, Formik } from 'formik';

import './InputLinkModal.css';
import { IcButton } from '@indece-common/ic-ui-lib-react';


export interface InputLinkModalParams
{
    onFinish: ( title: string | null, url: string ) => any;
}


export interface InputLinkModalProps
{
}


interface InputLinkModalFormData
{
    title:  string;
    url:    string;
}


interface InputLinkModalState
{
    params:     InputLinkModalParams | null;
}


export class InputLinkModal extends React.Component<InputLinkModalProps, InputLinkModalState>
{
    private readonly INITIAL_FORMVALUES: InputLinkModalFormData = {
        title:  '',
        url:    ''
    };
    private readonly _modalService: ModalService;


    constructor ( props: InputLinkModalProps )
    {
        super(props);

        this.state = {
            params:     null
        };

        this._modalService  = ModalService.getInstance();

        this._close         = this._close.bind(this);
        this._submit        = this._submit.bind(this);
    }


    private _close ( ): void
    {
        this._modalService.hideInputLink();
    }


    private async _submit ( values: InputLinkModalFormData ): Promise<void>
    {
        if ( !values.url.trim() || !this.state.params )
        {
            return;
        }


        this.state.params.onFinish(
            values.title.trim() || null,
            values.url.trim()
        );

        this._modalService.hideInputLink();
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._modalService.getInputLink().subscribe(this, async ( params ) =>
        {
            this.setState({
                params
            });
        });
        
        const params = this._modalService.getInputLink().get();
        this.setState({
            params
        });
    }


    public componentWillUnmount ( ): void
    {
        this._modalService.getInputLink().unsubscribe(this);
    }


    public render ( )
    {
        if ( ! this.state.params )
        {
            return null;
        }

        return (
            <Modal
                className='InputLinkModal'
                closable={true}
                onClose={this._close}>
                <ModalTitle>Link einfügen</ModalTitle>

                <ModalContent>
                    <Formik
                        initialValues={this.INITIAL_FORMVALUES}
                        onSubmit={this._submit}>       
                        <Form>
                            <div className='InputLinkModal-fields'>
                                <InputText
                                    label='Titel'
                                    name='title'
                                />
                                
                                <InputText
                                    label='URL'
                                    name='url'
                                    required={true}
                                />

                                <IcButton type='submit'>
                                    Einfügen
                                </IcButton>
                            </div>
                        </Form>
                    </Formik>
                </ModalContent>
            </Modal>
        );
    }
}
