import React from 'react';
import { InputText } from '../../Components/Input/InputText';
import { Modal } from '../../Components/Modal/Modal';
import { ModalContent } from '../../Components/Modal/ModalContent';
import { ModalTitle } from '../../Components/Modal/ModalTitle';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { ModalService } from '../../Services/ModalService';
import { EventService, EventV1, EventV1Date } from '../../Services/EventService';
import { Form, Formik } from 'formik';
import { sleep } from 'ts-delay';
import { InfoBox } from '../../Components/InfoBox/InfoBox';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize } from '@indece-common/ic-ui-lib-react';

import './CancelEventDateModal.css';


export interface CancelEventDateModalParams
{
    eventID:        number;
    eventDateID:    number;
    onFinish:       ( ) => any;
}


export interface CancelEventDateModalProps
{
}


interface CancelEventDateModalFormData
{
    message:    string;
}


interface CancelEventDateModalState
{
    params:             CancelEventDateModalParams | null;
    event:              EventV1 | null;
    eventDate:          EventV1Date | null;
    loading:            boolean;
    success:            string | null;
    error:              Error | null;
}


export class CancelEventDateModal extends React.Component<CancelEventDateModalProps, CancelEventDateModalState>
{
    private readonly INITIAL_FORMVALUES: CancelEventDateModalFormData = {
        message:    ''
    };
    private readonly _eventService: EventService;
    private readonly _modalService: ModalService;


    constructor ( props: CancelEventDateModalProps )
    {
        super(props);

        this.state = {
            params:             null,
            event:              null,
            eventDate:          null,
            loading:            false,
            success:            null,
            error:              null
        };

        this._eventService  = EventService.getInstance();
        this._modalService  = ModalService.getInstance();

        this._close         = this._close.bind(this);
        this._submit        = this._submit.bind(this);
    }


    private _close ( ): void
    {
        this._modalService.hideCancelEventDate();
    }


    private async _applyParams ( params: CancelEventDateModalParams | null ): Promise<void>
    {
        if ( ! params )
        {
            this.setState({
                params:             null,
                event:              null,
                eventDate:          null,
                error:              null,
                success:            null
            });

            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                params
            });

            const event = await this._eventService.getEvent(params.eventID);
            const eventDate = event.dates.find( o => o.id === params.eventDateID ) || null;
    
            this.setState({
                event,
                eventDate,
                loading:    false,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading event date: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _submit ( values: CancelEventDateModalFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.params || !this.state.event || !this.state.eventDate )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            await this._eventService.cancelEventDate(
                this.state.event.id,
                this.state.eventDate.id,
                {
                    message: values.message.trim() || null
                }
            );

            this.setState({
                loading:    false,
                success:    'Der Termin wurde erfolgreich abgesagt.'
            });

            await sleep(1000);

            this.state.params.onFinish();

            this._modalService.hideCancelEventDate();
        }
        catch ( err )
        {
            console.error(`Error canceling event date: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._modalService.getCancelEventDate().subscribe(this, async ( params ) =>
        {
            await this._applyParams(params);
        });
        
        const params = this._modalService.getCancelEventDate().get();
        await this._applyParams(params);
    }


    public componentWillUnmount ( ): void
    {
        this._modalService.getCancelEventDate().unsubscribe(this);
    }


    public render ( )
    {
        if ( ! this.state.params )
        {
            return null;
        }

        return (
            <Modal
                className='CancelEventDateModal'
                closable={true}
                onClose={this._close}>
                <ModalTitle>Termin absagen</ModalTitle>

                <ModalContent>
                    <IcErrorBox error={this.state.error} />

                    {!this.state.success ?
                        <Formik
                            initialValues={this.INITIAL_FORMVALUES}
                            onSubmit={this._submit}>
                            <Form>
                                <div>
                                    Möchten Sie den Termin am {this.state.eventDate?.datetime_start} der Veranstaltung {this.state.event?.title} absagen?
                                </div>

                                <InfoBox>
                                    Es wird automatisch eine E-Mail an alle bestätigten Reservierungen über die Absage versandt.
                                </InfoBox>
                            
                                <InputText
                                    label='Nachricht'
                                    name='message'
                                />

                                <IcButton type='submit' disabled={this.state.loading}>
                                    Termin absagen
                                </IcButton>
                            </Form>
                        </Formik>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </ModalContent>
            </Modal>
        );
    }
}
