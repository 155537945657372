import React from 'react';
import { sleep } from 'ts-delay';
import { PageContent } from '../../Components/PageContent/PageContent';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { EventService, EventLocationV1 } from '../../Services/EventService';
import { MetadataService } from '../../Services/MetadataService';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './AdminDeleteEventLocationPage.css';


export interface AdminDeleteEventLocationPageRouteParams
{
    eventLocationID: string;
}


export interface AdminDeleteEventLocationPageProps extends RouteComponentProps<AdminDeleteEventLocationPageRouteParams>
{
}


interface AdminDeleteEventLocationPageState
{
    eventLocation:  EventLocationV1 | null;
    loading:        boolean;
    success:        string | null;
    error:          Error | null;
}


class $AdminDeleteEventLocationPage extends React.Component<AdminDeleteEventLocationPageProps, AdminDeleteEventLocationPageState>
{
    private readonly _eventService:     EventService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: AdminDeleteEventLocationPageProps )
    {
        super(props);

        this.state = {
            eventLocation:  null,
            loading:        true,
            success:        null,
            error:          null
        };

        this._eventService = EventService.getInstance();
        this._metadataService = MetadataService.getInstance();

        this._delete    = this._delete.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const eventLocationID = parseInt(this.props.router.params.eventLocationID, 10);
            const eventLocation = await this._eventService.getEventLocation(eventLocationID);

            this.setState({
                eventLocation,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading event: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _delete ( ): Promise<void>
    {
        if ( this.state.loading || !this.state.eventLocation )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            await this._eventService.deleteEventLocation(this.state.eventLocation.id);

            this.setState({
                success:    'Der Veranstaltungsort wurde erfolgreich gelöscht',
                loading:    false
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'veranstaltungsorte'));
        }
        catch ( err )
        {
            console.error(`Error updating event location: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._metadataService.setTitle('Veranstaltungsort löschen');

        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminDeleteEventLocationPage'>
                <PageContent noHeader={true}>
                    <h1>Veranstaltungsort löschen</h1>

                    <IcErrorBox error={this.state.error} />

                    {!this.state.success ?
                        <div>
                            <IcButton onClick={this._delete}>
                                Löschen
                            </IcButton>
                        </div>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}


export const AdminDeleteEventLocationPage = withRouter($AdminDeleteEventLocationPage);
