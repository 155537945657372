import { BackendService } from './BackendService';


export interface LocationV1
{
    id:                 number;
    title:              string;
    abstract:           string;
    preview_image_uid:  string | null;
    image_uids:         Array<string>;
    description:        string;
}


export interface AddLocationV1
{
    title:              string;
    abstract:           string;
    preview_image_uid:  string | null;
    image_uids:         Array<string>;
    description:        string;
}


export interface UpdateLocationV1
{
    title:              string;
    abstract:           string;
    preview_image_uid:  string | null;
    image_uids:         Array<string>;
    description:        string;
}


export class LocationService
{
    private static _instance:           LocationService;
    private readonly _backendService:   BackendService;
    
    
    public static getInstance ( ): LocationService
    {
        if ( ! this._instance )
        {
            this._instance = new LocationService();
        }
        
        return this._instance;
    }


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getLocations ( from: number, size: number ): Promise<Array<LocationV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/location?from=${encodeURIComponent(from)}&size=${encodeURIComponent(size)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.locations;
    }
   
   
    public async getLocation ( locationID: number ): Promise<LocationV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/location/${encodeURIComponent(locationID)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.location;
    }


    public async addLocation ( params: AddLocationV1 ): Promise<number>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/location`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.location_id;
    }
   
   
    public async updateLocation ( locationID: number, params: UpdateLocationV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/location/${encodeURIComponent(locationID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
   
   
    public async deleteLocation ( locationID: number ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/location/${encodeURIComponent(locationID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
}
