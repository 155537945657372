import React from 'react';
import { Form, Formik } from 'formik';
import { sleep } from 'ts-delay';
import { InputText } from '../../Components/Input/InputText';
import { PageContent } from '../../Components/PageContent/PageContent';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { EventService, EventV1 } from '../../Services/EventService';
import { MetadataService } from '../../Services/MetadataService';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './AdminCancelEventPage.css';


export interface AdminCancelEventPageRouteParams
{
    eventID: string;
}


export interface AdminCancelEventPageProps extends RouteComponentProps<AdminCancelEventPageRouteParams>
{
}


interface AdminCancelEventPageFormData
{
    message:    string;
}


interface AdminCancelEventPageState
{
    event:      EventV1 | null;
    loading:    boolean;
    success:    string | null;
    error:      Error | null;
}


class $AdminCancelEventPage extends React.Component<AdminCancelEventPageProps, AdminCancelEventPageState>
{
    private readonly INITIAL_FORMVALUES: AdminCancelEventPageFormData = {
        message:    ''
    };
    private readonly _eventService:     EventService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: AdminCancelEventPageProps )
    {
        super(props);

        this.state = {
            event:      null,
            loading:    true,
            success:    null,
            error:      null
        };

        this._eventService = EventService.getInstance();
        this._metadataService = MetadataService.getInstance();

        this._submit    = this._submit.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const eventID = parseInt(this.props.router.params.eventID, 10);
            const event = await this._eventService.getEvent(eventID);

            this.setState({
                event,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading event: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }
    
    
    private async _submit ( values: AdminCancelEventPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.event )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            await this._eventService.cancelEvent(
                this.state.event.id,
                {
                    message:    values.message.trim() || null
                }
            );

            this.setState({
                success:    'Die Veranstaltung wurde erfolgreich abgesagt',
                loading:    false
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'veranstaltungen'));
        }
        catch ( err )
        {
            console.error(`Error updating event: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._metadataService.setTitle('Veranstaltung absagen');

        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminCancelEventPage'>
                <PageContent noHeader={true}>
                    <h1>Veranstaltung absagen</h1>

                    <IcErrorBox error={this.state.error} />

                    {!this.state.success ?
                        <Formik
                            initialValues={this.INITIAL_FORMVALUES}
                            onSubmit={this._submit}>       
                            <Form>
                                <InputText
                                    label='Nachricht'
                                    name='message'
                                />
                            
                                <IcButton type='submit'>
                                    Absagen
                                </IcButton>
                            </Form>
                        </Formik>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}


export const AdminCancelEventPage = withRouter($AdminCancelEventPage);
