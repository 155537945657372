import React from 'react';
import { Form, Formik } from 'formik';
import { sleep } from 'ts-delay';
import { InputMarkdown } from '../../Components/Input/InputMarkdown';
import { InputText } from '../../Components/Input/InputText';
import { PageContent } from '../../Components/PageContent/PageContent';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { FriendService, FriendV1 } from '../../Services/FriendService';
import { MetadataService } from '../../Services/MetadataService';
import { IcButton, IcButtonColor, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './AdminEditFriendPage.css';


export interface AdminEditFriendPageRouteParams
{
    friendID: string;
}


export interface AdminEditFriendPageProps extends RouteComponentProps<AdminEditFriendPageRouteParams>
{
}


interface AdminEditFriendPageFormData
{
    title:              string;
    link:               string;
    preview_image_uid:  string | null;
    description:        string;
}


interface AdminEditFriendPageState
{
    initialFormValues:  AdminEditFriendPageFormData;
    friend:             FriendV1 | null;
    loading:            boolean;
    success:            string | null;
    error:              Error | null;
}


class $AdminEditFriendPage extends React.Component<AdminEditFriendPageProps, AdminEditFriendPageState>
{
    private readonly _friendService:    FriendService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: AdminEditFriendPageProps )
    {
        super(props);

        this.state = {
            initialFormValues: {
                title:              '',
                link:               '',
                preview_image_uid:  null,
                description:        ''
            },
            friend:     null,
            loading:    true,
            success:    null,
            error:      null
        };

        this._friendService = FriendService.getInstance();
        this._metadataService = MetadataService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._submit    = this._submit.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const friendID = parseInt(this.props.router.params.friendID, 10);
            const friend = await this._friendService.getFriend(friendID);

            this.setState({
                initialFormValues: {
                    title:              friend.title,
                    link:               friend.link || '',
                    preview_image_uid:  friend.preview_image_uid,
                    description:        friend.description
                },
                friend,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading friend: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }
    
    
    private async _submit ( values: AdminEditFriendPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.friend )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            const title = values.title.trim();
            const link = values.link.trim() || null;
            const preview_image_uid = values.preview_image_uid || null;
            const description = values.description.trim();

            await this._friendService.updateFriend(
                this.state.friend.id,
                {
                    title,
                    link,
                    preview_image_uid,
                    description
                }
            );

            this.setState({
                success:    'Der Freund wurde erfolgreich aktualisiert',
                loading:    false
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'freund_innen'));
        }
        catch ( err )
        {
            console.error(`Error updating friend: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._metadataService.setTitle('Freund bearbeiten');

        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminEditFriendPage'>
                <PageContent noHeader={true}>
                    <h1>Freund_In bearbeiten</h1>

                    <IcErrorBox error={this.state.error} />

                    {!this.state.success ?
                        <Formik
                            initialValues={this.state.initialFormValues}
                            enableReinitialize={true}
                            onSubmit={this._submit}>       
                            <Form>
                                <InputText
                                    label='Name'
                                    name='title'
                                    required={true}
                                />
                                
                                <InputText
                                    label='Link'
                                    name='link'
                                />
                               
                                <InputMarkdown
                                    label='Beschreibung'
                                    name='description'
                                />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        Abbrechen
                                    </IcButton>

                                    <IcButton type='submit'>
                                        Speichern
                                    </IcButton>
                                </IcFloatRow>
                            </Form>
                        </Formik>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}


export const AdminEditFriendPage = withRouter($AdminEditFriendPage);
