import React from 'react';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './InfoBox.css';


export interface InfoBoxProps
{
    children?:  React.ReactNode | undefined;
    className?: string;
}


export class InfoBox extends React.Component<InfoBoxProps>
{
    public render ( )
    {
        return (
            <div className={`InfoBox ${this.props.className || ''}`}>
                <div className='InfoBox-icon'>
                    <FontAwesomeIcon icon={faInfo} />
                </div>

                <div className='InfoBox-text'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
