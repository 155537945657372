import React from 'react';
import { Form, Formik } from 'formik';
import { Hero } from '../../Components/Hero/Hero';
import { PageContent } from '../../Components/PageContent/PageContent';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { EventReservationV1, EventService, EventV1, EventV1Date } from '../../Services/EventService';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface CancelReservationPageProps extends RouteComponentProps
{
}


interface CancelReservationPageState
{
    code:               string | null;
    event:              EventV1 | null;
    eventDate:          EventV1Date | null;
    eventReservation:   EventReservationV1 | null;
    loading:            boolean;
    success:            string | null;
    error:              Error | null;
}


class $CancelReservationPage extends React.Component<CancelReservationPageProps, CancelReservationPageState>
{
    private readonly _eventService: EventService;


    constructor ( props: CancelReservationPageProps )
    {
        super(props);

        this.state = {
            code:               null,
            event:              null,
            eventDate:          null,
            eventReservation:   null,
            loading:            false,
            success:            null,
            error:              null
        };

        this._eventService  = EventService.getInstance();

        this._submit        = this._submit.bind(this);
    }


    private async _load ( eventReservationID: number, code: string ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const eventReservation = await this._eventService.getEventReservation(eventReservationID, code);
            const event = await this._eventService.getEventByEventDate(eventReservation.eventdate_id);
            const eventDate = event.dates.find( o => o.id === eventReservation.eventdate_id ) || null;
    
            this.setState({
                code,
                event,
                eventDate,
                eventReservation,
                loading:    true,
                error:      null
            });
        }
        catch ( err )
        {
            console.error(`Error loading event reservation: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _submit ( values: any ): Promise<void>
    {
        if ( this.state.loading || !this.state.eventReservation || !this.state.code )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            await this._eventService.cancelEventReservation(
                this.state.eventReservation.id,
                {
                    code: this.state.code
                }
            );

            this.props.router.navigate(
                {
                    pathname: this.props.router.location.pathname,
                    search: '?canceled=1',
                },
                {
                    replace: true
                }
            );

            this.setState({
                loading:    false,
                success:    'Die Reservierung wurde erfolgreich gelöscht.'
            });
        }
        catch ( err )
        {
            console.error(`Error canceling event reservation: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        const search = new URLSearchParams(this.props.router.location.search);
        const eventReservationID = parseInt(search.get('eventreservation_id') || '0', 10);
        const code = (search.get('code') || '').trim();

        await this._load(eventReservationID, code);
    }


    public render ( )
    {
        return (
            <div className='CancelReservationPage'>
                <Hero title='Reservierung löschen'>
                </Hero>
        
                <PageContent>
                    <IcErrorBox error={this.state.error} />

                    {!this.state.success ?
                        <>
                            <div className='CancelReservationPage-text'>
                                Möchten Sie Ihre Reservierung für die Veranstaltung {this.state.event?.title} am {this.state.eventDate?.datetime_start} löschen?
                            </div>

                            <Formik
                                initialValues={{}}
                                onSubmit={this._submit}>       
                                <Form>
                                    <IcButton type='submit'>
                                        Reservierung löschen
                                    </IcButton>
                                </Form>
                            </Formik>
                        </>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        )
    }
}


export const CancelReservationPage = withRouter($CancelReservationPage);
