import React from 'react';
import DayJS from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';
import { faEdit, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageContent } from '../../Components/PageContent/PageContent';
import { EventService, EventV1, GetEventsV1Period } from '../../Services/EventService';
import { EventUtils } from '../../utils/EventUtils';
import { IcButton, IcButtonColor, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcFloatRowPadding, IcSpinner, IcSpinnerSize, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './AdminEventsPage.css';


export interface AdminEventsPageProps
{
}


interface AdminEventsPageState
{
    events:     Array<EventV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


export class AdminEventsPage extends React.Component<AdminEventsPageProps, AdminEventsPageState>
{
    private readonly SIZE               = 30;
    private readonly _eventService:     EventService;


    constructor ( props: AdminEventsPageProps )
    {
        super(props);

        this.state = {
            events:     [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._eventService = EventService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const events = await this._eventService.getEvents(0, this.SIZE, GetEventsV1Period.All);

            this.setState({
                events,
                hasMore:    events.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading events: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }
   
   
    private async _reload ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const size = Math.max(this.SIZE, this.state.events.length);
            const events = await this._eventService.getEvents(0, size, GetEventsV1Period.All);

            this.setState({
                events,
                hasMore:    events.length >= size,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error reloading events: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }
   
   
    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const events = await this._eventService.getEvents(this.state.events.length, this.SIZE, GetEventsV1Period.All);

            this.setState({
                events:     [
                    ...this.state.events,
                    ...events
                ],
                hasMore:    events.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading events: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();

        this._eventService.onEventChanged().subscribe(this, async ( ) =>
        {
            await this._reload();
        });
    }


    public componentWillUnmount ( ): void
    {
        this._eventService.onEventChanged().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <div className='AdminEventsPage'>
                <PageContent noHeader={true}>
                    <h1>Administration: Veranstaltungen</h1>

                    <IcErrorBox error={this.state.error} />

                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton to={LinkUtils.make('admin', 'veranstaltung', 'neu')}>
                            <FontAwesomeIcon icon={faPlus} />
                            
                            Neue Veranstaltung
                        </IcButton>
                    </IcFloatRow>

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        Datum
                                    </IcTableCell>

                                    <IcTableCell>
                                        Titel
                                    </IcTableCell>

                                    <IcTableCell />
                                </IcTableRow>
                            </IcTableHead>
                                
                            <IcTableBody>
                                {this.state.events.map( ( event ) => (
                                    <IcTableRow key={event.id}>
                                        <IcTableCell>
                                            {DayJS((EventUtils.getClosestDate(event) || EventUtils.getMinDate(event))?.datetime_start).format('DD.MM.YYYY HH:mm')} Uhr
                                        </IcTableCell>

                                        <IcTableCell>
                                            {event.title}
                                        </IcTableCell>

                                        <IcTableCell align={IcTableCellAlign.Right}>
                                            <IcFloatRow align={IcFloatRowAlign.Right} padding={IcFloatRowPadding.None}>
                                                <IcButton
                                                    color={IcButtonColor.Link}
                                                    to={LinkUtils.make('admin', 'veranstaltung', event.id, 'bearbeiten')}
                                                    title='Bearbeiten'>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </IcButton>
                                            
                                                <IcButton
                                                    color={IcButtonColor.Link}
                                                    to={LinkUtils.make('admin', 'veranstaltung', event.id, 'absagen')}
                                                    title='Absagen'>
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </IcButton>
                                            
                                                <IcButton
                                                    color={IcButtonColor.Link}
                                                    to={LinkUtils.make('admin', 'veranstaltung', event.id, 'loeschen')}
                                                    title='Löschen'>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </IcButton>
                                            </IcFloatRow>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.events.length === 0 && !this.state.loading && !this.state.error ?
                                    <IcTableRow>
                                        <IcTableCell
                                            colSpan={3}
                                            disabled={true}
                                            align={IcTableCellAlign.Center}>
                                            Es wurden keine Veranstaltungen gefunden.
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>

                        <IcSpinner
                            size={IcSpinnerSize.Medium}
                            active={this.state.loading}
                        />
                    </InfiniteScroll>
                </PageContent>
            </div>
        );
    }
}
