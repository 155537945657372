import React from 'react';
import { AuthContextProps, withAuth } from 'oidc-react';
import { BackendService } from '../../Services/BackendService';
import { Environment } from '../../utils/Environment';



interface EmptyPageProps extends AuthContextProps
{
}


class $EmptyPage extends React.Component<EmptyPageProps>
{
    public async componentDidMount ( ): Promise<void>
    {
        try
        {
            if ( ! this.props.userData )
            {
                window.sessionStorage.setItem('kulturbunt-afterloginpath', window.location.pathname);

                await this.props.signIn();
            }
        }
        catch ( err )
        {
            console.warn(`Could not login: ${(err as Error).message}`, err);
        }
    }


    public render ( )
    {
        return null;
    }
}


const EmptyPage = withAuth($EmptyPage);


export interface RouteGuardProps extends AuthContextProps
{
    component:  React.JSXElementConstructor<any>;
}


class $RouteGuard extends React.Component<RouteGuardProps>
{
    private readonly _backendService: BackendService;


    constructor ( props: RouteGuardProps )
    {
        super(props);

        this._backendService = BackendService.getInstance();
    }


    public render ( )
    {
        if ( Environment.stage !== 'local' &&
             !this.props.userData )
        {
            return (
                <EmptyPage />
            );
        }

        if ( this.props.userData )
        {
            this._backendService.setAccessToken(this.props.userData.access_token);
        }

        const Component = this.props.component;

        return (
            <>
                <Component />
            </>
        );
    }
}


export const RouteGuard = withAuth($RouteGuard);
