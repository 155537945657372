import React from 'react';
import DayJS from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlogService, BlogItemV1 } from '../../Services/BlogService';
import { PageContent } from '../../Components/PageContent/PageContent';
import { IcButton, IcButtonColor, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcFloatRowPadding, IcSpinner, IcSpinnerSize, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './AdminBlogPage.css';


export interface AdminBlogPageProps
{
}


interface AdminBlogPageState
{
    blogitems:  Array<BlogItemV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


export class AdminBlogPage extends React.Component<AdminBlogPageProps, AdminBlogPageState>
{
    private readonly SIZE           = 30;
    private readonly _blogService:  BlogService;


    constructor ( props: AdminBlogPageProps )
    {
        super(props);

        this.state = {
            blogitems:   [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._blogService = BlogService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const blogitems = await this._blogService.getBlogItems(0, this.SIZE);

            this.setState({
                blogitems,
                hasMore:    blogitems.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading blogitems: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const blogitems = await this._blogService.getBlogItems(this.state.blogitems.length, this.SIZE);

            this.setState({
                blogitems:     [
                    ...this.state.blogitems,
                    ...blogitems
                ],
                hasMore:    blogitems.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more blogitems: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminBlogPage'>
                <PageContent noHeader={true}>
                    <h1>Administration: Aktuelles / Blog</h1>

                    <IcErrorBox error={this.state.error} />

                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton to={LinkUtils.make('admin', 'blog', 'neu')}>
                            <FontAwesomeIcon icon={faPlus} /> Neuer Blog-Beitrag
                        </IcButton>
                    </IcFloatRow>

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        Datum
                                    </IcTableCell>

                                    <IcTableCell>
                                        Titel
                                    </IcTableCell>

                                    <IcTableCell />
                                </IcTableRow>
                            </IcTableHead>
                                
                            <IcTableBody>
                                {this.state.blogitems.map( ( item ) => (
                                    <IcTableRow key={item.id}>
                                        <IcTableCell>
                                            {DayJS(item.datetime_created).format('DD.MM.YYYY')}
                                        </IcTableCell>

                                        <IcTableCell>
                                            {item.title}
                                        </IcTableCell>
                                        
                                        <IcTableCell align={IcTableCellAlign.Right}>
                                            <IcFloatRow align={IcFloatRowAlign.Right} padding={IcFloatRowPadding.None}>
                                                <IcButton
                                                    color={IcButtonColor.Link}
                                                    title='Bearbeiten'
                                                    to={LinkUtils.make('admin', 'blog', item.id, 'bearbeiten')}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </IcButton>
                                            
                                                <IcButton
                                                    color={IcButtonColor.Link}
                                                    title='Löschen'
                                                    to={LinkUtils.make('admin', 'blog', item.id, 'loeschen')}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </IcButton>
                                            </IcFloatRow>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.blogitems.length === 0 && !this.state.loading && !this.state.error ?
                                    <IcTableRow>
                                        <IcTableCell
                                            colSpan={3}
                                            disabled={true}
                                            align={IcTableCellAlign.Center}>
                                            Es wurden keine Blog-Beiträge gefunden.
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>

                        <IcSpinner
                            size={IcSpinnerSize.Medium}
                            active={this.state.loading}
                        />
                    </InfiniteScroll>
                </PageContent>
            </div>
        );
    }
}
