import React from 'react';
import DayJS from 'dayjs';
import InfiniteScroll from 'react-infinite-scroller';
import { faCancel, faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageContent } from '../../Components/PageContent/PageContent';
import { EventReservationV1, EventService } from '../../Services/EventService';
import { ModalService } from '../../Services/ModalService';
import { IcButton, IcButtonColor, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcFloatRowPadding, IcSpinner, IcSpinnerSize, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow } from '@indece-common/ic-ui-lib-react';

import './AdminEventReservationsPage.css';


export interface AdminEventReservationsPageProps
{
}


interface AdminEventReservationsPageState
{
    eventReservations:  Array<EventReservationV1>;
    hasMore:            boolean;
    loading:            boolean;
    error:              Error | null;
}


export class AdminEventReservationsPage extends React.Component<AdminEventReservationsPageProps, AdminEventReservationsPageState>
{
    private readonly SIZE               = 30;
    private readonly _eventService:     EventService;
    private readonly _modalService:     ModalService;


    constructor ( props: AdminEventReservationsPageProps )
    {
        super(props);

        this.state = {
            eventReservations:  [],
            hasMore:            true,
            loading:            true,
            error:              null
        };

        this._eventService = EventService.getInstance();
        this._modalService = ModalService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const eventReservations = await this._eventService.getEventReservations(0, this.SIZE);

            this.setState({
                eventReservations,
                hasMore:    eventReservations.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading event reservations: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _reload ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const size = Math.max(this.SIZE, this.state.eventReservations.length);
            
            const eventReservations = await this._eventService.getEventReservations(0, size);

            this.setState({
                eventReservations,
                hasMore:    eventReservations.length >= size,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading event reservations: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const eventReservations = await this._eventService.getEventReservations(this.state.eventReservations.length, this.SIZE);

            this.setState({
                eventReservations:     [
                    ...this.state.eventReservations,
                    ...eventReservations
                ],
                hasMore:    eventReservations.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more event reservations: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _confirm ( eventReservationID: number ): void
    {
        this._modalService.showConfirmEventReservation({
            eventReservationID,
            onConfirm: ( ) => { } 
        });
    }


    private _reject ( eventReservationID: number ): void
    {
        this._modalService.showRejectEventReservation({
            eventReservationID,
            onReject: ( ) => { }
        });
    }


    private _delete ( eventReservationID: number ): void
    {
        this._modalService.showDeleteEventReservation({
            eventReservationID,
            onDelete: ( ) => { }
        });
    }


    private _renderStatus ( eventReservation: EventReservationV1 ): string
    {
        if ( eventReservation.datetime_canceled )
        {
            return 'Abgesagt';
        }
        
        if ( eventReservation.datetime_rejected )
        {
            return 'Abgelehnt';
        }
        
        if ( eventReservation.datetime_confirmed )
        {
            return 'Bestätigt';
        }
        
        return 'Neu';
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();

        this._eventService.onEventReservationChanged().subscribe(this, async ( ) =>
        {
            await this._reload();
        });
    }


    public componentWillUnmount ( ): void
    {
        this._eventService.onEventReservationChanged().unsubscribe(this);
    }


    public render ( )
    {
        return (
            <div className='AdminEventReservationsPage'>
                <PageContent noHeader={true}>
                    <h1>Administration: Reservierungen</h1>

                    <IcErrorBox error={this.state.error} />

                    <InfiniteScroll
                        className='AdminEventReservationsPage-eventreservations'
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto,
                                IcTableColWidthAuto
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        Status
                                    </IcTableCell>

                                    <IcTableCell>
                                        Veranstaltung
                                    </IcTableCell>

                                    <IcTableCell>
                                        Anzahl
                                    </IcTableCell>

                                    <IcTableCell>
                                        Besucher
                                    </IcTableCell>

                                    <IcTableCell />
                                </IcTableRow>
                            </IcTableHead>

                            <IcTableBody>
                                {this.state.eventReservations.map( ( eventReservation ) => (
                                    <IcTableRow key={eventReservation.id}>
                                        <IcTableCell>
                                            {this._renderStatus(eventReservation)}
                                        </IcTableCell>
                                            
                                        <IcTableCell>
                                            <div>
                                                {eventReservation.event.title}
                                            </div>

                                            <div>
                                                {DayJS(eventReservation.event.dates[0].datetime_start).format('DD.MM.YYYY')} | {DayJS(eventReservation.event.dates[0].datetime_start).format('HH:mm')} Uhr
                                            </div>
                                        </IcTableCell>

                                        <IcTableCell>
                                            {eventReservation.count} {eventReservation.count === 1 ? 'Platz' : 'Plätze'} 
                                        </IcTableCell>

                                        <IcTableCell>
                                            <div>
                                                {eventReservation.name}
                                            </div>

                                            <div>
                                                {eventReservation.email}
                                            </div>
                                            
                                            {eventReservation.phone ?
                                                <div>
                                                    {eventReservation.phone}
                                                </div>
                                            : null}
                                        </IcTableCell>

                                        <IcTableCell align={IcTableCellAlign.Right}>
                                            <IcFloatRow align={IcFloatRowAlign.Right} padding={IcFloatRowPadding.None}>
                                                {!eventReservation.datetime_confirmed && !eventReservation.datetime_rejected && !eventReservation.datetime_canceled ? 
                                                    <IcButton
                                                        color={IcButtonColor.Link}
                                                        title='Bestätigen'
                                                        onClick={ ( ) => this._confirm(eventReservation.id) }>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </IcButton>
                                                : null}

                                                {!eventReservation.datetime_rejected && !eventReservation.datetime_canceled ? 
                                                    <IcButton
                                                        color={IcButtonColor.Link}
                                                        title='Ablehnen'
                                                        onClick={ ( ) => this._reject(eventReservation.id) }>
                                                        <FontAwesomeIcon icon={faCancel} />
                                                    </IcButton>
                                                : null}

                                                <IcButton
                                                    color={IcButtonColor.Link}
                                                    title='Löschen'
                                                    onClick={ ( ) => this._delete(eventReservation.id) }>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </IcButton>
                                            </IcFloatRow>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.eventReservations.length === 0 && !this.state.loading && !this.state.error ?
                                    <IcTableRow>
                                        <IcTableCell
                                            colSpan={5}
                                            disabled={true}
                                            align={IcTableCellAlign.Center}>
                                            Es wurden keine Reservierungen gefunden.
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>

                        <IcSpinner
                            size={IcSpinnerSize.Medium}
                            active={this.state.loading}
                        />
                    </InfiniteScroll>
                </PageContent>
            </div>
        );
    }
}
