import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import './WarningBox.css';


export interface WarningBoxProps
{
    children?:  React.ReactNode | undefined;
    className?: string;
}


export class WarningBox extends React.Component<WarningBoxProps>
{
    public render ( )
    {
        return (
            <div className={`WarningBox ${this.props.className || ''}`}>
                <div className='WarningBox-icon'>
                    <FontAwesomeIcon icon={faWarning} />
                </div>

                <div className='WarningBox-text'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
