import TagManager from 'react-gtm-module';
import { CookieHintService, CookieSelection } from './CookieHintService';


export enum TrackingEventType
{
    ContactFormSuccess = 'contact_form_success'
}


export interface TrackingEvent
{
    event:      TrackingEventType;
    message?:   string;
}


export class TrackingService
{
    private static _instance:               TrackingService;
    private readonly _cookieHintService:    CookieHintService;
    private _initialized:                   boolean;


    constructor ( )
    {
        this._cookieHintService = CookieHintService.getInstance();
        this._initialized       = false;
    }


    public static getInstance ( ): TrackingService
    {
        if ( ! this._instance )
        {
            this._instance = new TrackingService();
        }

        return this._instance;
    }


    private _onSelection ( selection: CookieSelection ): void
    {
        if ( this._initialized )
        {
            return;
        }

        if ( (selection.analytics || selection.marketing) && ((window as any).CONFIG || {}).GTM_ID )
        {
            (window as any).COOKIE_SELECTION = {
                analytics: selection.analytics,
                marketing: selection.marketing
            };

            TagManager.initialize({
                gtmId: (window as any).CONFIG.GTM_ID
            });

            this._initialized = true;
        }
    }


    public init ( ): void
    {
        this._cookieHintService.getSelection().subscribe(this, ( selection ) =>
        {
            this._onSelection(selection);
        });

        const selection = this._cookieHintService.getSelection().get();
        this._onSelection(selection);
    }


    public push ( event: TrackingEvent ): void
    {
        if ( ! this._initialized )
        {
            return;
        }

        TagManager.dataLayer({
            dataLayer: event
        });
    }
}
