import * as React from 'react';
import { Field, FieldProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../Services/ModalService';
import { FileService, ImageV1Size } from '../../Services/FileService';

import './Input.css';


export interface InputSelectFileProps
{
    label:      string;
    name:       string;
    disabled?:  boolean;
    required?:  boolean;
}


export class InputSelectFile extends React.Component<InputSelectFileProps>
{
    private readonly _fileService:  FileService;
    private readonly _modalService: ModalService;


    constructor ( props: InputSelectFileProps )
    {
        super(props);

        this._fileService = FileService.getInstance();
        this._modalService = ModalService.getInstance();
    }


    private _select ( fieldProps: FieldProps<string> ): void
    {
        this._modalService.showSelectFile({
            onSelect: ( file ) =>
            {
                fieldProps.form.setFieldValue(fieldProps.field.name, file.uid)
            }
        });
    }


    public render ( )
    {
        return (
            <Field name={this.props.name}>
                {( fieldProps: FieldProps<string> ) => (
                    <label className={`InputSelectFile ${fieldProps.field.value ? '' : 'empty'} ${this.props.disabled ? '' : 'disabled'} ${fieldProps.meta.error ? 'error' : ''}`} onClick={ ( ) => this._select(fieldProps) }>
                        <div className='InputSelectFile-label'>
                            {this.props.label} {this.props.required ? '*' : ''}
                        </div>

                        {fieldProps.field.value ?
                            <img src={this._fileService.getFilePreviewURL(fieldProps.field.value, ImageV1Size._100x100)} alt='' />
                        : null}

                        <div className='InputSelectFile-value'>
                            {fieldProps.field.value ? fieldProps.field.value : ''}
                        </div>

                        <div className='InputSelectFile-suffix'>
                            <FontAwesomeIcon icon={faFile} />
                        </div>
                    </label>
                )}
            </Field>
        );
    }
}
