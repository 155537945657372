import React from 'react';
import { Form, Formik } from 'formik';
import { sleep } from 'ts-delay';
import { InputText } from '../../Components/Input/InputText';
import { PageContent } from '../../Components/PageContent/PageContent';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { EventService, EventLocationV1, Countries, Country } from '../../Services/EventService';
import { MetadataService } from '../../Services/MetadataService';
import { InputSelect } from '../../Components/Input/InputSelect';
import { Formatter } from '../../utils/Formatter';
import { InputTextarea } from '../../Components/Input/InputTextarea';
import { IcButton, IcButtonColor, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './AdminEditEventLocationPage.css';


export interface AdminEditEventLocationPageRouteParams
{
    eventLocationID: string;
}


export interface AdminEditEventLocationPageProps extends RouteComponentProps<AdminEditEventLocationPageRouteParams>
{
}


interface AdminEditEventLocationPageFormData
{
    title:          string;
    street:         string;
    zip:            string;
    city:           string;
    country:        string;
    description:    string;
}


interface AdminEditEventLocationPageState
{
    initialFormValues:  AdminEditEventLocationPageFormData;
    eventLocation:      EventLocationV1 | null;
    loading:            boolean;
    success:            string | null;
    error:              Error | null;
}


class $AdminEditEventLocationPage extends React.Component<AdminEditEventLocationPageProps, AdminEditEventLocationPageState>
{
    private readonly _eventService:     EventService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: AdminEditEventLocationPageProps )
    {
        super(props);

        this.state = {
            initialFormValues: {
                title:          '',
                street:         '',
                zip:            '',
                city:           '',
                country:        '',
                description:    ''
            },
            eventLocation:  null,
            loading:        true,
            success:        null,
            error:          null
        };

        this._eventService      = EventService.getInstance();
        this._metadataService   = MetadataService.getInstance();

        this._cancel    = this._cancel.bind(this);
        this._submit    = this._submit.bind(this);
    }


    private _cancel ( ): void
    {
        this.props.router.navigate(-1);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const eventLocationID = parseInt(this.props.router.params.eventLocationID, 10);
            const eventLocation = await this._eventService.getEventLocation(eventLocationID);

            this.setState({
                eventLocation,
                initialFormValues: {
                    title:          eventLocation.title,
                    street:         eventLocation.street || '',
                    zip:            eventLocation.zip || '',
                    city:           eventLocation.city || '',
                    country:        eventLocation.country || '',
                    description:    eventLocation.description,
                },
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading event: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }
    
    
    private async _submit ( values: AdminEditEventLocationPageFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.eventLocation )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            const title = values.title.trim();
            const street = values.street.trim() || null;
            const zip = values.zip.trim() || null;
            const city = values.city.trim() || null;
            const country = values.country.trim() as Country || null;
            const description = values.description.trim();

            await this._eventService.updateEventLocation(
                this.state.eventLocation.id,
                {
                    title,
                    street,
                    zip,
                    city,
                    country,
                    description
                }
            );

            this.setState({
                success:    'Der Veranstaltungsort wurde erfolgreich aktualisiert',
                loading:    false
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'veranstaltungsorte'));
        }
        catch ( err )
        {
            console.error(`Error updating event location: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._metadataService.setTitle('Veranstaltungsort bearbeiten');

        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminEditEventLocationPage'>
                <PageContent noHeader={true}>
                    <h1>Veranstaltungsort bearbeiten</h1>

                    <IcErrorBox error={this.state.error} />

                    {!this.state.success ?
                        <Formik
                            initialValues={this.state.initialFormValues}
                            enableReinitialize={true}
                            onSubmit={this._submit}>       
                            <Form>
                                <InputText
                                    label='Name'
                                    name='title'
                                    required={true}
                                />
                            
                                <InputText
                                    label='Straße / Hausnummer'
                                    name='street'
                                />
                                
                                <InputText
                                    label='Postleitzahl'
                                    name='zip'
                                />
                                
                                <InputText
                                    label='Ort'
                                    name='city'
                                />
                                
                                <InputSelect
                                    label='Land'
                                    name='country'
                                    options={Countries.map( country => ({
                                        label: Formatter.country(country),
                                        value: country
                                    }))}
                                />

                                <InputTextarea
                                    label='Zusatzinformation'
                                    name='description'
                                />

                                <IcFloatRow align={IcFloatRowAlign.Right}>
                                    <IcButton
                                        color={IcButtonColor.Link}
                                        onClick={this._cancel}>
                                        Abbrechen
                                    </IcButton>

                                    <IcButton type='submit'>
                                        Speichern
                                    </IcButton>
                                </IcFloatRow>
                            </Form>
                        </Formik>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}


export const AdminEditEventLocationPage = withRouter($AdminEditEventLocationPage);
