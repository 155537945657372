import React from 'react';
import { InputText } from '../../Components/Input/InputText';
import { Modal } from '../../Components/Modal/Modal';
import { ModalContent } from '../../Components/Modal/ModalContent';
import { ModalTitle } from '../../Components/Modal/ModalTitle';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { ModalService } from '../../Services/ModalService';
import { FileService, FileV1, ImageV1Size } from '../../Services/FileService';
import { Form, Formik } from 'formik';
import { InputFile } from '../../Components/Input/InputFile';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize } from '@indece-common/ic-ui-lib-react';

import './SelectFileModal.css';


export interface SelectFileModalParams
{
    onSelect: ( file: FileV1 ) => any;
}


export interface SelectFileModalProps
{
}


interface SelectFileModalFormData
{
    title:      string;
    file:       File | null;
}


interface SelectFileModalState
{
    params:     SelectFileModalParams | null;
    files:      Array<FileV1>;
    loading:    boolean;
    success:    string | null;
    error:      Error | null;
}


export class SelectFileModal extends React.Component<SelectFileModalProps, SelectFileModalState>
{
    private readonly INITIAL_FORMVALUES: SelectFileModalFormData = {
        title:  '',
        file:   null
    };
    private readonly _fileService:  FileService;
    private readonly _modalService: ModalService;


    constructor ( props: SelectFileModalProps )
    {
        super(props);

        this.state = {
            params:     null,
            files:      [],
            loading:    false,
            success:    null,
            error:      null
        };

        this._fileService   = FileService.getInstance();
        this._modalService  = ModalService.getInstance();

        this._close         = this._close.bind(this);
        this._submit        = this._submit.bind(this);
    }


    private _close ( ): void
    {
        this._modalService.hideSelectFile();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const files = await this._fileService.getFiles(0, 50);

            this.setState({
                loading:    false,
                files
            });
        }
        catch ( err )
        {
            console.error(`Error loading files: ${(err as Error).message}`, err);

            this.setState({
                loading:    true,
                error:      err as Error
            });
        }
    }


    private _base64EncodeFile ( file: File ): Promise<string>
    {
        return new Promise( ( resolve, reject ) =>
        {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = ( ) =>
            {
                resolve((reader.result as string).split(',', 2)[1]);
            };
            reader.onerror = reject;
        });
    }


    private async _submit ( values: SelectFileModalFormData ): Promise<void>
    {
        if ( this.state.loading || !values.file || !this.state.params )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            const data = await this._base64EncodeFile(values.file);

            const fileUID = await this._fileService.addFile({
                title: values.title.trim(),
                filename: values.file.name.trim(),
                mimetype: values.file.type,
                data
            });

            this.setState({
                loading:    false
            });

            this.state.params.onSelect({
                uid: fileUID,
                title: values.title.trim(),
                filename: values.file.name.trim(),
                mimetype: values.file.type
            });

            this._modalService.hideSelectFile();
        }
        catch ( err )
        {
            console.error(`Error uploading file: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private _selectFile ( file: FileV1 ): void
    {
        if ( ! this.state.params )
        {
            return;
        }

        this.state.params.onSelect(file);

        this._modalService.hideSelectFile();
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._modalService.getSelectFile().subscribe(this, async ( params ) =>
        {
            this.setState({
                params
            });

            if ( params )
            {
                await this._load();
            }
        });
        
        const params = this._modalService.getSelectFile().get();
        this.setState({
            params
        });

        if ( params )
        {
            await this._load();
        }
    }


    public componentWillUnmount ( ): void
    {
        this._modalService.getSelectFile().unsubscribe(this);
    }


    public render ( )
    {
        if ( ! this.state.params )
        {
            return null;
        }

        return (
            <Modal
                className='SelectFileModal'
                closable={true}
                onClose={this._close}>
                <ModalTitle>Datei auswählen</ModalTitle>

                <ModalContent>
                    <IcErrorBox error={this.state.error} />

                    <div className='SelectFileModal-upload'>
                        <div className='SelectFileModal-upload-title'>
                            Neue Datei hochladen:
                        </div>

                        {!this.state.success ?
                            <Formik
                                initialValues={this.INITIAL_FORMVALUES}
                                onSubmit={this._submit}>       
                                <Form>
                                    <div className='SelectFileModal-upload-fields'>
                                        <InputText
                                            label='Titel'
                                            name='title'
                                        />
                                        
                                        <InputFile
                                            label='Datei'
                                            name='file'
                                        />

                                        <IcButton type='submit'>
                                            Hochladen
                                        </IcButton>
                                    </div>
                                </Form>
                            </Formik>
                        : null}
    
                        <SuccessBox message={this.state.success} />
                    </div>


                    <div className='SelectFileModal-files'>
                        {this.state.files.map( ( file ) => (
                            <div
                                className='SelectFileModal-file'
                                key={file.uid}
                                onClick={ ( ) => this._selectFile(file) }>
                                <img
                                    className='SelectFileModal-file-image'
                                    src={this._fileService.getFilePreviewURL(file.uid, ImageV1Size._100x100)}
                                    alt=''
                                />

                                <div className='SelectFileModal-file-title'>
                                    {file.title}
                                </div>
                            </div>
                        ))}

                        {this.state.files.length === 0 ?
                            <div className='SelectFileModal-files-empty'>
                                Noch keine Dateien vorhanden
                            </div>
                        : null}
                    </div>

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </ModalContent>
            </Modal>
        );
    }
}
