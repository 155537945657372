import React from 'react';
import { sleep } from 'ts-delay';
import { PageContent } from '../../Components/PageContent/PageContent';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { LocationService, LocationV1 } from '../../Services/LocationService';
import { MetadataService } from '../../Services/MetadataService';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './AdminDeleteLocationPage.css';


export interface AdminDeleteLocationPageRouteParams
{
    locationID: string;
}


export interface AdminDeleteLocationPageProps extends RouteComponentProps<AdminDeleteLocationPageRouteParams>
{
}


interface AdminDeleteLocationPageState
{
    location:   LocationV1 | null;
    loading:    boolean;
    success:    string | null;
    error:      Error | null;
}


class $AdminDeleteLocationPage extends React.Component<AdminDeleteLocationPageProps, AdminDeleteLocationPageState>
{
    private readonly _locationService:  LocationService;
    private readonly _metadataService:  MetadataService;


    constructor ( props: AdminDeleteLocationPageProps )
    {
        super(props);

        this.state = {
            location:   null,
            loading:    true,
            success:    null,
            error:      null
        };

        this._locationService = LocationService.getInstance();
        this._metadataService = MetadataService.getInstance();

        this._delete    = this._delete.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const locationID = parseInt(this.props.router.params.locationID, 10);
            const location = await this._locationService.getLocation(locationID);

            this.setState({
                location,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading location: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }
    
    
    private async _delete ( ): Promise<void>
    {
        if ( this.state.loading || !this.state.location )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            await this._locationService.deleteLocation(this.state.location.id);

            this.setState({
                success:    'Die Location wurde erfolgreich gelöscht',
                loading:    false
            });

            await sleep(1000);

            this.props.router.navigate(LinkUtils.make('admin', 'locations'));
        }
        catch ( err )
        {
            console.error(`Error updating location: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._metadataService.setTitle('Location löschen');

        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminDeleteLocationPage'>
                <PageContent noHeader={true}>
                    <h1>Location löschen</h1>

                    <IcErrorBox error={this.state.error} />

                    {!this.state.success ?
                        <div>
                            Möchten Sie die Location wirklich löschen?<br />
                            <br />
                            <IcButton onClick={this._delete}>
                                Löschen
                            </IcButton>
                        </div>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}


export const AdminDeleteLocationPage = withRouter($AdminDeleteLocationPage);
