import { Subject } from 'ts-subject';
import { BackendService } from './BackendService';


export enum EventV1BookingType
{
    MuenchenTicket  = 'MUENCHEN_TICKET'
}


export interface EventV1Date
{
    id:                     number;
    datetime_start:         string;
    datetime_doors:         string | null;
    datetime_end:           string | null;
    reservation_enabled:    boolean;
    booking_enabled:        boolean;
    booking_type:           EventV1BookingType | null;
    booking_url:            string | null;
    full:                   boolean;
    canceled:               boolean;
}


export interface EventV1
{
    id:                         number;
    title:                      string;
    abstract:                   string;
    description:                string;
    price:                      string | null;
    email_confirmation_note:    string | null;
    email_rejection_note:       string | null;
    preview_image_uid:          string | null;
    image_uids:                 Array<string>;
    location:                   EventLocationV1;
    dates:                      Array<EventV1Date>;
}


export enum Country
{
    DE  = 'DE'
}


export const Countries: Array<Country> = [
    Country.DE
];


export interface EventLocationV1
{
    id:             number;
    title:          string;
    street:         string | null;
    zip:            string | null;
    city:           string | null;
    country:        Country | null;
    description:    string;
    image_uid:      string | null;
    lat:            number | null;
    lon:            number | null;
}


export interface EventReservationV1
{
    id:                 number;
    eventdate_id:       number;
    code:               string;
    name:               string;
    email:              string;
    phone:              string | null;
    address:            string | null;
    count:              number;
    datetime_created:   string;
    datetime_confirmed: string | null;
    datetime_rejected:  string | null;
    datetime_canceled:  string | null;
    event:              EventV1;
}


export interface AddEventV1
{
    title:                      string;
    abstract:                   string;
    description:                string;
    price:                      string | null;
    email_confirmation_note:    string | null;
    email_rejection_note:       string | null;
    preview_image_uid:          string | null;
    image_uids:                 Array<string>;
    eventlocation_id:           number;
}


export interface UpdateEventV1
{
    title:                      string;
    abstract:                   string;
    description:                string;
    price:                      string | null;
    email_confirmation_note:    string | null;
    email_rejection_note:       string | null;
    preview_image_uid:          string | null;
    image_uids:                 Array<string>;
    eventlocation_id:           number;
}


export interface CancelEventV1
{
    message:    string | null;
}


export interface AddEventDateV1
{
    datetime_start:         string;
    datetime_doors:         string | null;
    datetime_end:           string | null;
    reservation_enabled:    boolean;
    booking_enabled:        boolean;
    booking_type:           EventV1BookingType | null;
    booking_url:            string | null;
}


export interface UpdateEventDateV1
{
    datetime_start:         string;
    datetime_doors:         string | null;
    datetime_end:           string | null;
    reservation_enabled:    boolean;
    booking_enabled:        boolean;
    booking_type:           EventV1BookingType | null;
    booking_url:            string | null;
}


export interface SetEventDateFullV1
{
    full:       boolean;
}


export interface CancelEventDateV1
{
    message:    string | null;
}


export interface AddEventLocationV1
{
    title:          string;
    street:         string | null;
    zip:            string | null;
    city:           string | null;
    country:        Country | null;
    description:    string;
}


export interface UpdateEventLocationV1
{
    title:          string;
    street:         string | null;
    zip:            string | null;
    city:           string | null;
    country:        Country | null;
    description:    string;
}


export interface AddEventReservationV1
{
    eventdate_id:   number;
    name:           string;
    email:          string;
    phone:          string | null;
    address:        string | null;
    count:          number;
}


export interface ConfirmEventReservationV1
{
    message:    string | null;
}


export interface RejectEventReservationV1
{
    message:    string | null;
}


export interface CancelEventReservationV1
{
    code:       string | null;
}


export interface EventReservationCountV1
{
    count_total:        number;
    count_confirmed:    number;
    count_rejected:     number;
    count_canceled:     number;
    sum_total:          number;
    sum_confirmed:      number;
    sum_rejected:       number;
    sum_canceled:       number;
}


export enum GetEventsV1Period
{
    All     = 'ALL',
    Future  = 'FUTURE',
    Past    = 'PAST'
}


export class EventService
{
    private static _instance:                           EventService;
    private readonly _backendService:                   BackendService;
    private readonly _subjectEventChanged:              Subject<void>;
    private readonly _subjectEventReservationChanged:   Subject<void>;
    
    
    public static getInstance ( ): EventService
    {
        if ( ! this._instance )
        {
            this._instance = new EventService();
        }
        
        return this._instance;
    }


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
        this._subjectEventChanged = new Subject();
        this._subjectEventReservationChanged = new Subject();
    }


    public onEventChanged ( ): Subject<void>
    {
        return this._subjectEventChanged;
    }


    public onEventReservationChanged ( ): Subject<void>
    {
        return this._subjectEventReservationChanged;
    }


    public async getEvents ( from: number, size: number, period: GetEventsV1Period ): Promise<Array<EventV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/event?from=${encodeURIComponent(from)}&size=${encodeURIComponent(size)}&period=${encodeURIComponent(period)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.events;
    }

   
    public async getEvent ( eventID: number ): Promise<EventV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/event/${encodeURIComponent(eventID)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.event;
    }
  
    
    public async getEventByEventDate ( eventDateID: number ): Promise<EventV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/event?eventdate_id=${encodeURIComponent(eventDateID)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        if ( resp.events.length === 0 )
        {
            throw new Error('Event not found');
        }

        return resp.events[0];
    }
    
    
    public async countEventReservations ( eventID: number ): Promise<EventReservationCountV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/event/${encodeURIComponent(eventID)}/reservation/count`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp;
    }
   

    public async addEvent ( params: AddEventV1 ): Promise<number>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/event`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        this._subjectEventChanged.next();

        return resp.event_id;
    }
   
   
    public async updateEvent ( eventID: number, params: UpdateEventV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/event/${encodeURIComponent(eventID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        this._subjectEventChanged.next();
    }
   
    
    public async cancelEvent ( eventID: number, params: CancelEventV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/event/${encodeURIComponent(eventID)}/cancel`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        this._subjectEventChanged.next();
    }
   
   
    public async deleteEvent ( eventID: number ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/event/${encodeURIComponent(eventID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        this._subjectEventChanged.next();
    }

    
    public async countEventDateReservations ( eventID: number, eventDateID: number ): Promise<EventReservationCountV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/event/${encodeURIComponent(eventID)}/date/${encodeURIComponent(eventDateID)}/reservation/count`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp;
    }
   
   
    public async addEventDate ( eventID: number, params: AddEventDateV1 ): Promise<number>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/event/${encodeURIComponent(eventID)}/date`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        this._subjectEventChanged.next();

        return resp.event_id;
    }
   
   
    public async updateEventDate ( eventID: number, eventDateID: number, params: UpdateEventDateV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/event/${encodeURIComponent(eventID)}/date/${encodeURIComponent(eventDateID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        this._subjectEventChanged.next();
    }
   
    
    public async setEventDateFull ( eventID: number, eventDateID: number, params: SetEventDateFullV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/event/${encodeURIComponent(eventID)}/date/${encodeURIComponent(eventDateID)}/full`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        this._subjectEventChanged.next();
    }
   
    
    public async cancelEventDate ( eventID: number, eventDateID: number, params: CancelEventDateV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/event/${encodeURIComponent(eventID)}/date/${encodeURIComponent(eventDateID)}/cancel`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        this._subjectEventChanged.next();
    }
   
   
    public async deleteEventDate ( eventID: number, eventDateID: number ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/event/${encodeURIComponent(eventID)}/date/${encodeURIComponent(eventDateID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        this._subjectEventChanged.next();
    }


    public async getEventLocations ( ): Promise<Array<EventLocationV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/eventlocation`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.eventlocations;
    }
   
   
    public async getEventLocation ( eventLocationID: number ): Promise<EventLocationV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/eventlocation/${encodeURIComponent(eventLocationID)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.eventlocation;
    }
    
    
    public async addEventLocation ( params: AddEventLocationV1 ): Promise<number>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/eventlocation`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.eventlocation_id;
    }
   
   
    public async updateEventLocation ( eventLocationID: number, params: UpdateEventLocationV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/eventlocation/${encodeURIComponent(eventLocationID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
   
   
    public async deleteEventLocation ( eventLocationID: number ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/eventlocation/${encodeURIComponent(eventLocationID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }

    
    public async getEventReservations ( from: number, size: number ): Promise<Array<EventReservationV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/eventreservation?from=${encodeURIComponent(from)}&size=${encodeURIComponent(size)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.eventreservations;
    }
    
    public async getEventReservation ( eventReservationID: number, code: string | null ): Promise<EventReservationV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/eventreservation/${encodeURIComponent(eventReservationID)}?code=${encodeURIComponent(code || '')}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.eventreservation;
    }


    public async addEventReservation ( params: AddEventReservationV1 ): Promise<number>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/eventreservation`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        this._subjectEventReservationChanged.next();

        return resp.eventreservation_id;
    }
    
    
    public async confirmEventReservation ( eventReservationID: number, params: ConfirmEventReservationV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/eventreservation/${encodeURIComponent(eventReservationID)}/confirm`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        this._subjectEventReservationChanged.next();
    }
    
    
    public async rejectEventReservation ( eventReservationID: number, params: RejectEventReservationV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/eventreservation/${encodeURIComponent(eventReservationID)}/reject`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        this._subjectEventReservationChanged.next();
    }
    
   
    public async deleteEventReservation ( eventReservationID: number ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/eventreservation/${encodeURIComponent(eventReservationID)}`,
            {
                method:     'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        this._subjectEventReservationChanged.next();
    }
    
    
    public async cancelEventReservation ( eventReservationID: number, params: CancelEventReservationV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/eventreservation/${encodeURIComponent(eventReservationID)}/cancel`,
            {
                method:     'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        this._subjectEventReservationChanged.next();
    }
}
