import React from 'react';
import DayJS from 'dayjs';
import { Form, Formik, FormikErrors } from 'formik';
import { Modal } from '../../Components/Modal/Modal';
import { ModalContent } from '../../Components/Modal/ModalContent';
import { ModalTitle } from '../../Components/Modal/ModalTitle';
import { EventService, EventV1 } from '../../Services/EventService';
import { ModalService } from '../../Services/ModalService';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { InputText } from '../../Components/Input/InputText';
import { InputCheckbox } from '../../Components/Input/InputCheckbox';
import { InputSelect, InputSelectOption } from '../../Components/Input/InputSelect';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize } from '@indece-common/ic-ui-lib-react';

import './AddReservationModal.css';


export interface AddReservationModalParams
{
    eventID:        number;
    eventDateID:    number;
}


export interface AddReservationModalProps
{
}


interface AddReservationModalFormData
{
    email:          string;
    phone:          string;
    name:           string;
    count:          string;
    privacypolicy:  boolean;
}


interface AddReservationModalState
{
    params:     AddReservationModalParams | null;
    event:      EventV1 | null;
    loading:    boolean;
    error:      Error | null;
    success:    string | null;
}


export class AddReservationModal extends React.Component<AddReservationModalProps, AddReservationModalState>
{
    private readonly OPTIONS_COUNT: Array<InputSelectOption> = [
        {
            label: '1',
            value: '1'
        },
        {
            label: '2',
            value: '2'
        },
        {
            label: '3',
            value: '3'
        },
        {
            label: '4',
            value: '4'
        },
        {
            label: '5',
            value: '5'
        },
        {
            label: '6',
            value: '6'
        },
        {
            label: '7',
            value: '7'
        },
        {
            label: '8',
            value: '8'
        }
    ]
    private readonly INITIAL_FORMVALUES: AddReservationModalFormData = {
        email:  '',
        phone:  '',
        name:   '',
        count:  '1',
        privacypolicy: false
    };
    private readonly _eventService: EventService;
    private readonly _modalService: ModalService;


    constructor ( props: AddReservationModalProps )
    {
        super(props);

        this.state = {
            params:     null,
            event:      null,
            loading:    true,
            error:      null,
            success:    null
        };

        this._eventService = EventService.getInstance();
        this._modalService = ModalService.getInstance();

        this._submit    = this._submit.bind(this);
        this._validate  = this._validate.bind(this);
        this._close     = this._close.bind(this);
    }


    private _close ( ): void
    {
        this._modalService.hideAddReservation();
    }


    private async _load ( params: AddReservationModalParams | null ): Promise<void>
    {
        if ( ! params )
        {
            this.setState({
                params:     null,
                event:      null,
                success:    null,
                error:      null,
                loading:    true
            });

            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            const event = await this._eventService.getEvent(params.eventID);

            this.setState({
                event,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loadign event: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private _validate ( formData: AddReservationModalFormData ): FormikErrors<AddReservationModalFormData>
    {
        const formErrors: FormikErrors<AddReservationModalFormData> = {};

        if ( ! formData.email )
        {
            formErrors.email = 'Bitte geben Sie Ihre E-Mail-Adresse ein';
        }
        
        if ( ! formData.name )
        {
            formErrors.name = 'Bitte geben Sie Ihren Namen ein';
        }
        
        if ( ! formData.privacypolicy )
        {
            formErrors.privacypolicy = 'Bitte stimmen Sie der Datenschutzerklärung zu';
        }

        return formErrors;
    }


    private async _submit ( values: AddReservationModalFormData ): Promise<void>
    {
        if ( ! this.state.params )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null
            });

            await this._eventService.addEventReservation(
                {
                    eventdate_id:   this.state.params.eventDateID,
                    name:           values.name.trim(),
                    email:          values.email.trim(),
                    phone:          values.phone.trim() || null,
                    address:        null,
                    count:          parseInt(values.count, 10)
                }
            );

            this.setState({
                success:    'Vielen Dank für Ihre Reservierung. Sie erhalten von uns eine E-Mail sobald Ihre Reservierung manuell bestätigt wurde.',
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error adding reservation: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._modalService.getAddReservation().subscribe(this, async ( params ) =>
        {
            this.setState({
                params
            });

            await this._load(params);
        });
        
        const params = this._modalService.getAddReservation().get();
        this.setState({
            params
        });

        await this._load(params);
    }


    public componentWillUnmount ( ): void
    {
        this._modalService.getAddReservation().unsubscribe(this);
    }


    public render ( )
    {
        if ( ! this.state.params )
        {
            return null;
        }

        const eventDate = this.state.event?.dates.find( o => o.id === this.state.params!.eventDateID ) || null;

        return (
            <Modal
                className='AddReservationModal'
                closable={true}
                onClose={this._close}>
                <ModalTitle>Tickets reservieren</ModalTitle>

                <ModalContent>
                    <IcErrorBox error={this.state.error} />

                    {this.state.event && eventDate ?
                        <div className='AddReservationModal-event'>
                            <div className='AddReservationModal-event-title'>
                                Veranstaltung: {this.state.event.title}
                            </div>
                            
                            <div className='AddReservationModal-event-start'>
                                Datum: {DayJS(eventDate.datetime_start).format('DD.MM.YYYY')} | {DayJS(eventDate.datetime_start).format('HH:mm')} Uhr
                            </div>
                        </div>
                    : null}

                    {!this.state.success ?
                        <Formik
                            initialValues={this.INITIAL_FORMVALUES}
                            onSubmit={this._submit}
                            validate={this._validate}>
                            <Form>
                                <InputText
                                    name='name'
                                    label='Ihr Name'
                                    required={true}
                                />
                                
                                <InputText
                                    type='email'
                                    name='email'
                                    label='Ihre E-Mail-Adresse'
                                    required={true}
                                />
                               
                                <InputText
                                    name='phone'
                                    label='Ihre Telefonnummer'
                                />
                                
                                <InputSelect
                                    name='count'
                                    label='Anzahl der Plätze'
                                    options={this.OPTIONS_COUNT}
                                    required={true}
                                />

                                <InputCheckbox
                                    label='Ich habe die Datenschutzerklärung gelesen und stimme dieser zu.'
                                    name='privacypolicy'
                                />

                                <IcButton type='submit'>
                                    Reservieren
                                </IcButton>
                            </Form>
                        </Formik>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </ModalContent>
            </Modal>
        );
    }
}
