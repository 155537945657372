import React from 'react';
import { PageContent } from '../../Components/PageContent/PageContent';

import './Error404Page.css';


export interface Error404PageProps
{
}


export class Error404Page extends React.Component<Error404PageProps>
{
    public render ( )
    {
        return (
            <div className='Error404Page'>
                <PageContent>
                    <h1>404 Seite nicht gefunden</h1>
                </PageContent>
            </div>
        );
    }
}
