import { BackendService } from './BackendService';


export enum AssetV1Tag
{
    HomePageHeroImage1          = 'homepage_heroimage_1',
    HomePageHeroImage2          = 'homepage_heroimage_2',
    HomePageHeroImage3          = 'homepage_heroimage_3',
    HomePageHeroVideo           = 'homepage_herovideo'
}


export interface AssetV1
{
    tag:        AssetV1Tag;
    file_uid:   string | null;
}


export interface UpdateAssetV1
{
    file_uid:   string | null;
}


export class AssetService
{
    private static _instance:           AssetService;
    private readonly _backendService:   BackendService;
    
    
    public static getInstance ( ): AssetService
    {
        if ( ! this._instance )
        {
            this._instance = new AssetService();
        }
        
        return this._instance;
    }


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getAssets ( tags: Array<AssetV1Tag> ): Promise<Partial<Record<AssetV1Tag, AssetV1>>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/asset?tags=${tags.map(encodeURIComponent).join(',')}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        const assets: Partial<Record<AssetV1Tag, AssetV1>> = {};

        for ( const asset of resp.assets )
        {
            assets[asset.tag as AssetV1Tag] = asset;
        }

        return assets;
    }


    public async updateAsset ( tag: AssetV1Tag, params: UpdateAssetV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/asset/${encodeURIComponent(tag)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
}
