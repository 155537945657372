import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { Form, Formik } from 'formik';
import { InputText } from '../../Components/Input/InputText';
import { InputFile } from '../../Components/Input/InputFile';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { FileService, FileV1, ImageV1Size } from '../../Services/FileService';
import { PageContent } from '../../Components/PageContent/PageContent';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './AdminFilesPage.css';


export interface AdminFilesPageProps
{
}


interface AdminFilesPageFormData
{
    title:      string;
    file:       File | null;
}


interface AdminFilesPageState
{
    files:      Array<FileV1>;
    hasMore:    boolean;
    showUpload: boolean;
    loading:    boolean;
    success:    string | null;
    error:      Error | null;
}


export class AdminFilesPage extends React.Component<AdminFilesPageProps, AdminFilesPageState>
{
    private readonly INITIAL_FORMVALUES: AdminFilesPageFormData = {
        title:  '',
        file:   null
    };
    private readonly SIZE           = 30;
    private readonly _fileService:  FileService;


    constructor ( props: AdminFilesPageProps )
    {
        super(props);

        this.state = {
            files:      [],
            hasMore:    true,
            showUpload: false,
            loading:    true,
            success:    null,
            error:      null
        };

        this._fileService = FileService.getInstance();

        this._loadMore = this._loadMore.bind(this);
        this._submit = this._submit.bind(this);
        this._showUpload = this._showUpload.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const files = await this._fileService.getFiles(0, this.SIZE);

            this.setState({
                files,
                hasMore:    files.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading files: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const files = await this._fileService.getFiles(this.state.files.length, this.SIZE);

            this.setState({
                files:     [
                    ...this.state.files,
                    ...files
                ],
                hasMore:    files.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading files: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private _base64EncodeFile ( file: File ): Promise<string>
    {
        return new Promise( ( resolve, reject ) =>
        {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = ( ) =>
            {
                resolve((reader.result as string).split(',', 2)[1]);
            };
            reader.onerror = reject;
        });
    }


    private async _submit ( values: AdminFilesPageFormData ): Promise<void>
    {
        if ( this.state.loading || !values.file )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            const data = await this._base64EncodeFile(values.file);

            await this._fileService.addFile({
                title: values.title.trim(),
                filename: values.file.name.trim(),
                mimetype: values.file.type,
                data
            });

            this.setState({
                loading:    false,
                success:    'Die Dtaie wurde erfolgreich hochgeladen'
            });

            await this._load();
        }
        catch ( err )
        {
            console.error(`Error uploading file: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private _showUpload ( ): void
    {
        this.setState({
            showUpload: true
        });
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminFilesPage'>
                <PageContent noHeader={true}>
                    <h1>Administration: Dateien</h1>

                    <IcErrorBox error={this.state.error} />

                    <div className='AdminFilesPage-new'>
                        {this.state.showUpload ?
                            <div className='AdminFilesPage-new-form'>
                                <div className='AdminFilesPage-new-form-title'>
                                    Neue Datei hochladen:
                                </div>

                                {!this.state.success ?
                                    <Formik
                                        initialValues={this.INITIAL_FORMVALUES}
                                        onSubmit={this._submit}>       
                                        <Form>
                                            <div className='AdminFilesPage-new-form-fields'>
                                                <InputText
                                                    label='Titel'
                                                    name='title'
                                                />
                                                
                                                <InputFile
                                                    label='Datei'
                                                    name='file'
                                                />

                                                <IcButton type='submit'>
                                                    Hochladen
                                                </IcButton>
                                            </div>
                                        </Form>
                                    </Formik>
                                : null}
            
                                <SuccessBox message={this.state.success} />
                            </div>
                        : null}
                        
                        {!this.state.showUpload ?
                            <IcButton onClick={this._showUpload}>
                                <FontAwesomeIcon icon={faPlus} /> Datei hochladen
                            </IcButton>
                        : null}
                    </div>

                    <InfiniteScroll
                        className='AdminFilesPage-files'
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        {this.state.files.map( ( file ) => (
                            <div className='AdminFilesPage-file' key={file.uid}>
                                <div className='AdminFilesPage-file-preview'>
                                    <img src={this._fileService.getFilePreviewURL(file.uid, ImageV1Size._100x100)} alt='' />
                                </div>
                                
                                <div className='AdminFilesPage-file-title'>
                                    {file.title}
                                </div>

                                <div className='AdminFilesPage-file-actions'>
                                    <Link className='AdminFilesPage-file-action' title='Löschen' to={LinkUtils.make('admin', 'datei', file.uid, 'delete')}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Link>
                                </div>
                            </div>
                        ))}

                        {this.state.files.length === 0 && !this.state.loading && !this.state.error ?
                            <div className='AdminFilesPage-empty'>
                                Es wurden keine Dateien gefunden.
                            </div>
                        : null}

                        <IcSpinner
                            size={IcSpinnerSize.Medium}
                            active={this.state.loading}
                        />
                    </InfiniteScroll>
                </PageContent>
            </div>
        );
    }
}
