import React from 'react';
import { Hero } from '../../Components/Hero/Hero';
import { PageContent } from '../../Components/PageContent/PageContent';
import { TextBlock } from '../../Components/TextBlock/TextBlock';
import { TextService, TextV1, TextV1Tag } from '../../Services/TextService';
import { IcErrorBox, IcSpinner, IcSpinnerSize } from '@indece-common/ic-ui-lib-react';


export interface ImprintPageProps
{
}


interface ImprintPageState
{
    texts:      Partial<Record<TextV1Tag, TextV1>>;
    loading:    boolean;
    error:      Error | null;
}


export class ImprintPage extends React.Component<ImprintPageProps, ImprintPageState>
{
    private readonly _textService:      TextService;


    constructor ( props: ImprintPageProps )
    {
        super(props);

        this.state = {
            texts:      {},
            loading:    true,
            error:      null
        };

        this._textService = TextService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const texts = await this._textService.getTexts([
                TextV1Tag.ImprintPageContent
            ]);

            this.setState({
                texts,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading text: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <div className='ImprintPage'>
                <Hero
                    title='Impressum'
                />

                <PageContent>
                    <IcErrorBox error={this.state.error} />

                    <TextBlock text={this.state.texts[TextV1Tag.ImprintPageContent]} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </PageContent>
            </div>
        );
    }
}
