import React from 'react';
import { PageContent } from '../../Components/PageContent/PageContent';
import { LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface LoginSuccessPageProps extends RouteComponentProps
{
}


class $LoginSuccessPage extends React.Component<LoginSuccessPageProps>
{
    private _timeoutRedirect: any | null;


    constructor ( props: LoginSuccessPageProps )
    {
        super(props);

        this._timeoutRedirect = null;
    }


    public componentDidMount ( ): void
    {
        if ( this._timeoutRedirect )
        {
            clearTimeout(this._timeoutRedirect);
        }

        this._timeoutRedirect = setTimeout( ( ) =>
        {
            this._timeoutRedirect = null;

            try
            {
                const afterLoginURL = window.sessionStorage.getItem('kulturbunt-afterloginpath');

                if ( afterLoginURL )
                {
                    window.sessionStorage.removeItem('kulturbunt-afterloginpath');

                    this.props.router.navigate(afterLoginURL);
                }
                else
                {
                    this.props.router.navigate(LinkUtils.make('admin', 'home'));
                }
            }
            catch ( err )
            {
                console.error(`Error navigating to after-login path: ${(err as Error).message}`, err);

                this.props.router.navigate(LinkUtils.make('admin', 'home'));
            }
        }, 500);
    }


    public componentWillUnmount ( ): void
    {
        if ( this._timeoutRedirect )
        {
            clearTimeout(this._timeoutRedirect);
            this._timeoutRedirect = null;
        }
    }


    public render ( )
    {
        return (
            <div className='LoginSuccessPage'>
                <PageContent>
                </PageContent>
            </div>
        );
    }
}


export const LoginSuccessPage = withRouter($LoginSuccessPage);
