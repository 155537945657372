import React from 'react';
import { Modal } from '../../Components/Modal/Modal';
import { CookieHintService, CookieSelection } from '../../Services/CookieHintService';
import { ModalTitle } from '../../Components/Modal/ModalTitle';
import { ModalContent } from '../../Components/Modal/ModalContent';
import { sleep } from 'ts-delay';
import { InputCheckboxLegacy } from '../../Components/Input/InputCheckboxLegacy';
import { IcButton, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';
import { Link } from 'react-router-dom';

import './CookieHintModal.css';


interface CookieHintModalProps extends RouteComponentProps
{
}


interface CookieHintModalState
{
    wasSelected:    boolean;
    edit:           boolean;
    loading:        boolean;
    selection:      CookieSelection;
}


class $CookieHintModal extends React.Component<CookieHintModalProps, CookieHintModalState>
{
    private readonly _cookieHintService: CookieHintService;


    constructor ( props: CookieHintModalProps )
    {
        super(props);

        this.state = {
            selection: {
                analytics: true,
                marketing: false
            },
            edit:           false,
            loading:        false,
            wasSelected:    false
        }

        this._cookieHintService = CookieHintService.getInstance();
        this._onInputChange     = this._onInputChange.bind(this);
        this._confirmAll        = this._confirmAll.bind(this);
        this._confirmSelected   = this._confirmSelected.bind(this);
        this._edit   = this._edit.bind(this);
    }


    public componentDidMount ( )
    {
        if ( this._cookieHintService.wasSelected() )
        {
            this.setState({
                selection:      this._cookieHintService.getSelection().get(),
                wasSelected:    true
            });
        }
    }


    private _onInputChange ( evt: any )
    {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            selection: {
                ...this.state.selection,
                [name]: value
            }
        });
    }


    private _confirmAll ( ): void
    {
        this._cookieHintService.setSelection({
            analytics: true,
            marketing: true
        });

        this.setState({
            selection:      this._cookieHintService.getSelection().get(),
            wasSelected:    this._cookieHintService.wasSelected(),
            loading:        false
        });
    }


    private _confirmSelected ( ): void
    {
        this._cookieHintService.setSelection(this.state.selection);

        this.setState({
            selection:      this._cookieHintService.getSelection().get(),
            wasSelected:    this._cookieHintService.wasSelected(),
            loading:        false
        });
    }


    private async _edit ( ): Promise<void>
    {
        this.setState({
            loading:    true
        });

        await sleep(3000);
        
        this.setState({
            loading:    false,
            edit:       true
        });
    }


    public render ( )
    {
        if ( this.state.wasSelected ||
             this.props.router.location.pathname.startsWith('/imprint') ||
             this.props.router.location.pathname.startsWith('/privacypolicy') )
        {
            return null;
        }

        return (
            <Modal className='CookieHintModal' closable={false}>
                <ModalTitle>Cookie-Einstellungen</ModalTitle>

                <ModalContent>
                    <div className='CookieHintModal-content'>
                        <div className='CookieHintModal-text'>
                            Wir setzen Cookies ein, um Ihnen die Webseitennutzung zu erleichtern und unsere Website zu verbessern. Du entscheidest, welche Cookies Du zulassen oder ablehnen möchtest.
                            Ihre Entscheidung kann jederzeit in den Datenschutz-Einstellungen geändert werden.<br />
                            <br />
                            Weitere Infos finden Sie auch in unserer <Link to={LinkUtils.make('datenschutz')} target='_blank'>Datenschutzerklärung</Link>.
                        </div>

                        <form className='CookieHintModal-selection'>
                            {this.state.edit ?
                                <>
                                    <InputCheckboxLegacy
                                        name='required'
                                        value={true}
                                        disabled={true}
                                        label='Technisch notwendige Cookies'
                                    />

                                    <InputCheckboxLegacy
                                        name='analytics'
                                        value={this.state.selection.analytics}
                                        label='Cookies für Statistiken'
                                        onChange={this._onInputChange}
                                    />

                                    <InputCheckboxLegacy
                                        name='marketing'
                                        value={this.state.selection.marketing}
                                        label='Cookies für Marketing'
                                        onChange={this._onInputChange}
                                    />
                                </>
                            :
                                <ul>
                                <li>Technisch notwendige Cookies</li>
                                <li>Cookies für Statistiken</li>
                                <li>Cookies für Marketing</li>
                                </ul>
                            }
                                    
                            <IcSpinner
                                size={IcSpinnerSize.Medium}
                                active={this.state.loading}
                            />
                        </form>

                        <div className='CookieHintModal-actions'>
                            <div className='CookieHintModal-imprint'>
                                <Link to={LinkUtils.make('impressum')} target='_blank'>Impressum</Link>
                            </div>

                            {this.state.edit ?
                                <div className='CookieHintModal-confirmselected'>
                                    <span className='link' onClick={this._confirmSelected}>Auswahl bestätigen</span>
                                </div>
                            :
                                <div className='CookieHintModal-edit'>
                                    <span className='link' onClick={this._edit}>Auswahl bearbeiten</span>
                                </div>
                            }

                            <div className='CookieHintModal-confirmall'>
                                <IcButton onClick={this._confirmAll}>Alle akzeptieren</IcButton>
                            </div>
                        </div>
                    </div>
                </ModalContent>
            </Modal>
        );
    }
}


export const CookieHintModal = withRouter($CookieHintModal);
