import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { TextBlock } from '../../Components/TextBlock/TextBlock';
import { FriendService, FriendV1 } from '../../Services/FriendService';
import { FileService, ImageV1Size } from '../../Services/FileService';
import { TextService, TextV1, TextV1Tag } from '../../Services/TextService';
import { PageContent } from '../../Components/PageContent/PageContent';
import { Hero } from '../../Components/Hero/Hero';
import { IcErrorBox, IcSpinner, IcSpinnerSize, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './FriendsPage.css';


export interface FriendsPageProps
{
}


interface FriendsPageState
{
    texts:      Partial<Record<TextV1Tag, TextV1>>;
    friends:    Array<FriendV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


export class FriendsPage extends React.Component<FriendsPageProps, FriendsPageState>
{
    private readonly SIZE               = 30;
    private readonly _textService:      TextService;
    private readonly _fileService:      FileService;
    private readonly _friendService:    FriendService;


    constructor ( props: FriendsPageProps )
    {
        super(props);

        this.state = {
            texts:      {},
            friends:    [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._textService = TextService.getInstance();
        this._fileService = FileService.getInstance();
        this._friendService = FriendService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const texts = await this._textService.getTexts([
                TextV1Tag.FriendsPageWelcome
            ]);

            const friends = await this._friendService.getFriends(0, this.SIZE);

            this.setState({
                texts,
                friends,
                hasMore:    friends.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading friends: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        if ( this.state.loading )
        {
            return;
        }

        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const friends = await this._friendService.getFriends(this.state.friends.length, this.SIZE);

            this.setState({
                friends:     [
                    ...this.state.friends,
                    ...friends
                ],
                hasMore:    friends.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more friends: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <div className='FriendsPage'>
                <Hero title='Unsere Freund_Innen'>
                </Hero>

                <PageContent>
                    <IcErrorBox error={this.state.error} />

                    <div className='FriendsPage-welcome'>
                        <TextBlock text={this.state.texts[TextV1Tag.FriendsPageWelcome]} />
                    </div>

                    <InfiniteScroll
                        className='FriendsPage-friends'
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        {this.state.friends.map( ( friend ) => (
                            <div className='FriendsPage-friend' key={friend.id}>
                                <h2 className='FriendsPage-friend-title'>
                                    {friend.title}
                                </h2>

                                {friend.link ?
                                    <div className='FriendsPage-friend-link'>
                                        <a href={LinkUtils.absolute(friend.link)}>{friend.link}</a>
                                    </div>
                                : null}

                                <div className='FriendsPage-friend-content'>
                                    {friend.preview_image_uid ?
                                        <div className='FriendsPage-friend-image'>
                                            <img src={this._fileService.getFilePreviewURL(friend.preview_image_uid, ImageV1Size._500x500)} alt='' />
                                        </div>
                                    : null}

                                    <TextBlock
                                        text={friend.description}
                                    />
                                </div>
                            </div>
                        ))}

                        {this.state.friends.length === 0 && !this.state.loading && !this.state.error ?
                            <div className='FriendsPage-empty'>
                                Es wurden keine Einträge gefunden.
                            </div>
                        : null}
                        
                        <IcSpinner
                            size={IcSpinnerSize.Medium}
                            active={this.state.loading}
                        />
                    </InfiniteScroll>
                </PageContent>
            </div>
        );
    }
}
