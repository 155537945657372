import * as React from 'react';
import MDEditor, { commands, TextAreaTextApi, TextState } from '@uiw/react-md-editor';
import { Field, FieldProps } from 'formik';
import { ModalService } from '../../Services/ModalService';
import { FileService, ImageV1Size } from '../../Services/FileService';

import './Input.css';


export interface InputMarkdownProps
{
    name:       string;
    label?:     string;
    required?:  boolean;
}


export class InputMarkdown extends React.Component<InputMarkdownProps>
{
    private readonly _fileService: FileService;
    private readonly _modalService: ModalService;


    constructor ( props: InputMarkdownProps )
    {
        super(props);

        this._fileService = FileService.getInstance();
        this._modalService = ModalService.getInstance();
    }

    public render ( )
    {
        return (
            <Field name={this.props.name}>
                {( fieldProps: FieldProps<string> ) => (
                    <div className='InputMarkdown'>
                        {this.props.label ?
                            <div className='InputMarkdown-label'>{this.props.label} {this.props.required ? '*' : ''}</div>
                        : null}

                        <MDEditor
                            value={fieldProps.field.value}
                            commands={[
                                commands.bold,
                                commands.hr,
                                commands.italic,
                                commands.divider,
                                commands.codeEdit,
                                commands.codeLive,
                                commands.codePreview,
                                commands.divider,
                                commands.fullscreen, 
                                // Custom Toolbars
                                {
                                    name: 'Link einfügen',
                                    keyCommand: 'selectlink',
                                    buttonProps: { 'aria-label': 'Link einfügen' },
                                    icon: (
                                        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"/>
                                        </svg>
                                    ),
                                    execute: (state: TextState, api: TextAreaTextApi) => {
                                        this._modalService.showInputLink({
                                            onFinish: ( title, url ) =>
                                            {
                                                api.replaceSelection(`[${title || url}](${url})`);
                                            }
                                        });
                                    },
                                },
                                {
                                    name: 'Bild einfügen',
                                    keyCommand: 'selectfile',
                                    buttonProps: { 'aria-label': 'Bild einfügen' },
                                    icon: (
                                        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/>
                                        </svg>
                                    ),
                                    execute: (state: TextState, api: TextAreaTextApi) => {
                                        this._modalService.showSelectFile({
                                            onSelect: ( file ) =>
                                            {
                                                const url = this._fileService.getFilePreviewURL(file.uid, ImageV1Size._500x500);
                                                
                                                api.replaceSelection(`![${url}](${url})`);
                                            }
                                        });
                                    },
                                },
                                {
                                    name: 'Datei einfügen',
                                    keyCommand: 'selectfile',
                                    buttonProps: { 'aria-label': 'Datei einfügen' },
                                    icon: (
                                        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                            <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"/>
                                        </svg>
                                    ),
                                    execute: (state: TextState, api: TextAreaTextApi) => {
                                        this._modalService.showSelectFile({
                                            onSelect: ( file ) =>
                                            {
                                                const url = this._fileService.getFileDataURL(file.uid);
                                                
                                                api.replaceSelection(`[${file.title || file.filename}](${url})`);
                                            }
                                        });
                                    },
                                }
                            ]}
                            onBlur={fieldProps.field.onBlur}
                            onChange={ ( value ) => fieldProps.form.setFieldValue(fieldProps.field.name, value)}
                        />
                    </div>
                )}
            </Field>
        );
    }
}
