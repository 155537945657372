import { Country, EventLocationV1 } from '../Services/EventService';


const COUNTRIES_MAP: Record<Country, string> = {
    [Country.DE]:   'Deutschland'
};


export class Formatter
{
    public static country ( country: Country ): string
    {
        return COUNTRIES_MAP[country] || country;
    }
    
    
    public static eventLocationAddress ( eventLocation: EventLocationV1 ): string
    {
        const address: Array<string> = [];

        if ( eventLocation.street )
        {
            address.push(eventLocation.street);
        }

        if ( eventLocation.zip || eventLocation.city )
        {
            address.push(`${eventLocation.zip} ${eventLocation.city}`.trim());
        }

        return address.join(', ');
    }
}
