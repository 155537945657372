import React from 'react';
import { AuthContextProps, withAuth } from 'oidc-react';
import { Hero } from '../../Components/Hero/Hero';
import { PageContent } from '../../Components/PageContent/PageContent';
import { SlideShow } from '../../Components/SlideShow/SlideShow';
import { TextBlock } from '../../Components/TextBlock/TextBlock';
import { FileService, ImageV1Size } from '../../Services/FileService';
import { LocationService, LocationV1 } from '../../Services/LocationService';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize, LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './LocationPage.css';


export interface LocationPageRouteParams
{
    locationID: string;
}


export interface LocationPageProps extends RouteComponentProps<LocationPageRouteParams>, AuthContextProps
{
}


interface LocationPageState
{
    location:   LocationV1 | null;
    loading:    boolean;
    error:      Error | null;
}


class $LocationPage extends React.Component<LocationPageProps, LocationPageState>
{
    private readonly _locationService:  LocationService;
    private readonly _fileService:      FileService;


    constructor ( props: LocationPageProps )
    {
        super(props);

        this.state = {
            location:   null,
            loading:    true,
            error:      null
        };

        this._locationService = LocationService.getInstance();
        this._fileService = FileService.getInstance();
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const locationID = parseInt(this.props.router.params.locationID, 10);
            const location = await this._locationService.getLocation(locationID);

            this.setState({
                location,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading location: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <div className='LocationPage'>
                <Hero title={this.state.location ? this.state.location.title : '...'}>
                    {this.state.location && this.state.location.preview_image_uid ?
                        <div
                            className='LocationPage-hero-img'
                            style={{backgroundImage: `url(${this._fileService.getFilePreviewURL(this.state.location.preview_image_uid, ImageV1Size._500x500)})`}}
                        />
                    : null}
                </Hero>

                <PageContent>
                    <IcErrorBox error={this.state.error} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />

                    {this.state.location && this.props.userData ?
                        <IcButton to={LinkUtils.make('admin', 'location', this.state.location.id, 'edit')}>
                            Bearbeiten
                        </IcButton>
                    : null}

                    {this.state.location ?
                        <TextBlock text={this.state.location.description} />
                    : null}

                    {this.state.location ?
                        <SlideShow imageUIDs={[
                            ...(this.state.location.preview_image_uid ? [this.state.location.preview_image_uid] : []),
                            ...this.state.location.image_uids
                        ]} />
                    : null}
                </PageContent>
            </div>
        );
    }
}


export const LocationPage = withAuth(withRouter($LocationPage));
