import React from 'react';
import { ModalService } from '../../Services/ModalService';
import { LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';


export interface AdminConfirmEventReservationPageParams
{
    eventReservationID: string;
}


export interface AdminConfirmEventReservationPageProps extends RouteComponentProps<AdminConfirmEventReservationPageParams>
{
}


class $AdminConfirmEventReservationPage extends React.Component<AdminConfirmEventReservationPageProps>
{
    private readonly _modalService: ModalService;


    constructor ( props: AdminConfirmEventReservationPageProps )
    {
        super(props);

        this._modalService = ModalService.getInstance();
    }


    public async componentDidMount ( ): Promise<void>
    {
        const eventReservationID = parseInt(this.props.router.params.eventReservationID, 10);

        this._modalService.showConfirmEventReservation({
            eventReservationID,
            onConfirm: async ( ) =>
            {
                window.location.reload();   // TODO
            }
        });

        this.props.router.navigate(LinkUtils.make('admin', 'reservierungen'));
    }


    public render ( )
    {
        return (
            <div className='AdminConfirmEventReservationPage'>
            </div>
        );
    }
}


export const AdminConfirmEventReservationPage = withRouter($AdminConfirmEventReservationPage);
