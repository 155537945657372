import React from 'react';
import DayJS from 'dayjs';
import { Modal } from '../../Components/Modal/Modal';
import { ModalContent } from '../../Components/Modal/ModalContent';
import { ModalTitle } from '../../Components/Modal/ModalTitle';
import { SuccessBox } from '../../Components/SuccessBox/SuccessBox';
import { ModalService } from '../../Services/ModalService';
import { EventReservationV1, EventService, EventV1, EventV1Date } from '../../Services/EventService';
import { Form, Formik } from 'formik';
import { sleep } from 'ts-delay';
import { WarningBox } from '../../Components/WarningBox/WarningBox';
import { IcButton, IcErrorBox, IcSpinner, IcSpinnerSize } from '@indece-common/ic-ui-lib-react';

import './DeleteEventReservationModal.css';


export interface DeleteEventReservationModalParams
{
    eventReservationID: number;
    onDelete:          ( eventReservationID: number ) => any;
}


export interface DeleteEventReservationModalProps
{
}


interface DeleteEventReservationModalFormData
{
    message:    string;
}


interface DeleteEventReservationModalState
{
    params:             DeleteEventReservationModalParams | null;
    event:              EventV1 | null;
    eventDate:          EventV1Date | null;
    eventReservation:   EventReservationV1 | null;
    loading:            boolean;
    success:            string | null;
    error:              Error | null;
}


export class DeleteEventReservationModal extends React.Component<DeleteEventReservationModalProps, DeleteEventReservationModalState>
{
    private readonly INITIAL_FORMVALUES: DeleteEventReservationModalFormData = {
        message:    ''
    };
    private readonly _eventService: EventService;
    private readonly _modalService: ModalService;


    constructor ( props: DeleteEventReservationModalProps )
    {
        super(props);

        this.state = {
            params:             null,
            event:              null,
            eventDate:          null,
            eventReservation:   null,
            loading:            false,
            success:            null,
            error:              null
        };

        this._eventService  = EventService.getInstance();
        this._modalService  = ModalService.getInstance();

        this._close         = this._close.bind(this);
        this._submit        = this._submit.bind(this);
    }


    private _close ( ): void
    {
        this._modalService.hideDeleteEventReservation();
    }


    private async _applyParams ( params: DeleteEventReservationModalParams | null ): Promise<void>
    {
        if ( ! params )
        {
            this.setState({
                params:             null,
                event:              null,
                eventDate:          null,
                eventReservation:   null,
                error:              null,
                success:            null
            });

            return;
        }

        try
        {
            this.setState({
                params,
                loading:    true,
                error:      null
            });

            const eventReservation = await this._eventService.getEventReservation(params.eventReservationID, null);
            const event = await this._eventService.getEventByEventDate(eventReservation.eventdate_id);
            const eventDate = event.dates.find( o => o.id === eventReservation.eventdate_id ) || null;
    
            this.setState({
                event,
                eventDate,
                eventReservation,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading event reservation: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    private async _submit ( values: DeleteEventReservationModalFormData ): Promise<void>
    {
        if ( this.state.loading || !this.state.params )
        {
            return;
        }

        try
        {
            this.setState({
                loading:    true,
                error:      null,
                success:    null
            });

            await this._eventService.deleteEventReservation(
                this.state.params.eventReservationID
            );

            this.setState({
                loading:    false,
                success:    'Die Reservierung wurde erfolgreich gelöscht.'
            });

            await sleep(1000);

            this.state.params.onDelete(this.state.params.eventReservationID);

            this._modalService.hideDeleteEventReservation();
        }
        catch ( err )
        {
            console.error(`Error deleting event reservation: ${(err as Error).message}`, err);

            this.setState({
                loading:    false,
                error:      err as Error
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        this._modalService.getDeleteEventReservation().subscribe(this, async ( params ) =>
        {
            await this._applyParams(params);
        });
        
        const params = this._modalService.getDeleteEventReservation().get();
        await this._applyParams(params);
    }


    public componentWillUnmount ( ): void
    {
        this._modalService.getDeleteEventReservation().unsubscribe(this);
    }


    public render ( )
    {
        if ( ! this.state.params )
        {
            return null;
        }

        return (
            <Modal
                className='DeleteEventReservationModal'
                closable={true}
                onClose={this._close}>
                <ModalTitle>Reservierung löschen</ModalTitle>

                <ModalContent>
                    <IcErrorBox error={this.state.error} />

                    {this.state.event && this.state.eventDate && this.state.eventReservation ?
                        <div className='DeleteEventReservationModal-event'>
                            <div className='DeleteEventReservationModal-event-row'>
                                Veranstaltung: {this.state.event.title}
                            </div>
                            
                            <div className='DeleteEventReservationModal-event-row'>
                                Datum: {DayJS(this.state.eventDate.datetime_start).format('DD.MM.YYYY')} | {DayJS(this.state.eventDate.datetime_start).format('HH:mm')} Uhr
                            </div>
                            
                            <div className='DeleteEventReservationModal-event-row'>
                                Name: {this.state.eventReservation.name}
                            </div>
                            
                            <div className='DeleteEventReservationModal-event-row'>
                                E-Mail: {this.state.eventReservation.email}
                            </div>
                            
                            <div className='DeleteEventReservationModal-event-row'>
                                Telefon: {this.state.eventReservation.phone || '-'}
                            </div>
                            
                            <div className='DeleteEventReservationModal-event-row'>
                                Plätze: {this.state.eventReservation.count}
                            </div>
                        </div>
                    : null}

                    {!this.state.success ?
                        <Formik
                            initialValues={this.INITIAL_FORMVALUES}
                            onSubmit={this._submit}>
                            <Form>
                                <div>
                                    Soll die Reservierung gelöscht werden?
                                </div>

                                <WarningBox>
                                    Beim Löschen der Reservierung wird <b>keine</b> E-Mail an den Benutzer gesendet.
                                </WarningBox> 

                                <IcButton type='submit'>
                                    Reservierung löschen
                                </IcButton>
                            </Form>
                        </Formik>
                    : null}

                    <SuccessBox message={this.state.success} />

                    <IcSpinner
                        size={IcSpinnerSize.Medium}
                        active={this.state.loading}
                    />
                </ModalContent>
            </Modal>
        );
    }
}
