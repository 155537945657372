import React from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import { faBars, faSignOut, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContextProps, withAuth } from 'oidc-react';
import { LinkUtils, RouteComponentProps, withRouter } from '@indece-common/ic-ui-lib-react';

import './NavBar.css';


export interface NavBarProps extends AuthContextProps, RouteComponentProps
{
}


interface NavBarState
{
    expanded:   boolean;
}


class $NavBar extends React.Component<NavBarProps, NavBarState>
{
    constructor ( props: NavBarProps )
    {
        super(props);

        this.state = {
            expanded:   false
        };

        this._toggle = this._toggle.bind(this);
        this._hide = this._hide.bind(this);
        this._logout = this._logout.bind(this);
    }


    private _toggle ( ): void
    {
        this.setState({
            expanded: !this.state.expanded
        });
    }
    
    
    private _hide ( ): void
    {
        this.setState({
            expanded: false
        });
    }

    
    private async _logout ( ): Promise<void>
    {
        const cookies = new Cookies();

        cookies.remove('kulturbunt-signedin');
        
        window.sessionStorage.removeItem('kulturbunt-afterloginpath');

        await this.props.signOutRedirect();
    }


    public render ( )
    {
        return (
            <div className='NavBar'>
                {this.props.userData && this.props.userData.profile ?
                    <div className='NavBar-admin'>
                        <div className='NavBar-admin-inner'>
                            <div className='NavBar-admin-title'>
                                Admin-Panel
                            </div>

                            <div className='NavBar-space' />

                            <div className='NavBar-admin-items'>
                                <Link to={LinkUtils.make('admin', 'home')} className='NavBar-admin-item'>
                                    Home
                                </Link>
                                
                                <Link to={LinkUtils.make('admin', 'veranstaltungen')} className='NavBar-admin-item'>
                                    Veranstaltungen
                                </Link>
                                
                                <Link to={LinkUtils.make('admin', 'reservierungen')} className='NavBar-admin-item'>
                                    Reservierungen
                                </Link>
                                
                                <Link to={LinkUtils.make('admin', 'freund_innen')} className='NavBar-admin-item'>
                                    Freund_Innen
                                </Link>
                                
                                <Link to={LinkUtils.make('admin', 'locations')} className='NavBar-admin-item'>
                                    Räumlichkeiten
                                </Link>
                                
                                <Link to={LinkUtils.make('admin', 'blog')} className='NavBar-admin-item'>
                                    Aktuelles
                                </Link>

                                <Link to={LinkUtils.make('admin', 'status')} className='NavBar-admin-item'>
                                    Status
                                </Link>
                                
                                <div className='NavBar-admin-item'>
                                    {this.props.userData.profile.name}

                                    <div onClick={this._logout}>
                                        <FontAwesomeIcon icon={faSignOut} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : null}

                <div className='NavBar-main'>
                    <div className='NavBar-inner'>
                        <div
                            className={'NavBar-items' + (this.state.expanded ? ' expanded' : '')}
                            onClickCapture={this._hide}>
                            <div className='NavBar-closebutton' onClick={this._hide}>
                                <FontAwesomeIcon icon={faTimes} />
                            </div>

                            <Link to={LinkUtils.make()} className='NavBar-item'>
                                Home
                            </Link>
                           
                            <Link to={LinkUtils.make('veranstaltungen')} className='NavBar-item'>
                                Spielplan
                            </Link>

                            <Link to={LinkUtils.make('locations')} className='NavBar-item'>
                                Räumlichkeiten
                            </Link>

                            <Link to={LinkUtils.make('freund_innen')} className='NavBar-item'>
                                Freund_Innen
                            </Link>

                            <Link to={LinkUtils.make('blog')} className='NavBar-item'>
                                Aktuelles
                            </Link>

                            <Link to={LinkUtils.make('kontakt')} className='NavBar-item'>
                                Kontakt
                            </Link>
                        </div>

                        <div className='NavBar-space' />

                        <div className='NavBar-menubutton' onClick={this._toggle}>
                            <FontAwesomeIcon icon={faBars} />
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}


export const NavBar = withRouter(withAuth($NavBar));
