import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocationService, LocationV1 } from '../../Services/LocationService';
import { PageContent } from '../../Components/PageContent/PageContent';
import { IcButton, IcButtonColor, IcErrorBox, IcFloatRow, IcFloatRowAlign, IcFloatRowPadding, IcSpinner, IcSpinnerSize, IcTable, IcTableBody, IcTableCell, IcTableCellAlign, IcTableColWidthAuto, IcTableHead, IcTableRow, LinkUtils } from '@indece-common/ic-ui-lib-react';

import './AdminLocationsPage.css';


export interface AdminLocationsPageProps
{
}


interface AdminLocationsPageState
{
    locations:  Array<LocationV1>;
    hasMore:    boolean;
    loading:    boolean;
    error:      Error | null;
}


export class AdminLocationsPage extends React.Component<AdminLocationsPageProps, AdminLocationsPageState>
{
    private readonly SIZE               = 30;
    private readonly _locationService:  LocationService;


    constructor ( props: AdminLocationsPageProps )
    {
        super(props);

        this.state = {
            locations:  [],
            hasMore:    true,
            loading:    true,
            error:      null
        };

        this._locationService = LocationService.getInstance();

        this._loadMore = this._loadMore.bind(this);
    }


    private async _load ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });
            
            const locations = await this._locationService.getLocations(0, this.SIZE);

            this.setState({
                locations,
                hasMore:    locations.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading locations: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    private async _loadMore ( ): Promise<void>
    {
        try
        {
            this.setState({
                error:      null,
                loading:    true
            });

            const locations = await this._locationService.getLocations(this.state.locations.length, this.SIZE);

            this.setState({
                locations:     [
                    ...this.state.locations,
                    ...locations
                ],
                hasMore:    locations.length >= this.SIZE,
                loading:    false
            });
        }
        catch ( err )
        {
            console.error(`Error loading more locations: ${(err as Error).message}`, err);

            this.setState({
                error:      err as Error,
                loading:    false
            });
        }
    }


    public async componentDidMount ( ): Promise<void>
    {
        await this._load();
    }


    public render ( )
    {
        return (
            <div className='AdminLocationsPage'>
                <PageContent noHeader={true}>
                    <h1>Administration: Locations</h1>

                    <IcErrorBox error={this.state.error} />

                    <IcFloatRow align={IcFloatRowAlign.Right}>
                        <IcButton to={LinkUtils.make('admin', 'location', 'add')}>
                            <FontAwesomeIcon icon={faPlus} />
                            
                            Neue Location
                        </IcButton>
                    </IcFloatRow>

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this._loadMore}
                        initialLoad={false}
                        hasMore={this.state.hasMore}
                        threshold={50}
                        useWindow={true}>
                        <IcTable
                            cols={[
                                IcTableColWidthAuto,
                                IcTableColWidthAuto
                            ]}>
                            <IcTableHead>
                                <IcTableRow>
                                    <IcTableCell>
                                        Name
                                    </IcTableCell>

                                    <IcTableCell />
                                </IcTableRow>
                            </IcTableHead>
                                
                            <IcTableBody>
                                {this.state.locations.map( ( loc ) => (
                                    <IcTableRow key={loc.id}>
                                        <IcTableCell>
                                            {loc.title}
                                        </IcTableCell>

                                        <IcTableCell align={IcTableCellAlign.Right}>
                                            <IcFloatRow align={IcFloatRowAlign.Right} padding={IcFloatRowPadding.None}>
                                                <IcButton
                                                    color={IcButtonColor.Link}
                                                    title='Bearbeiten'
                                                    to={LinkUtils.make('admin', 'location', loc.id, 'edit')}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </IcButton>
                                            
                                                <IcButton
                                                    color={IcButtonColor.Link}
                                                    title='Löschen'
                                                    to={LinkUtils.make('admin', 'location', loc.id, 'delete')}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </IcButton>
                                            </IcFloatRow>
                                        </IcTableCell>
                                    </IcTableRow>
                                ))}

                                {this.state.locations.length === 0 && !this.state.loading && !this.state.error ?
                                    <IcTableRow>
                                        <IcTableCell
                                            colSpan={2}
                                            disabled={true}
                                            align={IcTableCellAlign.Center}>
                                            Es wurden keine Locations gefunden.
                                        </IcTableCell>
                                    </IcTableRow>
                                : null}
                            </IcTableBody>
                        </IcTable>

                        <IcSpinner
                            size={IcSpinnerSize.Medium}
                            active={this.state.loading}
                        />
                    </InfiniteScroll>
                </PageContent>
            </div>
        );
    }
}
