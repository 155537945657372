import React from 'react';
import Cookies from 'universal-cookie';
import { Environment } from './utils/Environment';
import { AuthProvider, AuthProviderProps } from 'oidc-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { TrackingService } from './Services/TrackingService';
import { ScrollToTopController } from './Controllers/ScrollToTopController/ScrollToTopController';
import { Footer } from './Components/Footer/Footer';
import { NavBar } from './Components/NavBar/NavBar';
import { CookieHintModal } from './Modals/CookieHintModal/CookieHintModal';
import { ContactPage } from './Pages/ContactPage/ContactPage';
import { HomePage } from './Pages/HomePage/HomePage';
import { AdminLoginPage } from './Pages/AdminLoginPage/AdminLoginPage';
import { Error404Page } from './Pages/Error404Page/Error404Page';
import { ImprintPage } from './Pages/ImprintPage/ImprintPage';
import { PrivacyPolicyPage } from './Pages/PrivacyPolicyPage/PrivacyPolicyPage';
import { EventsPage } from './Pages/EventsPage/EventsPage';
import { AdminEventsPage } from './Pages/AdminEventsPage/AdminEventsPage';
import { EventPage } from './Pages/EventPage/EventPage';
import { LocationPage } from './Pages/LocationPage/LocationPage';
import { LocationsPage } from './Pages/LocationsPage/LocationsPage';
import { AdminLocationsPage } from './Pages/AdminLocationsPage/AdminLocationsPage';
import { AdminHomePage } from './Pages/AdminHomePage/AdminHomePage';
import { BlogPage } from './Pages/BlogPage/BlogPage';
import { BlogItemPage } from './Pages/BlogItemPage/BlogItemPage';
import { FriendsPage } from './Pages/FriendsPage/FriendsPage';
import { AddReservationModal } from './Modals/AddReservationModal/AddReservationModal';
import { SubscribeModal } from './Modals/SubscribeModal/SubscribeModal';
import { AdminAddLocationPage } from './Pages/AdminAddLocationPage/AdminAddLocationPage';
import { AdminEditLocationPage } from './Pages/AdminEditLocationPage/AdminEditLocationPage';
import { AdminDeleteLocationPage } from './Pages/AdminDeleteLocationPage/AdminDeleteLocationPage';
import { AdminAddBlogItemPage } from './Pages/AdminAddBlogItemPage/AdminAddBlogItemPage';
import { AdminEditBlogItemPage } from './Pages/AdminEditBlogItemPage/AdminEditBlogItemPage';
import { AdminDeleteBlogItemPage } from './Pages/AdminDeleteBlogItemPage/AdminDeleteBlogItemPage';
import { AdminAddFriendPage } from './Pages/AdminAddFriendPage/AdminAddFriendPage';
import { AdminEditFriendPage } from './Pages/AdminEditFriendPage/AdminEditFriendPage';
import { AdminDeleteFriendPage } from './Pages/AdminDeleteFriendPage/AdminDeleteFriendPage';
import { AdminBlogPage } from './Pages/AdminBlogPage/AdminBlogPage';
import { AdminEditEventPage } from './Pages/AdminEditEventPage/AdminEditEventPage';
import { AdminDeleteEventPage } from './Pages/AdminDeleteEventPage/AdminDeleteEventPage';
import { AdminAddEventPage } from './Pages/AdminAddEventPage/AdminAddEventPage';
import { AdminFriendsPage } from './Pages/AdminFriendsPage/AdminFriendsPage';
import { AdminEventLocationsPage } from './Pages/AdminEventLocationsPage/AdminEventLocationsPage';
import { AdminAddEventLocationPage } from './Pages/AdminAddEventLocationPage/AdminAddEventLocationPage';
import { AdminEditEventLocationPage } from './Pages/AdminEditEventLocationPage/AdminEditEventLocationPage';
import { AdminDeleteEventLocationPage } from './Pages/AdminDeleteEventLocationPage/AdminDeleteEventLocationPage';
import { AdminEventReservationsPage } from './Pages/AdminEventReservationsPage/AdminEventReservationsPage';
import { AuthController } from './Controllers/AuthController/AuthController';
import { SelectFileModal } from './Modals/SelectFileModal/SelectFileModal';
import { AdminCancelEventPage } from './Pages/AdminCancelEventPage/AdminCancelEventPage';
import { ConfirmEventReservationModal } from './Modals/ConfirmEventReservationModal/ConfirmEventReservationModal';
import { RejectEventReservationModal } from './Modals/RejectEventReservationModal/RejectEventReservationModal';
import { CancelEventDateModal } from './Modals/CancelEventDateModal/CancelEventDateModal';
import { CancelEventModal } from './Modals/CancelEventModal/CancelEventModal';
import { CancelReservationPage } from './Pages/CancelReservationPage/CancelReservationPage';
import { LoginSuccessPage } from './Pages/LoginSuccessPage/LoginSuccessPage';
import { SelectEventLocationModal } from './Modals/SelectEventLocationModal/SelectEventLocationModal';
import { AdminFilesPage } from './Pages/AdminFilesPage/AdminFilesPage';
import { ArchivePage } from './Pages/ArchivePage/ArchivePage';
import { AdminConfirmEventReservationPage } from './Pages/AdminConfirmEventReservationPage/AdminConfirmEventReservationPage';
import { AdminRejectEventReservationPage } from './Pages/AdminRejectEventReservationPage/AdminRejectEventReservationPage';
import { DeleteEventReservationModal } from './Modals/DeleteEventReservationModal/DeleteEventReservationModal';
import { RouteGuard } from './Components/RouteGuard/RouteGuard';
import { InputLinkModal } from './Modals/InputLinkModal/InputLinkModal';
import { DeleteEventDateModal } from './Modals/DeleteEventDateModal/DeleteEventDateModal';
import { IcErrorBox } from '@indece-common/ic-ui-lib-react';
import { ErrorTranslator } from './utils/ErrorTranslator';
import { AdminStatusPage } from './Pages/AdminStatusPage/AdminStatusPage';

import './App.css';


const oidcConfig: AuthProviderProps = {
    onSignIn: ( userData ) =>
    {
        const cookies = new Cookies();

        cookies.set('kulturbunt-signedin', '1', {
            path:       '/',
            secure:     false,
            httpOnly:   false
        });
    },
    onSignOut: ( ) => 
    {
        const cookies = new Cookies();

        cookies.remove('kulturbunt-signedin');
    },
    autoSignIn: false,
    authority: Environment.oauth.authority,
    clientId: Environment.oauth.clientId,
    redirectUri: Environment.oauth.redirectUri + '/login-success',
    postLogoutRedirectUri: Environment.oauth.redirectUri,
    scope: `openid profile email offline_access`
};


IcErrorBox.translate = ( err ) => ErrorTranslator.translate(err) || err.message;


export class App extends React.Component
{
    private readonly _trackingService:  TrackingService;


    constructor ( props: any )
    {
        super(props);

        this._trackingService = TrackingService.getInstance();
    }


    public componentDidMount ( ): void
    {
        this._trackingService.init();
    }


    public render ( )
    {
        return (
            <BrowserRouter>
                <AuthProvider {...oidcConfig}>
                    <div className='App'>
                        <NavBar />

                        <div className='App-content'>
                            <Routes>
                                <Route path='/' element={<HomePage />} />

                                <Route path='/veranstaltungen' element={<EventsPage />} />
                                <Route path='/veranstaltung/:eventID'element={<EventPage />} />
                                <Route path='/locations' element={<LocationsPage />} />
                                <Route path='/location/:locationID' element={<LocationPage />} />
                                <Route path='/blog' element={<BlogPage />} />
                                <Route path='/blog/:blogitemID' element={<BlogItemPage />} />
                                <Route path='/freund_innen' element={<FriendsPage />} />
                                <Route path='/archiv' element={<ArchivePage />} />
                                <Route path='/kontakt' element={<ContactPage />} />
                                <Route path='/impressum' element={<ImprintPage />} />
                                <Route path='/datenschutz' element={<PrivacyPolicyPage />} />
                                <Route path='/reservierung/loeschen' element={<CancelReservationPage />} />
                                <Route path='/login-success' element={<LoginSuccessPage />} />
                                <Route path='/admin/login' element={<AdminLoginPage />} />
                                <Route path='/admin/home' element={<RouteGuard component={AdminHomePage} />} />
                                <Route path='/admin/veranstaltungen' element={<RouteGuard component={AdminEventsPage} />} />
                                <Route path='/admin/veranstaltung/neu' element={<RouteGuard component={AdminAddEventPage} />}/>
                                <Route path='/admin/veranstaltung/:eventID/bearbeiten' element={<RouteGuard component={AdminEditEventPage} />} />
                                <Route path='/admin/veranstaltung/:eventID/loeschen' element={<RouteGuard component={AdminDeleteEventPage} />}/>
                                <Route path='/admin/veranstaltung/:eventID/absagen' element={<RouteGuard component={AdminCancelEventPage} />} />
                                <Route path='/admin/veranstaltungsorte' element={<RouteGuard component={AdminEventLocationsPage} />} />
                                <Route path='/admin/veranstaltungsort/add' element={<RouteGuard component={AdminAddEventLocationPage} />} />
                                <Route path='/admin/veranstaltungsort/:eventLocationID/edit' element={<RouteGuard component={AdminEditEventLocationPage} />} />
                                <Route path='/admin/veranstaltungsort/:eventLocationID/delete' element={<RouteGuard component={AdminDeleteEventLocationPage} />} />
                                <Route path='/admin/reservierungen' element={<RouteGuard component={AdminEventReservationsPage} />} />
                                <Route path='/admin/reservierung/:eventReservationID/bestaetigen' element={<RouteGuard component={AdminConfirmEventReservationPage} />} />
                                <Route path='/admin/reservierung/:eventReservationID/ablehen' element={<RouteGuard component={AdminRejectEventReservationPage} />} />
                                <Route path='/admin/locations' element={<RouteGuard component={AdminLocationsPage} />} />
                                <Route path='/admin/location/add' element={<RouteGuard component={AdminAddLocationPage} />} />
                                <Route path='/admin/location/:locationID/edit' element={<RouteGuard component={AdminEditLocationPage} />} />
                                <Route path='/admin/location/:locationID/delete' element={<RouteGuard component={AdminDeleteLocationPage} />} />
                                <Route path='/admin/blog' element={<RouteGuard component={AdminBlogPage} />} />
                                <Route path='/admin/blog/neu' element={<RouteGuard component={AdminAddBlogItemPage} />} />
                                <Route path='/admin/blog/:blogitemID/bearbeiten' element={<RouteGuard component={AdminEditBlogItemPage} />} />
                                <Route path='/admin/blog/:blogitemID/loeschen' element={<RouteGuard component={AdminDeleteBlogItemPage} />} />
                                <Route path='/admin/freund_innen' element={<RouteGuard component={AdminFriendsPage} />} />
                                <Route path='/admin/freund_in/add' element={<RouteGuard component={AdminAddFriendPage} />} />
                                <Route path='/admin/freund_in/:friendID/edit' element={<RouteGuard component={AdminEditFriendPage} />} />
                                <Route path='/admin/freund_in/:friendID/delete' element={<RouteGuard component={AdminDeleteFriendPage} />} />
                                <Route path='/admin/dateien' element={<RouteGuard component={AdminFilesPage} />} />
                                <Route path='/admin/status' element={<RouteGuard component={AdminStatusPage} />} />

                                <Route path='*' element={<Error404Page />} />
                            </Routes>
                        </div>

                        <Footer />
                    </div>

                    <SubscribeModal />
                    <AddReservationModal />
                    <ConfirmEventReservationModal />
                    <RejectEventReservationModal />
                    <DeleteEventReservationModal />
                    <CancelEventDateModal />
                    <CancelEventModal />
                    <DeleteEventDateModal />
                    <SelectFileModal />
                    <SelectEventLocationModal />
                    <InputLinkModal />
                    <CookieHintModal />
                
                    <ScrollToTopController />
                    <AuthController />
                </AuthProvider>
            </BrowserRouter>
        );
    }
}
