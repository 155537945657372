import React from 'react';

import './PageContent.css';


export interface PageContentProps
{
    noHeader?:  boolean;
    children?:  React.ReactNode | undefined;
}


export class PageContent extends React.Component<PageContentProps>
{
    public render ( )
    {
        return (
            <div className={`PageContent ${this.props.noHeader ? 'noheader' : ''}`}>
                {this.props.children}
            </div>
        );
    }
}
