import { BackendService } from './BackendService';


export interface BlogItemV1
{
    id:                 number;
    title:              string;
    abstract:           string;
    preview_image_uid:  string | null;
    image_uids:         Array<string>;
    description:        string;
    datetime_created:   string;
}


export interface AddBlogItemV1
{
    title:              string;
    abstract:           string;
    preview_image_uid:  string | null;
    image_uids:         Array<string>;
    description:        string;
}


export interface UpdateBlogItemV1
{
    title:              string;
    abstract:           string;
    preview_image_uid:  string | null;
    image_uids:         Array<string>;
    description:        string;
}


export class BlogService
{
    private static _instance:           BlogService;
    private readonly _backendService:   BackendService;
    
    
    public static getInstance ( ): BlogService
    {
        if ( ! this._instance )
        {
            this._instance = new BlogService();
        }
        
        return this._instance;
    }


    constructor ( )
    {
        this._backendService = BackendService.getInstance();
    }


    public async getBlogItems ( from: number, size: number ): Promise<Array<BlogItemV1>>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/blogitem?from=${encodeURIComponent(from)}&size=${encodeURIComponent(size)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.blogitems;
    }
   
   
    public async getBlogItem ( blogitemID: number ): Promise<BlogItemV1>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/blogitem/${encodeURIComponent(blogitemID)}`,
            {
                method: 'GET',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );

        return resp.blogitem;
    }


    public async addBlogItem ( params: AddBlogItemV1 ): Promise<number>
    {
        const resp = await this._backendService.fetchJson(
            `/api/v1/blogitem`,
            {
                method: 'POST',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );

        return resp.blogitem_id;
    }
   
   
    public async updateBlogItem ( blogitemID: number, params: UpdateBlogItemV1 ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/blogitem/${encodeURIComponent(blogitemID)}`,
            {
                method: 'PUT',
                headers:    {
                    'Accept':       'application/json',
                    'Content-Type': 'application/json'
                },
                body:       JSON.stringify(params)
            }
        );
    }
   
   
    public async deleteBlogItem ( blogitemID: number ): Promise<void>
    {
        await this._backendService.fetchJson(
            `/api/v1/blogitem/${encodeURIComponent(blogitemID)}`,
            {
                method: 'DELETE',
                headers:    {
                    'Accept':   'application/json'
                }
            }
        );
    }
}
